import {
  FETCH_SCAN_DATA_GROUPED_BY_ASSET_REQUEST,
  FETCH_SCAN_DATA_GROUPED_BY_ASSET_SUCCESS,
  FETCH_SCAN_DATA_GROUPED_BY_VULNERABILITY_REQUEST,
  FETCH_SCAN_DATA_GROUPED_BY_VULNERABILITY_SUCCESS,
  GROUPED_BY_CARDS_ASSETS,
  GROUPED_BY_CARDS_ISSUES
} from 'action_types/scan_details';

const initialState = {
  groupedByVulnerability: [],
  groupedByVulnerabilityCount: null,
  groupedByAsset: [],
  groupByAssetsCount: null,
  groupByVulnerabilityNextItems: null,
  groupedByAssetNextItems: null,
  groupedByVulnerabilityPreviousItems: null,
  groupedByAssetPreviousItems: null,
  groupedByDataLeak: [],
  groupedByDataLeakCount: null,
  groupedByDataLeakNextItems: null,
  groupedByDataLeakPreviousItems: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_SCAN_DATA_GROUPED_BY_ASSET_REQUEST:
    case FETCH_SCAN_DATA_GROUPED_BY_VULNERABILITY_REQUEST:
    case 'FETCH_SCAN_DATA_GROUPED_BY_DATA_LEAK_REQUEST':
      return {
        ...state,
        loading: true
      };
    case FETCH_SCAN_DATA_GROUPED_BY_ASSET_SUCCESS:
      return {
        ...state,
        groupedByAsset: [...action.data],
        groupByAssetsCount: action.groupByAssetsCount,
        groupedByAssetNextItems: action.nextItems,
        groupedByAssetPreviousItems: action.previousItems
      };
    case FETCH_SCAN_DATA_GROUPED_BY_VULNERABILITY_SUCCESS:
      return {
        ...state,
        groupedByVulnerability: [...action.data],
        groupedByVulnerabilityCount: action.groupedByVulnerabilityCount,
        groupByVulnerabilityNextItems: action.nextItems,
        groupedByVulnerabilityPreviousItems: action.previousItems
      };
    case 'FETCH_SCAN_DATA_GROUPED_BY_DATA_LEAK_SUCCESS':
      return {
        ...state,
        groupedByDataLeak: [...action.data],
        groupedByDataLeakCount: action.groupedByDataLeakCount,
        groupedByDataLeakNextItems: action.nextItems,
        groupedByDataLeakPreviousItems: action.previousItems
      };
    case GROUPED_BY_CARDS_ASSETS:
    case GROUPED_BY_CARDS_ISSUES:
      return {
        ...state,
        loading: false
      };
    case 'RESET_REDUCER_DATA':
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
