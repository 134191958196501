import { CATCH_ERROR } from 'action_types/error';

const initialState = {
  errorResponse: '',
  errorMessage: '',
  errorKeys: [],
  loadingModal: false,
  errorCode: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CATCH_ERROR:
      return {
        ...state,
        ...action.errorInfo,
        loadingModal: false
      };

    case 'RESET_REDUCER_DATA':
    case 'RESET_ERROR_INFO':
      return {
        ...state,
        ...initialState
      };
    case 'SHOW_MODAL_LOADER':
      return {
        ...state,
        loadingModal: true
      };
    case 'SUCCESS_MESSAGE_MODAL':
      return {
        ...state,
        loadingModal: false
      };

    default:
      return state;
  }
}
