import * as icon from 'utils/icon';
import {
  GROUPED_BY_CARDS_ASSETS,
  GROUPED_BY_CARDS_ISSUES
} from 'action_types/scan_details';

const initialState = {
  rhOrganisationsPage: [
    {
      key: 1,
      title: 'PENDING',
      values: 0,
      icon: icon.scheduled,
      iconStyle: {
        padding: '0.5rem',
        backgroundColor: 'var(--color-pending)'
      },
      cardStyle: {
        marginLeft: '2rem'
      }
    },
    {
      key: 2,
      title: 'VERIFIED',
      values: 0,
      icon: icon.reported,
      iconStyle: {
        padding: '0.5rem',
        backgroundColor: 'var(--color-info)'
      }
    },
    {
      key: 3,
      title: 'DISABLED',
      values: 0,
      icon: icon.aborted,
      iconStyle: {
        padding: '0.5rem',
        backgroundColor: 'var(--color-aborted)'
      }
    },
    {
      key: 4,
      title: 'DELETED',
      values: 0,
      icon: icon.deleted,
      iconStyle: {
        padding: '0.5rem',
        backgroundColor: 'var(--color-deleted)'
      },
      cardStyle: {
        marginRight: '2rem'
      }
    }
  ],
  groupedPageAssets: [
    {
      key: 1,
      title: 'CRITICAL SEVERITY ASSETS',
      name: 4,
      values: 0,
      icon: icon.critical,
      iconStyle: {
        padding: '0.5rem',
        backgroundImage:
          'linear-gradient(to bottom, var(--color-gradient-one-0), var(--color-gradient-one-1))'
      },
      cardStyle: {
        borderLeft: '7px solid var(--color-gradient-one-0)',
        marginLeft: '2rem'
      }
    },
    {
      key: 2,
      title: 'HIGH SEVERITY ASSETS',
      name: 3,
      values: 0,
      icon: icon.high,
      iconStyle: {
        padding: '0.5rem',
        backgroundImage:
          'linear-gradient(to bottom, var(--color-gradient-two-0), var(--color-gradient-two-1))'
      },
      cardStyle: {
        borderLeft: '7px solid var(--color-gradient-two-0)'
      }
    },
    {
      key: 3,
      title: 'MODERATE SEVERITY ASSETS',
      name: 2,
      values: 0,
      icon: icon.moderate,
      iconStyle: {
        padding: '0.5rem',
        backgroundImage:
          'linear-gradient(to bottom, var(--color-gradient-three-0), var(--color-gradient-three-1))'
      },
      cardStyle: {
        borderLeft: '7px solid var(--color-gradient-three-0)'
      }
    },
    {
      key: 4,
      title: 'LOW SEVERITY ASSETS',
      name: 1,
      values: 0,
      icon: icon.normal,
      iconStyle: {
        padding: '0.5rem',
        backgroundImage:
          'linear-gradient(to bottom, var(--color-gradient-four-0), var(--color-gradient-four-1))'
      },
      cardStyle: {
        borderLeft: '7px solid var(--color-gradient-four-0)',
        marginRight: '2rem'
      }
    }
  ],
  groupedPageIssues: [
    {
      key: 1,
      title: 'CRITICAL SEVERITY ISSUES',
      values: 0,
      icon: icon.critical,
      iconStyle: {
        padding: '0.5rem',
        backgroundImage:
          'linear-gradient(to bottom, var(--color-gradient-one-0), var(--color-gradient-one-1))'
      },
      cardStyle: {
        borderLeft: '7px solid var(--color-gradient-one-0)',
        marginLeft: '2rem'
      }
    },
    {
      key: 2,
      title: 'HIGH SEVERITY ISSUES',
      values: 0,
      icon: icon.high,
      iconStyle: {
        padding: '0.5rem',
        backgroundImage:
          'linear-gradient(to bottom, var(--color-gradient-two-0), var(--color-gradient-two-1))'
      },
      cardStyle: {
        borderLeft: '7px solid var(--color-gradient-two-0)'
      }
    },
    {
      key: 3,
      title: 'MODERATE SEVERITY ISSUES',
      values: 0,
      icon: icon.moderate,
      iconStyle: {
        padding: '0.5rem',
        backgroundImage:
          'linear-gradient(to bottom, var(--color-gradient-three-0), var(--color-gradient-three-1))'
      },
      cardStyle: {
        borderLeft: '7px solid var(--color-gradient-three-0)'
      }
    },
    {
      key: 4,
      title: 'LOW SEVERITY ISSUES',
      values: 0,
      icon: icon.normal,
      iconStyle: {
        padding: '0.5rem',
        backgroundImage:
          'linear-gradient(to bottom, var(--color-gradient-four-0), var(--color-gradient-four-1))'
      },
      cardStyle: {
        borderLeft: '7px solid var(--color-gradient-four-0)',
        marginRight: '2rem'
      }
    }
  ]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GROUPED_BY_CARDS_ASSETS:
      return {
        ...state,
        groupedPageAssets: [...action.payload]
      };
    case GROUPED_BY_CARDS_ISSUES:
      return {
        ...state,
        groupedPageIssues: [...action.payload]
      };
    case 'RH_ORG_CARDS':
      return {
        ...state,
        rhOrganisationsPage: [...action.payload]
      };
    case 'RESET_REDUCER_DATA':
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
