export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGOUT = 'LOGOUT';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';

export const SET_USER = 'SET_USER';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const UPDATE_PROFILE_NAME = 'UPDATE_PROFILE_NAME';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
