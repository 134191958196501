import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTooltip } from 'components/tooltip';

const useStyles = makeStyles(() => ({
  button: {
    padding: '9px 20px',
    border: '0.25px solid var(--color-primary)',
    backgroundColor: 'var(--color-primary)',
    color: 'var(--color-white)',
    borderRadius: 4,
    // fontSize: '14px',
    cursor: 'pointer',
    outline: 'none',
    fontWeight: 700,
    fontFamily: 'Montserrat !important',
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.3s ease-out',
    justifyContent: 'center',
    maxHeight: '42px',
    '&:hover': {
      boxShadow: 'var(--light-mode-box-shadow)',
      backgroundColor: 'var(--color-primary600)',
    },
    '&:active': {
      transform: 'scale(0.9)'
    }
  },
  buttonOutlined: {
    padding: '9px 20px',
    border: '0.25px solid var(--color-primary)',
    background: 'var(--color-background)',
    color: 'var(--color-primary)',
    textAlign: 'center',
    boxShadow: 'none',
    margin: '10px 0',
    borderRadius: 4,
    // fontSize: '14px',
    cursor: 'pointer',
    outline: 'none',
    fontWeight: 700,
    fontFamily: 'Montserrat !important',
    display: 'flex',
    fontSize: 14,
    alignItems: 'center',
    transition: 'all 0.3s ease-out',
    justifyContent: 'center',
    maxHeight: '42px',
    '&:hover': {
      boxShadow: 'var(--light-mode-box-shadow)'
    },
    '&:active': {
      transform: 'scale(0.9)'
    }
  },
  buttonOutlinedDisabled: {
    padding: '9px 20px',
    border: '0.25px solid var(--color-muted)',
    backgroundColor: 'var(--color-background)',
    color: 'var(--color-muted)',
    borderRadius: 4,
    transition: 'all 0.3s ease-out',
    fontSize: '14px !important',
    fontWeight: 700,
    cursor: 'not-allowed',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    maxHeight: '24px',
    marginTop: 7,
    justifyContent: 'center'
  },
  cancelButton: {
    padding: '8px 16px',
    border: '1px solid #4D4D4D',
    backgroundColor: 'var(--color-white)',
    transition: 'all 0.5s ease-out',
    color: '#4D4D4D',
    fontWeight: 700,
    borderRadius: 4,
    fontSize: '14px !important',
    cursor: 'pointer',
    outline: 'none',
    fontFamily: 'Montserrat !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '42px'
  },
  disabledButton: {
    padding: '9px 20px',
    border: '1px solid var(--color-muted)',
    backgroundColor: 'var(--color-muted)',
    color: 'var(--color-white)',
    borderRadius: 4,
    transition: 'all 0.3s ease-out',
    fontSize: '14px !important',
    fontWeight: 700,
    cursor: 'not-allowed',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    maxHeight: '42px',
    justifyContent: 'center'
  }
}));

export const Button = (props) => {
  const classes = useStyles();
  return props.linkData ? (
    <Link to={props.linkData} onClick={props.onClick} style={props.style}>
      <p className={classes.button} style={props.buttonStyle}>
        {props.children}
        {props.title}
      </p>
    </Link>
  ) : (
    <button
      className={classes.button}
      onClick={props.onClick}
      type={props.type || 'button'}
      style={props.style}>
      {props.children}
      {props.title}
    </button>
  );
};

export const CancelButton = (props) => {
  const classes = useStyles();
  return props.linkData ? (
    <Link to={props.linkData} onClick={props.onClick} style={props.style}>
      <p className={classes.cancelButton}>
        {props.children}
        {props.title}
      </p>
    </Link>
  ) : (
    <button
      className={classes.cancelButton}
      type={props.type || 'button'}
      onClick={props.onClick}
      style={props.style}>
      {props.children}
      {props.title}
    </button>
  );
};

export const DisabledButton = (props) => {
  const classes = useStyles();
  return (
    <CustomTooltip title={props.tooltipMessage ? props.tooltipMessage : ''}>
      <p className={classes.disabledButton} style={props.style}>
        {props.children}
        {props.title}
      </p>
    </CustomTooltip>
  );
};

export const OutlinedButton = (props) => {
  const classes = useStyles();
  return props.linkData ? (
    <Link to={props.linkData} onClick={props.onClick} style={props.style}>
      <p className={classes.buttonOutlined} style={props.buttonStyle}>
        {props.children}
        {props.title}
      </p>
    </Link>
  ) : (
    <button
      className={classes.buttonOutlined}
      onClick={props.onClick}
      type={props.type || 'button'}
      style={props.style}>
      {props.children}
      {props.title}
    </button>
  );
};

export const OutlinedDisabledButton = (props) => {
  const classes = useStyles();
  return (
    <CustomTooltip title={props.tooltipMessage ? props.tooltipMessage : ''}>
      <p className={classes.buttonOutlinedDisabled} style={props.style}>
        {props.children}
        {props.title}
      </p>
    </CustomTooltip>
  );
};
