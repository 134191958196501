import { authLogout } from 'actions/auth';
import * as errorTypes from 'action_types/error';
import { showFailureModal, showSuccessModal } from 'actions/modals';
import { fetchLatestNotifications } from 'actions/scan_details';
import {
  setErrorData,
  setAwsErrorData,
  setAwsSubscriptionError,
  setAwsIamSubscriptionError,
  jiraErrorData,
  otpError,
  orgCodeError,
  createDescriptionError,
  subscriptionError,
  passwordChangeError
} from 'actions/error_data';

export const errorHandler = (error, dispatch, history, login) => {
  if (
    error.response &&
    (error.response.status === 401 || error.response.status === 403)
  ) {
    if (history) {
      if (error.response.status === 401) {
        setTimeout(() => {
          dispatch(authLogout(history));
        }, 1000);
        const userRole = localStorage.getItem('role');
        if (
          userRole === 'PENDING' ||
          userRole === 'ORG_PENDING' ||
          userRole === 'VERIFIED'
        ) {
          return;
        }
        localStorage.setItem('wasSessionExpired', true);
        localStorage.setItem(
          'location',
          JSON.stringify(history.location.pathname)
        );
        // if (!login) {
        // }

      }
      dispatch({
        type: errorTypes.CATCH_ERROR,
        errorInfo: {
          errorResponse: `${error.response.status}`,
          errorMessage: error.response.data.detail
            ? error.response.data.detail
            : error.response.data.error
              ? error.response.data.error
              : error.response.data ? error.response.data : '',
          errorKeys: [],
          errorCode: error.response.status
        }
      });
      dispatch(showFailureModal());
      // trick for Deleted User message handling

      // dispatch(logout(history));
    }
    return;
  } else if (error.response && error.response.status === 402) {
    dispatch(fetchLatestNotifications(history));
  } else if (error.response && error.response.status === 400) {
    if (error.response.data.error === 'Invalid OTP') {
      const errorMessage = 'Invalid OTP';
      dispatch({
        type: errorTypes.CATCH_ERROR,
        errorInfo: {
          errorResponse: `${error.response.status} Error Status - Invalid OTP`,
          errorMessage,
          errorKeys: [],
          errorCode: error.response.status
        }
      });
      dispatch(otpError(errorMessage));
    } else if (error.response.config.url === '/orgs/billing/') {
      dispatch({
        type: errorTypes.CATCH_ERROR,
        errorInfo: {
          errorResponse: `${error.response.status} Error Status - Not Subscribed`,
          errorKeys: [],
          errorCode: error.response.status
        }
      });
      dispatch(subscriptionError(error.response.data.error));
    } else if (error.response.data.error === 'Invalid Organisation Code') {
      const errorMessage = 'Invalid Organisation Code';
      dispatch({
        type: errorTypes.CATCH_ERROR,
        errorInfo: {
          errorResponse: `${error.response.status} Error Status - Invalid Org Code`,
          errorMessage,
          errorKeys: [],
          errorCode: error.response.status
        }
      });
      dispatch(orgCodeError(errorMessage));
    } else {
      dispatch({
        type: errorTypes.CATCH_ERROR,
        errorInfo: {
          errorResponse: `${error.response.status} Error Status - Internal Server Error`,
          errorMessage: `${error.response.data.error
            ? error.response.data.error.message
              ? error.response.data.error.message
              : error.response.data.error
            : error.response.data.error
            }`,
          errorKeys: error.response.data.fields
            ? error.response.data.fields
            : [],
          errorCode: error.response.status
        }
      });
      if (error.response.config.url === '/password/change/') {
        dispatch(passwordChangeError(error.response.data.error));
      }
      if (error.response.config.url === '/orgs/jira/configure/') {
        dispatch(jiraErrorData(error.response.data.error.message));
      }
      if (
        error.response.config.url === '/orgs/aws-keys/' &&
        error.response.config.method === 'post'
      ) {
        dispatch(setAwsSubscriptionError(error.response.data.error));
      } else if (
        error.response.config.url === '/orgs/aws-config/' &&
        error.response.config.method === 'post'
      ) {
        dispatch(
          setAwsIamSubscriptionError(
            error.response.data.msg
              ? error.response.data.msg
              : error.response.data.error
          )
        );
      }
      if (error.response.data && history) {
        if (
          history.location.pathname !== '/admin/rh/settings/issue-types' ||
          history.location.pathname !== '/admin/rh/settings/dataleak-types/' ||
          history.location.pathname !== '/admin/rh/settings/dataleak-types' ||
          history.location.pathname !== '/admin/rh/settings/issue-types/'
        ) {
          dispatch(
            setErrorData(
              error.response.config.data
                ? JSON.parse(error.response.config.data)
                : [],
              error.response.data
            )
          );
        }
      }
      if (
        error.response.config.url === '/orgs/aws-keys/' &&
        error.response.config.method === 'post'
      ) {
        dispatch(setAwsSubscriptionError(error.response.data.error));
      }
      if (
        (error.response.config.url === '/dataleak/' ||
          error.response.config.url === '/issue/') &&
        error.response.config.method === 'post'
      ) {
        dispatch(createDescriptionError(error.response.data[0]));
      }
      if (
        (history && history.location.pathname === '/admin/rh/settings') ||
        history.location.pathname === '/admin/rh/settings/issue-types/' ||
        // history.location.pathname === '/user/login-form' ||
        error.response.config.url === '/orgs/jira/configure/' ||
        history.location.pathname === '/admin/rh/settings/dataleak-types/' ||
        history.location.pathname === '/admin/rh/settings/dataleak-types' ||
        history.location.pathname === '/admin/rh/settings/issue-types' ||
        error.response.config.url === '/orgs/cloud_keys/' ||
        error.response.config.url === '/orgs/aws-keys/' ||
        error.response.config.url === '/orgs/aws-config/' ||
        error.response.config.url === '/password/change/'
      ) {
      } else {
        dispatch(showFailureModal());
      }
    }
    return;
  } else if (error.response && error.response.status === 500) {
    const errorMessage = `Oops! Our server is on a break!
    Please try after some time.`;
    dispatch({
      type: errorTypes.CATCH_ERROR,
      errorInfo: {
        errorResponse: `${error.response.status} Error Status - Internal Server Error`,
        errorMessage,
        errorKeys: [],
        errorCode: error.response.status
      }
    });
    dispatch(showFailureModal());
    return;
  } else if (error.response && error.response.status === 404) {
    const errorMessage = error.response.data.error
      ? error.response.data.error
      : `Oops! Result not found.`;
    dispatch({
      type: errorTypes.CATCH_ERROR,
      errorInfo: {
        errorResponse: `${error.response.status} Error Status - Result not found`,
        errorMessage,
        errorKeys: [],
        errorCode: error.response.status
      }
    });
    dispatch(showFailureModal());
    return;
  } else if (error.response && error.response.status === 422) {
    if (error.response.config.url === '/scan/create/') {
      dispatch({
        type: errorTypes.CATCH_ERROR,
        errorInfo: {
          errorResponse: `${error.response.error}`,
          errorMessage: error.response.statusText,

          errorCode: error.response.status
        }
      });
      dispatch(showFailureModal());
      return;
    }
    let errorMessage;
    let errorKeys = [];
    if (
      error.response.data.error.code_str &&
      error.response.data.error.code_str === 'INVALID_AWS_CREDS'
    ) {
      errorMessage = error.response.data.error.message;
      dispatch({
        type: errorTypes.CATCH_ERROR,
        errorInfo: {
          errorResponse: `${error.response.error}`,
          errorMessage,
          errorKeys,
          errorCode: error.response.status
        }
      });
      dispatch(
        setAwsErrorData(
          error.response.data.error.fields,
          error.response.data.error.message
        )
      );
      return;
    }

    if (error.response && error.response.data && error.response.data.fields) {
      errorMessage = `these domains are not valid and skipped: ${error.response.data.fields.toString()}`;
      errorKeys = error.response.data.fields ? error.response.data.fields : [];
    } else {
      errorMessage = error.response.data.error.message
        ? error.response.data.error.message
        : error.response.data.error
          ? error.response.data.error
          : [];
      errorKeys = error.response.data.error.fields
        ? error.response.data.error.fields
        : error.response.data.fields
          ? error.response.data.fields
          : [];
    }
    dispatch({
      type: errorTypes.CATCH_ERROR,
      errorInfo: {
        errorResponse: `${error.response.error}`,
        errorMessage,
        errorKeys,
        errorCode: error.response.status
      }
    });
    dispatch(showFailureModal());
    return;
  } else if (error.response && error.response.status === 413) {
    const errorMessage = `The file size exceeds the required limit. Please evaluate the file and upload it again.`;
    dispatch({
      type: errorTypes.CATCH_ERROR,
      errorInfo: {
        errorResponse: `${error.response.status} Error Status - File size limit exceeds`,
        errorMessage,
        errorKeys: [],
        errorCode: error.response.status
      }
    });
    dispatch(showFailureModal());
    return;
  } else if (error.response && error.response.status === 429) {
    dispatch({
      type: errorTypes.CATCH_ERROR,
      errorInfo: {
        errorResponse: `${error.response.status} Error Status - RATE_LIMIT_EXCEEDED`,
        errorMessage: error.response.data.detail
          ? error.response.data.detail
          : error.response.data.error.message,
        errorKeys: [],
        errorCode: error.response.status
      }
    });
    dispatch(
      jiraErrorData(
        error.response.data.detail
          ? error.response.data.detail
          : error.response.data.error.message
      )
    );
    if (error.response.config.url === '/user/attach-org/') {
      dispatch(orgCodeError(error.response.data.error.message));
    } else if (error.response.config.url === 'user/otp-verification/') {
      if (error.response.data.error) {
        dispatch(otpError(error.response.data.error.message));
      } else {
        dispatch(otpError(error.response.data.detail));
      }
    }
    dispatch(showFailureModal());
    return;
  } else if (login) {
    const errorMessage = error.response
      ? error.response.data.error.message
      : 'Oops! Something is not right. Please try again after sometime';

    const errorKeys = error.response ? error.response.data.error.fields : [];

    const errorResponse = error.response
      ? error.response.data.error.code_str
      : '';

    const errorCode = error.response ? error.response.status : '';

    dispatch({
      type: errorTypes.CATCH_ERROR,
      errorInfo: {
        errorResponse,
        errorMessage,
        errorKeys,
        errorCode
      }
    });
    return;
  }

  const errorMessage = error.response
    ? error.response.data.error.message
    : 'Oops! Something is not right. Please try again after sometime';

  const errorKeys = error.response ? error.response.data.error.fields : [];

  const errorResponse = error.response
    ? error.response.data.error.code_str
    : '';

  const errorCode = error.response ? error.response.status : '';

  dispatch({
    type: errorTypes.CATCH_ERROR,
    errorInfo: {
      errorResponse,
      errorMessage,
      errorKeys,
      errorCode
    }
  });
  // eslint-disable-next-line no-console
  if (error.response && `${error.response.status}`.startsWith('4')) {
    dispatch(showFailureModal());
  }
};

export const validationErrorHandler = (error, dispatch) => {
  if (
    (error.response && error.response.status === 500) ||
    (error.response && error.response.status === 501)
  ) {
    const errorMessage = `Oops! Our server is on a break!
    Please try after some time.`;
    dispatch({
      type: errorTypes.CATCH_ERROR,
      errorInfo: {
        errorResponse: `${error.response.status} Error Status - Internal Server Error`,
        errorMessage,
        errorKeys: [],
        errorCode: error.response.status
      }
    });
    dispatch(showFailureModal());
    return;
  }

  const errorMessage = error.response.data.error.message
    ? error.response.data.error.message
    : error.response.data.error;
  const errorKeys = error.response.data.error.fields
    ? error.response.data.error.fields
    : [];

  dispatch({
    type: errorTypes.CATCH_ERROR,
    errorInfo: {
      errorResponse: error.response.data.error.code_str
        ? error.response.data.error.code_str
        : error.response.data.error,
      errorMessage,
      errorKeys,
      errorCode: error.response.status
    }
  });
};

export const checkIsError = (errorArray, id, inputField) => {
  let isError = false;
  for (let i = 0; i < errorArray.length; i++) {
    if (errorArray[i].id === id && errorArray[i].error.includes(inputField)) {
      isError = true;
    }
  }
  return isError;
};

export const stripeErrorHandler = (
  error,
  stripe,
  requestData,
  dispatch,
  planName,
  history
) => {
  if (
    error.response &&
    (error.response.status === 401 || error.response.status === 403)
  ) {
    dispatch({
      type: errorTypes.CATCH_ERROR,
      errorInfo: {
        errorResponse: `${error.response.status}`,
        errorMessage: ``,
        errorKeys: [],
        errorCode: error.response.status
      }
    });
    if (history) {
      if (error.response.status === 401) {
        localStorage.setItem('wasSessionExpired', true);
        localStorage.setItem(
          'location',
          JSON.stringify(history.location.pathname)
        );
      }
      // dispatch(logout(history));
    }
    return;
  }
  const errorData = error.response.data.error;

  if (errorData.fields[0].status === 'requires action') {
    stripe
      .confirmCardPayment(errorData.fields[0].client_secret, {
        payment_method: requestData.payment_method_id
      })
      .then((result) => {
        if (result.error) {
          // Start code flow to handle updating the payment details.
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc).
          throw result;
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            // Show a success message to your customer.

            dispatch({
              type: 'SUCCESS_MESSAGE_MODAL',
              message: {
                title: 'Success!',
                message: `You are successfully subscribed to our ${planName}`,
                redirectTo: '',
                buttonTitle: 'Ok'
              }
            });
            dispatch(showSuccessModal());
          }
        }
      })
      .catch((error) => {
        let errorMessage = error.response
          ? errorData.message
          : 'Oops! Something is not right. Please try again after sometime';

        let errorKeys = error.response ? errorData.fields : [];

        let errorResponse = error.response ? errorData.code_str : '';

        let errorCode = error.response ? error.response.status : '';
        if (error.error.decline_code === 'insufficient_funds') {
          errorMessage = error.error.message;
          errorKeys = [];
          errorResponse = error.error.type;
          errorCode = error.error.code;
        } else if (
          error.error.code === 'payment_intent_authentication_failure'
        ) {
          errorMessage = error.error.message;
          errorKeys = [];
          errorResponse = error.error.type;
          errorCode = error.error.code;
        }

        dispatch({
          type: errorTypes.CATCH_ERROR,
          errorInfo: {
            errorResponse,
            errorMessage,
            errorKeys,
            errorCode
          }
        });
        dispatch(showFailureModal());
      });
    return;
  } else if (errorData.fields[0].status === 'requires payment method') {
    let errorMessage = error.response
      ? errorData.message
      : 'Oops! Something is not right. Please try again after sometime';

    let errorKeys = error.response ? errorData.fields : [];

    let errorResponse = error.response ? errorData.code_str : '';

    let errorCode = error.response ? error.response.status : '';

    dispatch({
      type: errorTypes.CATCH_ERROR,
      errorInfo: {
        errorResponse,
        errorMessage,
        errorKeys,
        errorCode
      }
    });
    dispatch(showFailureModal());
    return;
  }
  const errorMessage = error.response
    ? error.response.data.error.message
    : 'Oops! Something is not right. Please try again after sometime';

  const errorKeys = error.response ? error.response.data.error.fields : [];

  const errorResponse = error.response
    ? error.response.data.error.code_str
    : '';

  const errorCode = error.response ? error.response.status : '';

  dispatch({
    type: errorTypes.CATCH_ERROR,
    errorInfo: {
      errorResponse,
      errorMessage,
      errorKeys,
      errorCode
    }
  });
  dispatch(showFailureModal());
};
