const initialState = {
  data: {
    asset_count: null,
    asset_count_change: null,
    issue_count: null,
    issue_count_change: null,
    dataleak_count: null,
    dataleak_count_change: null,
    last_change_days_delta: null,
    severity: null,
    assets_type_count: [
      {
        type: null,
        count: null
      },
      {
        type: null,
        count: null
      },
      {
        type: null,
        count: null
      },
      {
        type: null,
        count: null
      },
      {
        type: null,
        count: null
      }
    ],
    tags_count: [
      {
        tag: null,
        count: null
      },
      {
        tag: null,
        count: null
      },
      {
        tag: null,
        count: null
      },
      {
        tag: null,
        count: null
      },
      {
        tag: null,
        count: null
      }
    ],
    top_issues: [
      {
        count: null,
        condition: null,
        severity: null
      },
      {
        count: null,
        condition: null,
        severity: null
      },
      {
        count: null,
        condition: null,
        severity: null
      },
      {
        count: null,
        condition: null,
        severity: null
      },
      {
        count: null,
        condition: null,
        severity: null
      }
    ],
    top_vulnerable_assets: [
      {
        asset: null,
        count: null
      },
      {
        asset: null,
        count: null
      },
      {
        asset: null,
        count: null
      },
      {
        asset: null,
        count: null
      },
      {
        asset: null,
        count: null
      }
    ],
    geolocation: [],
    scans_delta: [],
    severity_count: []
  },
  status: null,
  statusLoading: false,
  loading: false,
  cardLoading: false,
  unvalidated: '',
  killed_by: '',
  resumable: '',
  killed_ts: '',
  is_killed_scan: null,
  setupFinished: false,
  org_subscription: false,
  asm_score: null,
  rssFeed: [],
  nextItems: null,
  previousItems: null,
  Count: null,
  REPORTS_SUB_SECTIONS: {
    "DATA_LEAKS": "DISABLED",
    "REPORT_PDF": "DISABLED",
    "WEB_ENDPOINTS": "DISABLED",
    "ASSET_EXPOSURE": "DISABLED",
    "SECURITY_RISKS": "DISABLED",
    "ASSET_INVENTORY": "DISABLED",
    "THIRD_PARTY_ASSETS": "DISABLED"
  },
  REPORTS_TOP_LEVEL: 'DISABLED'
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'FETCH_DASHBOARD_DATA_V2_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'FETCH_DASHBOARD_DATA_V2_SUCCESS':
      return {
        ...state,
        data: action.data,
        loading: false
      };

    case 'RESET_REDUCER_DATA': {
      const dashboardStatus = state.status;
      const org_subscription = state.org_subscription;
      const REPORTS_SUB_SECTIONS = state.REPORTS_SUB_SECTIONS;
      const REPORTS_TOP_LEVEL = state.REPORTS_TOP_LEVEL;
      return {
        ...state,
        ...initialState,
        status: dashboardStatus,
        org_subscription: org_subscription,
        REPORTS_SUB_SECTIONS: REPORTS_SUB_SECTIONS,
        REPORTS_TOP_LEVEL: REPORTS_TOP_LEVEL
      };
    }
    case 'DASHBOARD_STATUS_REQUEST':
      return {
        ...state,
        // statusLoading: true
      };
    case 'FEED_DATA_REQUEST':
      return {
        ...state,
        cardLoading: true
      };
    case 'DASHBOARD_STATUS_SUCCESS':
      return {
        ...state,
        status: action.status,
        // statusLoading: false,
        unvalidated: action.is_unvalidated,
        killed_by: '',
        killed_ts: action.killed_ts,
        resumable: action.resumable,
        is_killed_scan: action.is_killed_scan,
        setupFinished: action.setup_finished,
        org_subscription: action.org_subscription,
        asm_score: action.asm_score
      };
    case 'DASHBOARD_SETUP_STATUS_SUCCESS':
      return {
        ...state,
        status: action.status,
        statusLoading: false,
        setupFinished: action.setup_finished,
        org_subscription: action.org_subscription
      };
    case 'FEED_DATA_RESPONSE':
      return {
        ...state,
        cardLoading: false,
        rssFeed: action.data,
        Count: action.Count,
        nextItems: action.nextItems,
        previousItems: action.previousItems,
      };
    case 'REPORT_TAB_SUCCESS':
      return {
        ...state,
        REPORTS_SUB_SECTIONS: action.REPORTS_SUB_SECTIONS,
        REPORTS_TOP_LEVEL: action.REPORTS_TOP_LEVEL,
      };
    default:
      return state;
  }
}
