import axios from "axios";
var url = process.env.REACT_APP_API;
const API = axios.create({
  baseURL: `${url}/api/v1/`,
  headers: {},
  withCredentials: true,
  transformRequest: [
    function (data, headers) {
      // const access_token = Cookies.get("token");
      // if (access_token) {
      //   headers.Authorization = `${"JWT "}${access_token}`;
      // }
      headers["Content-Type"] = "application/json;charset=UTF-8";

      return JSON.stringify(data);
    },
  ],
});

API.interceptors.request.use(
  (config) => {
    if (
      !config.url.includes("localhost") &&
      !config.url.startsWith("https://")
    ) {
      config.url = config.url.replace(/^http:\/\//i, "https://");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const APIv2 = axios.create({
  baseURL: `${url}/api/v2/`,
  headers: {},
  withCredentials: true,
  transformRequest: [
    function (data, headers) {
      // const access_token = Cookies.get("token");
      // if (access_token) {
      //   headers.Authorization = `${"JWT "}${access_token}`;
      // }
      headers["Content-Type"] = "application/json;charset=UTF-8";
      return JSON.stringify(data);
    },
  ],
});
const fileAPI = axios.create({
  baseURL: `${url}/api/v1/`,
  headers: {},
  withCredentials: true,
  transformRequest: [
    function (data, headers) {
      // const access_token = Cookies.get("token");
      // if (access_token) {
      //   headers.Authorization = `${"JWT "}${access_token}`;
      // }
      return data;
    },
  ],
});
const fileAPIv2 = axios.create({
  baseURL: `${url}/api/v2/`,
  headers: { 'Content-Type': 'multipart/form-data' },
  withCredentials: true,
  transformRequest: [
    function (data, headers) {
      // const access_token = Cookies.get("token");
      // if (access_token) {
      //   headers.Authorization = `${"JWT "}${access_token}`;
      // }
      return data;
    },
  ],
});

const API_Payment = axios.create({
  // Test API template for payments page
  baseURL: `${url}/api/`,
  headers: {},
  withCredentials: true,
  transformRequest: [
    function (data, headers) {
      // const access_token = Cookies.get("token");
      // if (access_token) {
      //   headers.Authorization = `${"JWT "}${access_token}`;
      // }
      headers["Content-Type"] = "application/json;charset=UTF-8";

      return JSON.stringify(data);
    },
  ],
});

export { API, APIv2, fileAPI, fileAPIv2, API_Payment };
