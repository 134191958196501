import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink, Link, withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
// import AccountCircle from '@material-ui/icons/Person';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
// import LogOutIcon from '@material-ui/icons/ExitToAppRounded';
import logo from 'assets/nvadrLogoOnboarding.png';
import { logoutUser } from 'actions/auth';
import { useSelector, useDispatch } from 'react-redux';
// import { useFetching } from 'utils/custom_hooks';
import toLower from 'lodash/toLower';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import UsersIcon from '@material-ui/icons/PeopleRounded';
import OrganisationIcon from '@material-ui/icons/AccountBalanceRounded';
import LogoutButton from 'components/auth_logout';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  appBar: {
    backgroundColor: 'var(--color-background)',
    position: 'fixed',
    top: 0,
    left: 0,
    boxShadow: 'var(--dark-mode-box-shadow)'
  },
  toolbar: {
    padding: '0rem 1rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  menu: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '1rem 2rem',
    marginTop: 0,
    marginBottom: 0,
    color: 'var(--color-text)',
    background: 'var(--color-background)'
  },
  menuIcon: {
    paddingRight: '1rem'
  },

  profile: {
    padding: '1rem',
    paddingLeft: '1.5rem',
    margin: 0,
    color: 'var(--color-light-text)',
    fontWeight: 500,
    marginLeft: 'auto'
  },

  activeIcon: {
    color: 'var(--color-light-text)',
    backgroundColor: 'var(--color-bodyBG)',
    padding: '5px',
    borderRadius: '50px',
    fontSize: 20
  },
  listItemIcon: {
    paddingLeft: '0.5rem',
    color: 'var(--color-primary)'
  },
  listLinks: {
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    color: 'var(--color-primary)',
    marginBottom: 0,
    fontWeight: '700 !important',
    opacity: 1,
    position: 'relative',
    transition: 'all 0.4s ease-in-out',
    '&:hover': {
      backgroundColor: 'var(--color-transparentDark)',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      marginTop: '1rem'
    },
    '&.active': {
      color: 'var(--color-white)',
      backgroundColor: 'var(--color-primary)',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      boxShadow: 'var(--image-box-shadow)',
      marginTop: '1rem'
    }
  },
  listItem: {
    padding: 0,
    marginRight: '20px',
    width: 'auto',
    display: 'flex'
  }
}));

function RhAdminHeader(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // function handleMenu(event) {
  //   setAnchorEl(event.currentTarget);
  // }

  function handleClose() {
    setAnchorEl(null);
  }

  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.setItem('wasSessionExpired', false);
    localStorage.setItem('location', '');
    dispatch(logoutUser(props.history, true));
  };

  // useFetching(getUser, props.history, Cookies.get("token"));
  const user = useSelector((state) => state.authentication.user);

  return (
    <div className={classes.root} style={{ height: '56px !important' }}>
      <AppBar
        position="relative"
        className={classes.appBar}
        style={{
          boxShadow: 'none',
          borderBottom: '1px solid var(--color-light-grey)'
        }}>
        <Toolbar className={classes.toolbar}>
          <Link to="/admin/orgs">
            <div className="logo" style={{ paddingLeft: 0 }}>
              <img src={logo} width="150" height="30" alt="NVADR Logo" />
            </div>
          </Link>
          <List style={{ display: 'flex', flexDirection: 'row' }}>
            <ListItem button className={classes.listItem} disableRipple>
              <NavLink exact to="/admin/orgs" className={classes.listLinks}>
                <ListItemIcon className={classes.listItemIcon}>
                  <OrganisationIcon />
                </ListItemIcon>
                <ListItemText primary="Organisations" />
              </NavLink>
            </ListItem>
            <ListItem button className={classes.listItem} disableRipple>
              <NavLink exact to="/admin/users" className={classes.listLinks}>
                <ListItemIcon className={classes.listItemIcon}>
                  <UsersIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </NavLink>
            </ListItem>

            <ListItem button className={classes.listItem} disableRipple>
              <NavLink
                exact
                to="/admin/rh/settings"
                className={classes.listLinks}>
                <ListItemIcon className={classes.listItemIcon}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </NavLink>
            </ListItem>
          </List>
          <div className={classes.profile}>
            <p>
              {user.name.split(' ').shift()}:{' '}
              <span
                className={`status__tags user_roles ${toLower(user.role)}-role`}
                style={{ width: 'auto', display: 'inline-block' }}>
                {user.role}
              </span>
            </p>
          </div>
          <div className={classes.accountIcon}>
            <IconButton color="inherit" size="small">
              <LogoutButton header={true} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              open={open}
              onClose={handleClose}>
              <MenuItem onClick={handleLogout} className={classes.menu}>
                {/* <LogOutIcon className={classes.menuIcon} /> */}
                {/* Log out */}
                <LogoutButton header={true} />
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withRouter(memo(RhAdminHeader));
