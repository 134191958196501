import {
  SAVE_ERROR_DATA,
  SAVE_AWS_KEY_ERROR_DATA,
  JIRA_ERROR,
} from "action_types/error_data";

const initialState = {
  assetData: [],
  errorMessages: "",
  keys: [],
  awsErrorMessages: "",
  awsNoSubscriptionError: "",
  awsIamNoSubscriptionError: "",
  jiraErrorMessage: "",
  gcpError: "",
  doError: "",
  cloudflareError: "",
  otpErrorMessage: "",
  orgCodeErrorMessage: "",
  subscriptionError: "",
  createDescriptionErrors: [],
  passwordChangeError: "",
  arnErrorData: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SAVE_ERROR_DATA:
      return {
        ...state,
        assetData: action.assetData,
        errorMessages: action.errorMessages,
      };
    case SAVE_AWS_KEY_ERROR_DATA:
      return {
        ...state,
        keys: action.data,
        awsErrorMessages: action.awsErrorMessages,
      };
    case "AWS_NO_SUBSCRIPTION_ERROR":
      return {
        ...state,
        awsNoSubscriptionError: action.awsErrorMessages,
      };
    case "AWS_IAM_NO_SUBSCRIPTION_ERROR":
      return {
        ...state,
        awsIamNoSubscriptionError: action.awsErrorMessages,
      };
    case "AWS_ARN_ERROR_DATA":
      return {
        ...state,
        awsIamNoSubscriptionError: action.awsErrorMessages,
        arnErrorData: action.data,
      };
    case JIRA_ERROR:
      return {
        ...state,
        jiraErrorMessage: action.errorMessage,
      };
    case "GCP_ERROR":
      return {
        ...state,
        gcpError: action.errorMessage,
      };
    case "DO_ERROR":
      return {
        ...state,
        doError: action.errorMessage,
      };
    case "CLOUDFLARE_ERROR":
      return {
        ...state,
        cloudflareError: action.errorMessage,
      };
    case "OTP_ERROR":
      return {
        ...state,
        otpErrorMessage: action.errorMessage,
      };
    case "SUBSCRIPTION_ERROR":
      return {
        ...state,
        subscriptionError: action.errorMessage,
      };
    case "ORG_CODE_ERROR":
      return {
        ...state,
        orgCodeErrorMessage: action.errorMessage,
      };
    case "CREATE_DESCRIPTION_ERROR":
      return {
        ...state,
        createDescriptionErrors: action.errorMessages,
      };
    case "PASSWORD_CHANGE_ERROR":
      return {
        ...state,
        passwordChangeError: action.errorMessage,
      };
    default:
      return state;
  }
}
