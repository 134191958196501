import {
  FETCH_ASSET_SCAN_DETAILS_SUCCESS,
  FETCH_SCAN_DETAILS_REQUEST,
  FETCH_SCAN_DATA_GROUPED_BY_ASSET_REQUEST,
  FETCH_SCAN_DATA_GROUPED_BY_ASSET_SUCCESS,
  FETCH_SCAN_DATA_GROUPED_BY_VULNERABILITY_REQUEST,
  FETCH_SCAN_DATA_GROUPED_BY_VULNERABILITY_SUCCESS,
  GROUPED_BY_CARDS_ASSETS,
  GROUPED_BY_CARDS_ISSUES
} from 'action_types/scan_details';
import { API, APIv2, fileAPI } from 'utils/api';
import moment from 'moment-timezone';
import map from 'lodash/map';
import { fetchRhPieChartData } from 'actions/charts';
import { checkSeverity } from 'utils/severity_check';

import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import startsWith from 'lodash/startsWith';
import countBy from 'lodash/countBy';
import { errorHandler } from 'utils/error_handler';
import { setErrorData } from 'actions/error_data';
import { showSuccessModal } from 'actions/modals';
import { fetchRhSecurityRisksDetails } from 'actions/issue_table';
import { fetchScansOverview } from 'actions/scan_table';
import { fetchDashboardStatus } from 'actions/dashboard';
import shortId from 'shortid';

export const resetReducerData = () => (dispatch) => {
  dispatch({
    type: 'RESET_REDUCER_DATA'
  });
};

export const resetErrorInfo = () => (dispatch) => {
  dispatch({
    type: 'RESET_ERROR_INFO'
  });
};

export const fetchScanMetaData = (data) => (dispatch) => {
  dispatch({
    type: 'SCAN_META_DATA',
    scanId: data.scan_id || data.job_id,
    scanType: data.scan_type,
    scanStartDate: moment.utc(data.scan_created || data.created).format('ll'),
    scanName: data.scan_name,
    targets: data.targets
  });
};

export const fetchAssetScanDetailsById = (data, history) => async (
  dispatch
) => {
  try {
    const reportedDate = data.reported_ts
      ? moment.utc(data.reported_ts).format('ll')
      : '--';

    const totalAssets = reduce(
      data.asset_count,
      (sum, count) => sum + count,
      0
    );
    const scanStatus = data.status;
    const numberCards = [
      {
        key: 1,
        title: 'Total Assets',
        value: totalAssets
      },
      {
        key: 2,
        title: 'Scan Status',
        value: scanStatus
      },
      {
        key: 3,
        title: 'Scan Completion Date',
        value: reportedDate
      }
    ];

    const pieChartData = data.asset_count;

    const relatedScans = map(data.related_scans, (item) => ({
      key: `${item.celery_task}#${shortId.generate()}`,
      celeryTask: item.celery_task,
      relatedScans: item.related_scans
    }));

    dispatch({
      type: FETCH_ASSET_SCAN_DETAILS_SUCCESS,
      numberCards,
      vulnerablePorts: data.vulnerable_ports.ports,
      // assetList,
      assetsCount: data.assets.count,
      targets: data.targets,
      issueList: data.issues.vulnerabilities,
      dataLeaks: data.dataleaks.dataleaks,
      relatedScans,
      issuesCount: data.issues.count,
      subscriptionStatus: data.subscription_status,
      dataLeaksCount: data.dataleaks.count,
      vulnerablePortsCount: data.vulnerable_ports.count,
      allAssetTypes: pieChartData,
      reportDownloadUrl: data.pdf_file,
      reportDownloadUrlStatus: data.pdf_status,
      dataDownloadUrl: data.csv_file,
      dataDownloadUrlStatus: data.csv_status,
      disabledTasks: data.disabled_tasks ? data.disabled_tasks : []
    });

    dispatch({
      type: 'FETCH_SCAN_DETAILS_ASSETS_SUCCESS',
      assets: data.assets.assets,
      nextItems: null,
      previousItems: null,
      assetsCount: data.assets.count
    });
    dispatch(fetchRhPieChartData(pieChartData));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchScanDetails = (jobId, history) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SCAN_DETAILS_REQUEST });

    const { data } = await API.get(`/scans/${jobId}/`);
    await dispatch(fetchAssetScanDetailsById(data, history));
    await dispatch(fetchScanMetaData(data));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const getCountByMaxSeverity = (jobId, data) => async (
  dispatch,
  getState
) => {
  try {
    // const { data } = await API.get(
    //   `/scans/${jobId}/asset-severity-count/`,
    // );

    const state = getState();
    const cards = state.cards.groupedPageAssets;
    const rhOrgPageCards = map(cards, (card) => ({ ...card }));
    const rhOrgPageNumbers = countBy(data, 'severity');
    const newCards = map(rhOrgPageCards, (card) => {
      let newCard = { ...card };
      forEach(rhOrgPageNumbers, (value, element) => {
        if (card.name !== element) {
          return;
        }
        newCard = { ...card, values: value };
      });
      return newCard;
    });
    dispatch({ type: GROUPED_BY_CARDS_ASSETS, payload: newCards });
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const getCountBySeverity = (jobId) => async (dispatch, getState) => {
  try {
    const { data } = await API.get(`/scans/${jobId}/severity-count/`);

    const cards = getState().cards.groupedPageIssues;
    const groupedPageCards = map(cards, (card) => ({
      ...card
    }));

    const newCards = map(groupedPageCards, (card) => {
      let newCard = { ...card };
      forEach(data.severity_count, (value, element) => {
        if (!startsWith(card.title, element)) {
          return;
        }
        newCard = { ...card, values: value };
      });
      return newCard;
    });
    dispatch({ type: GROUPED_BY_CARDS_ISSUES, payload: newCards });
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const fetchScanDataGroupedByAsset = (jobId, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: FETCH_SCAN_DATA_GROUPED_BY_ASSET_REQUEST });

    const { data } = await API.get(`/scans/${jobId}/group-by-asset`);

    dispatch({
      type: FETCH_SCAN_DATA_GROUPED_BY_ASSET_SUCCESS,
      data: data.results,
      groupByAssetsCount: data.count,
      nextItems: data.next,
      previousItems: data.previous
    });
    dispatch(fetchScanMetaData(data));
    dispatch(getCountByMaxSeverity(jobId, data.results));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextScanDataGroupedByAsset = (history) => async (
  dispatch,
  getState
) => {
  try {
    const url = getState().groupedDetails.groupedByAssetNextItems;
    const { data } = await API.get(url);

    const groupedByAssetData = getState().groupedDetails.groupedByAsset;
    const groupedByAssetUpdated = groupedByAssetData.concat(data.results);

    dispatch({
      type: FETCH_SCAN_DATA_GROUPED_BY_ASSET_SUCCESS,
      data: groupedByAssetUpdated,
      groupByAssetsCount: data.count,
      nextItems: data.next,
      previousItems: data.previous
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchScanDataGroupedByVulnerability = (jobId, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: FETCH_SCAN_DATA_GROUPED_BY_VULNERABILITY_REQUEST });

    const { data } = await API.get(`/scans/${jobId}/group-by-vulnerability`);
    let groupedByVulnerability = map(data.results, (item, index) => {
      const assets = map(item.assets, (vulnerability, index) => ({
        key: `${vulnerability.asset} - ${index}`,
        hostname: vulnerability.asset,
        severity: checkSeverity(vulnerability.severity),
        poc: vulnerability.proof_of_concept
      }));
      const vulnerabilityData = {
        key: `${item.condition} - ${index}}`,
        vulnerability: item.condition,
        severity: checkSeverity(item.severity),
        assets,
        description: item.description,
        recommendation: item.recommendation,
        references: item.references.join(', ')
      };
      return vulnerabilityData;
    });

    dispatch({
      type: FETCH_SCAN_DATA_GROUPED_BY_VULNERABILITY_SUCCESS,
      data: groupedByVulnerability,
      groupedByVulnerabilityCount: data.count,
      nextItems: data.next,
      previousItems: data.previous
    });
    dispatch(fetchScanMetaData(data));
    dispatch(getCountBySeverity(jobId));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextScanDataGroupedByVulnerability = (
  jobId,
  history
) => async (dispatch, getState) => {
  try {
    const url = getState().groupedDetails.groupByVulnerabilityNextItems;
    const { data } = await API.get(url);
    let groupedByVulnerability = map(data.results, (item, index) => {
      const assets = map(item.assets, (vulnerability, index) => ({
        key: `${vulnerability.asset} - ${index}`,
        hostname: vulnerability.asset,
        severity: checkSeverity(vulnerability.severity),
        poc: vulnerability.proof_of_concept
      }));
      const vulnerabilityData = {
        key: `${item.condition} - ${index}}`,
        vulnerability: item.condition,
        severity: checkSeverity(item.severity),
        assets,
        description: item.description,
        recommendation: item.recommendation,
        references: item.references.join(', ')
      };
      return vulnerabilityData;
    });

    const groupedByVulnerabilityData = getState().groupedDetails
      .groupedByVulnerability;
    const groupedByVulnerabilityUpdated = groupedByVulnerabilityData.concat(
      groupedByVulnerability
    );

    dispatch({
      type: FETCH_SCAN_DATA_GROUPED_BY_VULNERABILITY_SUCCESS,
      data: groupedByVulnerabilityUpdated,
      groupedByVulnerabilityCount: data.count,
      nextItems: data.next,
      previousItems: data.previous
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchAllScanAssets = (filterUrl, history, jobId) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_SCAN_DETAILS_ASSETS_REQUEST' });

    const baseUrl = `/org/${jobId}/asset-inventory/`;
    const { data } = await API.get(`${baseUrl}${filterUrl}`);
    dispatch({
      type: 'FETCH_SCAN_DETAILS_ASSETS_SUCCESS',
      assets: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      assetsCount: data.count,
      currentUrl: filterUrl
    });
    dispatch(getCountByMaxSeverity(jobId, data.results));
    dispatch(fetchScanMetaData(data));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextAllScanAssets = (history, nextUrl) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_SCAN_DETAILS_ASSETS_REQUEST' });

    const { data } = await API.get(nextUrl);

    dispatch({
      type: 'FETCH_SCAN_DETAILS_ASSETS_SUCCESS',
      assets: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      assetsCount: data.count,
      currentUrl: nextUrl ? `?${nextUrl.split('?')[1]}` : ''
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchAllScanIssues = (filterUrl, history, jobId) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_SCAN_DETAILS_ISSUES_REQUEST' });

    const baseUrl = `/org/${jobId}/issues/`;
    const { data } = await API.get(`${baseUrl}${filterUrl}`);

    dispatch({
      type: 'FETCH_SCAN_DETAILS_ISSUES_SUCCESS',
      issueList: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      issuesCount: data.count,
      currentUrl: filterUrl
    });
    dispatch(fetchScanMetaData(data));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextAllScanIssues = (history, nextUrl) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_SCAN_DETAILS_ISSUES_REQUEST' });

    const { data } = await API.get(nextUrl);

    dispatch({
      type: 'FETCH_SCAN_DETAILS_ISSUES_SUCCESS',
      issueList: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      issuesCount: data.count,
      currentUrl: nextUrl ? `?${nextUrl.split('?')[1]}` : ''
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchAllScanDataleaks = (filterUrl, history, jobId) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_SCAN_DETAILS_DATA_LEAKS_REQUEST' });

    const baseUrl = `/org/${jobId}/dataleaks/`;
    const { data } = await API.get(`${baseUrl}${filterUrl}`);

    dispatch({
      type: 'FETCH_SCAN_DETAILS_DATA_LEAKS_SUCCESS',
      dataLeaks: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      dataLeaksCount: data.count,
      currentUrl: filterUrl
    });
    dispatch(fetchScanMetaData(data));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextAllScanDataleaks = (history, nextUrl) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_SCAN_DETAILS_DATA_LEAKS_REQUEST' });

    const { data } = await API.get(nextUrl);

    dispatch({
      type: 'FETCH_SCAN_DETAILS_DATA_LEAKS_SUCCESS',
      dataLeaks: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      dataLeaksCount: data.count,
      currentUrl: nextUrl ? `?${nextUrl.split('?')[1]}` : ''
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchAllScanAssetExposure = (filterUrl, history, jobId) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_SCAN_DETAILS_ASSETS_REQUEST' });

    const baseUrl = `/org/${jobId}/asset-exposure/`;
    const { data } = await API.get(`${baseUrl}${filterUrl}`);

    dispatch({
      type: 'FETCH_SCAN_DETAILS_ASSETS_SUCCESS',
      assets: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      assetsCount: data.count,
      currentUrl: filterUrl
    });
    dispatch(getCountByMaxSeverity(jobId, data.results));
    dispatch(fetchScanMetaData(data));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextAllScanAssetExposure = (history, nextUrl) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_SCAN_DETAILS_PORTS_REQUEST' });

    const { data } = await API.get(nextUrl);

    dispatch({
      type: 'FETCH_SCAN_DETAILS_ASSETS_SUCCESS',
      assets: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      assetsCount: data.count,
      currentUrl: nextUrl ? `?${nextUrl.split('?')[1]}` : ''
    });
    dispatch(getCountByMaxSeverity(history, data.results));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const getAssetsType = () => async (dispatch) => {
  try {
    const res = await API.get(`/orgs/asset/types/`);
    if (res.status === 200) {
      const { data } = res;
      dispatch({
        type: 'FETCH_ASSETS_TYPE_LIST',
        data: data.types
      });
    }
  } catch (error) {
    errorHandler(error, dispatch);
  }
};
export const getIssueAssetsType = (orgId, asset) => async (dispatch) => {
  try {
    let url = `/org/${orgId}/list/asset-types/`;
    if (asset) {
      url = `/org/${orgId}/list/asset-types/?${asset}`;
    }
    const res = await API.get(url);
    if (res.status === 200) {
      const { data } = res;
      dispatch({
        type: 'FETCH_ASSETS_TYPE_LIST',
        data
      });
    }
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const getAllAssetTypes = (scanId) => async (dispatch) => {
  try {
    const { data } = await API.get(`/scans/${scanId}/asset-types`);
    dispatch({
      type: 'FETCH_ASSET_UNIQUE_TYPES',
      data: data['asset-types']
    });
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const getAllIssueTypes = (scanId) => async (dispatch) => {
  try {
    const { data } = await API.get(`/scans/${scanId}/issue-types`);
    dispatch({
      type: 'FETCH_ISSUE_UNIQUE_TYPES',
      data: data['issue-types']
    });
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const getIssueTitles = () => async (dispatch) => {
  try {
    const { data } = await API.get(`/issues/titles/`);
    dispatch({
      type: 'FETCH_ISSUE_TITLE_LIST',
      data
    });
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const getIssueAssets = (orgId) => async (dispatch) => {
  try {
    const res = await API.get(`org/${orgId}/list/asset-exposures/`);
    if (res.status === 200) {
      const { data } = res;
      dispatch({
        type: 'FETCH_ISSUE_ASSETS_LIST',
        data
      });
    }
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const getIssuePorts = (orgId, assets) => async (dispatch) => {
  try {
    let basePortUrl = `/org/${orgId}/open-ports/`;
    if (assets) {
      basePortUrl = `/org/${orgId}/open-ports/?q=${assets}`;
    }

    const res = await API.get(basePortUrl);
    if (res.status === 200) {
      const { data } = res;
      const ports = data.filter(el => el !== null)
      dispatch({
        type: 'FETCH_ISSUE_PORTS_LIST',
        data: ports
      });
    }
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const getSignatureList = () => async (dispatch) => {
  try {
    const res = await API.get(`/dataleak/signatures/`);
    if (res.status === 200) {
      const { data } = res;
      dispatch({
        type: 'FETCH_SIGNATURE_LIST',
        data
      });
    }
  } catch (error) {
    errorHandler(error, dispatch);
  }
};
export const getAssetTypeList = () => async (dispatch) => {
  try {
    const res = await API.get(`/org/third-party-asset-types/`);
    if (res.status === 200) {
      const { data } = res;
      dispatch({
        type: 'FETCH_ASSET_TYPE_LIST',
        data
      });
    }
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const getDataleakTargetList = (orgId) => async (dispatch) => {
  try {
    const res = await API.get(`/org/${orgId}/targets/`);
    if (res.status === 200) {
      const { data } = res;
      dispatch({
        type: 'FETCH_DATALEAK_TARGET_LIST',
        data
      });
    }
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const getAllDataLeaksSignature = (scanId) => async (dispatch) => {
  try {
    const res = await API.get(`/scans/${scanId}/dataleak-types/`);
    if (res.status === 200) {
      const { data } = res;
      dispatch({
        type: 'FETCH_DATA_LEAKS_SIGNATURE_TYPES',
        data: data['dataleak-types']
      });
    }
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const fetchScanScreenshots = (history) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_SCREENSHOTS_PENDING' });
    const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
      ? JSON.parse(localStorage.getItem('AssetGroupParams'))
      : [{ group_name: "Default Group", id: null }];
    const filterUrl = assetGroupParams[0].value ? `?asset_group=${assetGroupParams[0].value}` : ''
    const { data } = await API.get(`/orgs/screenshots/${filterUrl}`);
    const urls = map(data.results, (url) => ({
      src: url.s3_url,
      url: url.asset_url
    }));
    dispatch({
      type: 'SAVE_SCREENSHOT_URLS',
      data: urls,
      // count: data.count,
      // nextItems: data.next,
      // previousItems: data.previous
    });
    dispatch(fetchScanMetaData(data));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchNextScanScreenshots = (history) => async (
  dispatch,
  getState
) => {
  try {
    // dispatch({ type: 'FETCH_SCREENSHOTS_PENDING' });
    const nextUrl = getState().scanDetails.nextItems;

    const { data } = await API.get(nextUrl);
    const urls = map(data.results, (url) => ({
      src: url.s3_url,
      url: url.asset_url
    }));
    dispatch(fetchScanMetaData(data));
    dispatch({
      type: 'SAVE_SCREENSHOT_URLS',
      data: getState().scanDetails.screenshotUrls.concat(urls),
      count: data.count,
      nextItems: data.next,
      previousItems: data.previous
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchThirdPartyScreenshots = (history) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_SCREENSHOTS_PENDING' });
    const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
      ? JSON.parse(localStorage.getItem('AssetGroupParams'))
      : [{ group_name: "Default Group", id: null }];
    const filterUrl = assetGroupParams[0].value ? `?asset_group=${assetGroupParams[0].value}` : ''
    const { data } = await API.get(`/orgs/screenshots/thirdpartyintegrations/${filterUrl}`);
    const urls = map(data['url-list'], (url) => ({
      src: url.s3_url,
      url: url.asset_url
    }));
    dispatch({
      type: 'SAVE_THIRD_PARTY_SCREENSHOT_URLS',
      data: urls
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const setShowingAssetScreenshotsTrue = () => (dispatch) => {
  dispatch({
    type: 'SHOW_ASSET_APPLICATION_SCREENSHOTS'
  });
};

export const setShowingThirdPartyScreenshotsTrue = () => (dispatch) => {
  dispatch({
    type: 'SHOW_THIRD_PARTY_ASSOCIATION_SCREENSHOTS'
  });
};

export const fetchAllNotifications = (history, value) => async (
  dispatch
) => {
  try {
    dispatch({
      type: 'GET_ALL_NOTIFICATIONS_REQUEST'
    });
    const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
      ? JSON.parse(localStorage.getItem('AssetGroupParams'))
      : [{ name: "Default Group", value: '' }]
    const filterUrl = assetGroupParams[0].value ? `asset_group=${assetGroupParams[0].value}` : ''
    let url = `/notifications/?${filterUrl}`;
    if (value) {
      url = `/notifications/${value}&${filterUrl}`;
    }
    const { data } = await APIv2.get(url);
    const notifications = map(data.notifications, (item) => {
      const date = moment.utc(item.created_on).format('ll');
      const url = item.url ? new URL(item.url) : '';
      return {
        ...item,
        id: item.id,
        status: item.status,
        is_seen: item.status === "NEW" ? false : true,
        msg: item.content,
        event_type: item.notification_type === "new_asset_count" ? "asset-count" : item.notification_type === "new_dataleak_count" ? 'dataleak-count' : item.notification_type === "new_issue_count" ? 'issue-count' : item.notification_type,
        severity: checkSeverity(item.metadata.severity),
        date,
        url: url ? `${url.pathname}${url.search}` : ''
      };
    });

    dispatch({
      type: 'GET_ALL_NOTIFICATIONS_SUCCESS',
      notifications,
      nextItems: data.next,
      previousItems: data.previous,
      totalCount: data.count,
      unreadCount: data.unread
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchActivityNotifications = (history, value, type) => async (
  dispatch
) => {
  try {
    dispatch({
      type: 'GET_ALL_NOTIFICATIONS_REQUEST'
    });
    const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
      ? JSON.parse(localStorage.getItem('AssetGroupParams'))
      : [{ name: "Default Group", value: '' }]
    const filterUrl = assetGroupParams[0].value ? `asset_group=${assetGroupParams[0].value}` : ''
    let url = `/notifications/?${filterUrl}`;
    if (value) {
      url = `/notifications/?${type}=${value}&${filterUrl}`;
    }
    const { data } = await APIv2.get(url);
    const notifications = map(data.notifications, (item) => {
      const date = moment.utc(item.created_on).format('ll');
      const url = item.url ? new URL(item.url) : '';
      return {
        ...item,
        id: item.id,
        status: item.status,
        is_seen: item.status === "NEW" ? false : true,
        msg: item.content,
        event_type: item.notification_type === "new_asset_count" ? "asset-count" : item.notification_type === "new_dataleak_count" ? 'dataleak-count' : item.notification_type === "new_issue_count" ? 'issue-count' : item.notification_type,
        severity: checkSeverity(item.metadata.severity),
        date,
        url: url ? `${url.pathname}${url.search}` : ''
      };
    });

    dispatch({
      type: 'GET_ACTIVITY_NOTIFICATIONS_SUCCESS',
      notifications,
      nextItems: data.next,
      previousItems: data.previous,
      totalCount: data.count,
      unreadCount: data.unread
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextNotifications = (history, selected) => async (
  dispatch,
  getState
) => {
  try {
    let url = getState().notifications.nextItems;
    if (url) {
      if (selected && selected !== '') {
        if (!url.includes('event_type')) {
          url = `${url.substr(
            0,
            url.indexOf('page')
          )}event_type=${selected}&${url.substring(url.indexOf('page'))}`;
        }
      }
      const { data } = await APIv2.get(url);
      const notifications = map(data.results, (item) => {
        const date = moment.utc(item.created_on).format('ll');
        const url = item.url ? new URL(item.url) : '';

        return {
          id: item.id,
          status: item.status,
          msg: item.msg,
          is_seen: item.is_seen,
          event_type: item.notification_type,
          severity: checkSeverity(Number(item.metadata.severity)),
          date,
          url: url ? `${url.pathname}${url.search}` : ''
        };
      });

      const notificationsData = getState().notifications.notifications;
      const notificationsUpdated = notificationsData.concat(notifications);

      dispatch({
        type: 'GET_ALL_NOTIFICATIONS_SUCCESS',
        notifications: notificationsUpdated,
        nextItems: data.next,
        previousItems: data.previous,
        totalCount: data.count,
        unreadCount: data.unread
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchLatestNotifications = () => async (dispatch) => {
  try {
    dispatch({
      type: 'GET_LATEST_NOTIFICATIONS_REQUEST'
    });
    const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
      ? JSON.parse(localStorage.getItem('AssetGroupParams'))
      : [{ name: "Default Group", value: '' }]
    const filterUrl = assetGroupParams[0].value ? `&asset_group=${assetGroupParams[0].value}` : ''
    const { data } = await APIv2.get(`/notifications/?n=5${filterUrl}`);
    const latestNotifications = map(data.notifications, (item) => {
      const date = moment.utc(item.created_on).format('ll');
      const url = item.url ? new URL(item.url) : '';
      return {
        ...item,
        id: item.id,
        status: item.status,
        is_seen: item.is_seen,
        event_type: item.notification_type === "new_asset_count" ? "asset-count" : item.notification_type === "new_dataleak_count" ? 'dataleak-count' : item.notification_type === "new_issue_count" ? 'issue-count' : item.notification_type,
        msg: item.content,
        severity: checkSeverity(Number(item.metadata.severity)),
        date,
        url: url ? `${url.pathname}${url.search}` : ''
      };
    });

    dispatch({
      type: 'GET_LATEST_NOTIFICATIONS_SUCCESS',
      latestNotifications,
      totalCount: data.count,
      unreadCount: data.unread
    });
  } catch (error) {
    // errorHandler(error, dispatch, history);
  }
};
export const updateLatestNotificationStatus = (params) => async (dispatch) => {
  try {
    const response = await APIv2.put(`/notifications/`, params);

    if (response.status === 200) {
      dispatch(fetchAllNotifications());
    }
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const updateAllNotificationStatus = (params, history) => async (dispatch) => {
  try {
    dispatch({
      type: 'GET_ALL_NOTIFICATIONS_REQUEST'
    });
    const response = await APIv2.put(`/notifications/`, params);

    if (response.status === 200) {
      dispatch(fetchAllNotifications());
      // dispatch(updateLatestNotificationStatus());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchScanDataGroupedByDataLeaks = (jobId, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_SCAN_DATA_GROUPED_BY_DATA_LEAK_REQUEST' });

    const { data } = await API.get(`/scans/${jobId}/group-by-dataleak`);
    let groupedByDataLeak = map(data.results, (item, index) => {
      const assets = map(item.assets, (dataLeak) => ({
        key: `${dataLeak.asset} - ${dataLeak.asset_id}`,
        asset: dataLeak.asset,
        sensitive_info: dataLeak.sensitive_info,
        sensitive_url: dataLeak.sensitive_url
      }));
      const dataLeakData = {
        key: `${item.signature} - ${index}}`,
        signature: item.signature,
        assets
      };
      return dataLeakData;
    });

    dispatch({
      type: 'FETCH_SCAN_DATA_GROUPED_BY_DATA_LEAK_SUCCESS',
      data: groupedByDataLeak,
      groupedByDataLeakCount: data.count,
      nextItems: data.next,
      previousItems: data.previous
    });
    dispatch(fetchScanMetaData(data));
    dispatch(getCountBySeverity(jobId));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextScanDataGroupedByDataLeaks = (jobId, history) => async (
  dispatch,
  getState
) => {
  try {
    const url = getState().groupedDetails.groupedByDataLeakNextItems;
    const { data } = await API.get(url);
    let groupedByDataLeak = map(data.results, (item, index) => {
      const assets = map(item.assets, (dataLeak) => ({
        key: `${dataLeak.asset} - ${dataLeak.asset_id}`,
        asset: dataLeak.asset,
        sensitive_info: dataLeak.sensitive_info,
        sensitive_url: dataLeak.sensitive_url
      }));
      const dataLeakData = {
        key: `${item.signature} - ${index}}`,
        signature: item.signature,
        assets
      };
      return dataLeakData;
    });

    const groupedByDataLeakData = getState().groupedDetails.groupedByDataLeak;
    const groupedByDataLeakUpdated = groupedByDataLeakData.concat(
      groupedByDataLeak
    );

    dispatch({
      type: 'FETCH_SCAN_DATA_GROUPED_BY_DATA_LEAK_SUCCESS',
      data: groupedByDataLeakUpdated,
      groupedByDataLeakCount: data.count,
      nextItems: data.next,
      previousItems: data.previous
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleReportRegeneration = (ordId, type, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'REGENERATE_REPORT_REQUEST' });

    const response = await API.post(`/report/${ordId}/?file_type=${type}`, {});

    if (response.status === 200) {
      dispatch({ type: 'REGENERATE_REPORT_SUCCESS' });
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: type === 'pdf' ? 'PDF generation initiated. Will be available on the portal within 5 minutes.' : 'CSV Report regenerated successfully',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const uploadRhNessusFile = (jobId, file, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'SEND_NESSUS_POST_REQUEST' });
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    const response = await fileAPI.post(
      `/scans/nessus/${jobId}/create/`,
      file,
      config
    );

    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'The .nessus file is submitted successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchScanDetails(jobId, history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const uploadToAssetInventory = (file, orgId, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'NEW_ASSET_ADD_REQUEST' });
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    const response = await fileAPI.post(
      `/org/${orgId}/asset-inventory/csv-json/`,
      file,
      config
    );
    if (response.status === 201) {
      const currentUrl = getState().assetTable.currentUrl;
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'New asset is added to the list successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchAllScanAssets(currentUrl, history, orgId));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const addToAssetInventory = (assetsToAdd, orgId, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'NEW_ASSET_ADD_REQUEST' });

    const response = await API.post(`/org/${orgId}/assets/csv/`, assetsToAdd);
    if (response.status === 201) {
      const currentUrl = getState().assetTable.currentUrl;
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message:
            assetsToAdd.length === 1
              ? 'New asset is added to the list successfully.'
              : 'New assets are added to the list successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(setErrorData([], ''));
      dispatch(showSuccessModal());
      if (history.location.pathname.includes('asset-inventory')) {
        dispatch(fetchAllScanAssets(currentUrl, history, orgId));
      }
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const editToAssetInventory = (assetsToAdd, orgId, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'NEW_ASSET_ADD_REQUEST' });

    const response = await API.put(
      `/org/${orgId}/asset-inventory/`,
      assetsToAdd
    );
    if (response.status === 200) {
      const currentUrl = getState().assetTable.currentUrl;
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Asset is updated to the list successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchAllScanAssets(currentUrl, history, orgId));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const uploadToIssueInventory = (file, orgId, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'NEW_ASSET_ADD_REQUEST' });
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    const response = await fileAPI.post(
      `/org/${orgId}/issues/csv-json/`,
      file,
      config
    );
    if (response.status === 201) {
      const currentUrl = getState().issueTable.currentUrl;
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'New issue is added to the list successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchAllScanIssues(currentUrl, history, orgId));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const addToIssueInventory = (issuesToAdd, orgId, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'NEW_ASSET_ADD_REQUEST' });

    const response = await API.post(`/org/${orgId}/issues/csv/`, issuesToAdd);
    if (response.status === 201) {
      const currentUrl = getState().issueTable.currentUrl;
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message:
            issuesToAdd.length === 1
              ? 'New issue is added to the list successfully.'
              : 'New issues are added to the list successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(setErrorData([], ''));
      dispatch(showSuccessModal());
      if (history.location.pathname.includes('issues')) {
        dispatch(fetchAllScanIssues(currentUrl, history, orgId));
      }
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const updateAssetsActiveInventory = (
  assetsToUpdate,
  orgId,
  check,
  history,
  action
) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'NEW_ASSET_ADD_REQUEST' });

    let response;
    if (action === 'update') {
      response = await API.put(`/org/${orgId}/assets/active/csv/`, {
        assetsToUpdate,
        check
      });
    } else {
      response = await API.post(`/org/${orgId}/assets/active/csv/`, {
        assetsToUpdate,
        check
      });
    }
    if (response.status === 201 || response.status === 200) {
      const currentUrl = getState().issueTable.currentUrl;
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: check
            ? response.data.message
            : 'Assets are Updated to the list successfully.',
          checkActive: check,
          inventoryData: check ? assetsToUpdate : [],
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(setErrorData([], ''));
      dispatch(showSuccessModal());
      if (history.location.pathname.includes('assets')) {
        dispatch(fetchAllScanAssets(currentUrl, history, orgId));
      }
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const updateIssueActiveInventory = (
  issuesToUpdate,
  orgId,
  check,
  history,
  action
) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'NEW_ASSET_ADD_REQUEST' });

    let response;
    if (action === 'update') {
      response = await API.put(`/org/${orgId}/issues/active/csv/`, {
        issuesToUpdate,
        check
      });
    } else {
      response = await API.post(`/org/${orgId}/issues/active/csv/`, {
        issuesToUpdate,
        check
      });
    }
    if (response.status === 201 || response.status === 200) {
      const currentUrl = getState().issueTable.currentUrl;
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: check
            ? response.data.message
            : 'Issues are Updated to the list successfully.',
          checkActive: check,
          inventoryData: check ? issuesToUpdate : [],
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(setErrorData([], ''));
      dispatch(showSuccessModal());
      if (history.location.pathname.includes('issues')) {
        dispatch(fetchAllScanIssues(currentUrl, history, orgId));
      }
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const editToIssueInventory = (issuesToAdd, orgId, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'NEW_ASSET_ADD_REQUEST' });

    const response = await API.put(`/org/${orgId}/issues/`, issuesToAdd);
    if (response.status === 200) {
      const currentUrl = getState().issueTable.currentUrl;
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Issue is updated to the list successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchAllScanIssues(currentUrl, history, orgId));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const editToRhIssueInventory = (
  issuesToAdd,
  orgId,
  title,
  history
) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'NEW_ASSET_ADD_REQUEST' });

    const response = await API.put(`/org/${orgId}/issues/`, issuesToAdd);
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Issue is updated to the list successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchRhSecurityRisksDetails(orgId, title, history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const uploadToDataleakInventory = (file, orgId, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'NEW_ASSET_ADD_REQUEST' });
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    const response = await fileAPI.post(
      `/org/${orgId}/dataleaks/csv-json/`,
      file,
      config
    );
    if (response.status === 201) {
      const currentUrl = getState().issueTable.currentUrl;
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'New dataleak is added to the list successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchAllScanDataleaks(currentUrl, history, orgId));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const addToDataleaksInventory = (
  dataleaksToAdd,
  orgId,
  history
) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'NEW_ASSET_ADD_REQUEST' });

    const response = await API.post(
      `/org/${orgId}/dataleaks/csv/`,
      dataleaksToAdd
    );
    if (response.status === 201) {
      const currentUrl = getState().issueTable.currentUrl;
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message:
            dataleaksToAdd.length === 1
              ? 'New dataleak is added to the list successfully.'
              : 'New dataleaks are added to the list successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(setErrorData([], ''));
      dispatch(showSuccessModal());
      if (history.location.pathname.includes('dataleaks')) {
        dispatch(fetchAllScanDataleaks(currentUrl, history, orgId));
      }
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const addToThirdPartyAsset = (
  asset,
  orgId,
  history
) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'NEW_ASSET_ADD_REQUEST' });

    const response = await API.post(
      `/org/${orgId}/third-party/csv/`,
      asset
    );
    if (response.status === 201) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message:
            asset.length === 1
              ? 'New Third Party Asset is added to the list successfully.'
              : 'New Third Party Assets are added to the list successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(setErrorData([], ''));
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const updateToDataleaksActiveInventory = (
  dataleaksToUpdate,
  orgId,
  check,
  history,
  action
) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'NEW_ASSET_ADD_REQUEST' });

    let response;
    if (action === 'update') {
      response = await API.put(`/org/${orgId}/dataleaks/active/csv/`, {
        dataleaksToUpdate,
        check
      });
    } else {
      response = await API.post(`/org/${orgId}/dataleaks/active/csv/`, {
        dataleaksToUpdate,
        check
      });
    }

    if (response.status === 201 || response.status === 200) {
      const currentUrl = getState().issueTable.currentUrl;
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: check
            ? response.data.message
            : 'Data Leaks Updated Successfully!',
          redirectTo: '',
          checkActive: check,
          inventoryData: check ? dataleaksToUpdate : [],
          buttonTitle: 'Ok'
        }
      });
      dispatch(setErrorData([], ''));
      dispatch(showSuccessModal());
      if (history.location.pathname.includes('dataleaks')) {
        dispatch(fetchAllScanDataleaks(currentUrl, history, orgId));
      }
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const editToDataleaksInventory = (
  dataleaksToAdd,
  orgId,
  history
) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'NEW_ASSET_ADD_REQUEST' });

    const response = await API.put(`/org/${orgId}/dataleaks/`, dataleaksToAdd);
    if (response.status === 200) {
      const currentUrl = getState().issueTable.currentUrl;
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Dataleak is Updated to the list successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchAllScanDataleaks(currentUrl, history, orgId));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const uploadToAssetExposure = (file, orgId, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'NEW_ASSET_ADD_REQUEST' });
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    const response = await fileAPI.post(
      `/org/${orgId}/asset-exposure/csv-json/`,
      file,
      config
    );
    if (response.status === 201) {
      const currentUrl = getState().assetTable.currentUrl;
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'New asset is added to the list successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchAllScanAssetExposure(currentUrl, history, orgId));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const addToAssetExposure = (assetsToAdd, orgId, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'NEW_ASSET_ADD_REQUEST' });

    const response = await API.post(
      `/org/${orgId}/asset-exposure/`,
      assetsToAdd
    );
    if (response.status === 201) {
      const currentUrl = getState().assetTable.currentUrl;
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'New asset is added to the list successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      if (history.location.pathname.includes('asset-exposure')) {
        dispatch(fetchAllScanAssetExposure(currentUrl, history, orgId));
      }
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const editToAssetExposure = (assetsToAdd, orgId, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'NEW_ASSET_ADD_REQUEST' });

    const response = await API.put(
      `/org/${orgId}/asset-exposure/`,
      assetsToAdd
    );
    if (response.status === 200) {
      const currentUrl = getState().assetTable.currentUrl;
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Asset is updated to the list successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchAllScanAssetExposure(currentUrl, history, orgId));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const getAssetExposureFilterType = (orgId, history) => async (
  dispatch
) => {
  try {
    // dispatch({ type: 'FETCH_ORGS_ASSET_UNIQUE_TYPES' });

    const { data } = await API.get(`/org/${orgId}/asset-exposure-tags/`);
    if (data.types.length) {
      dispatch({
        type: 'FETCH_ORGS_ASSET_UNIQUE_TYPES',
        data: data.types
      });
    }
    if (data.tags.length) {
      dispatch({
        type: 'FETCH_ASSET_EXP_TAGS_SUCCESS',
        tags: data.tags
      });
    }
    if (data.correlation_scores.length) {
      dispatch({
        type: 'FETCH_ASSET_EXP_CORRELATION_SCORES_SUCCESS',
        correlationScores: data.correlation_scores
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const getAssetInventoryFilterType = (orgId, history) => async (
  dispatch
) => {
  try {
    // dispatch({ type: 'FETCH_ORGS_ASSET_UNIQUE_TYPES' });

    const { data } = await API.get(`/org/${orgId}/asset-inventory-tags/`);
    if (data.types.length) {
      dispatch({
        type: 'FETCH_ORGS_ASSET_UNIQUE_TYPES',
        data: data.types
      });
    }
    if (data.tags.length) {
      dispatch({
        type: 'FETCH_ASSET_EXP_TAGS_SUCCESS',
        tags: data.tags
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const addNewTarget = (assetsToAdd, orgId, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'NEW_TARGET_ADD_REQUEST' });

    const response = await API.put(`/org/${orgId}/update-targets/`, {
      targets: assetsToAdd
    });
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'New target is added to the list successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(getDataleakTargetList(orgId));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchReportData = (history, page) => async (dispatch) => {
  try {
    dispatch({ type: 'GET_ALL_REPORT_REQUEST' });
    const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
      ? JSON.parse(localStorage.getItem('AssetGroupParams'))
      : [{ group_name: "asset_group", value: "" }];
    const filterUrl = assetGroupParams[0].value ? `&asset_group=${assetGroupParams[0].value}` : ''
    const url = `/orgs/pdf-csv/range/?file_type=${page}${filterUrl}`;
    const { data } = await API.get(url);

    dispatch({
      type: 'GET_ALL_REPORT_SUCCESS',
      data: data.results || [],
      nextItems: data.next,
      previousItems: data.previous,
      totalCount: data.count
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchFilterReportData = (history, page, filter) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'GET_ALL_REPORT_REQUEST' });
    const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
      ? JSON.parse(localStorage.getItem('AssetGroupParams'))
      : [{ group_name: "asset_group", value: "" }];
    const filterUrl = assetGroupParams[0].value ? `&asset_group=${assetGroupParams[0].value}` : ''
    const newFilter = filter ? `&${filter}` : ''
    const url = `/orgs/pdf-csv/range/?file_type=${page}${newFilter}${filterUrl}`;
    const { data } = await API.get(url);

    dispatch({
      type: 'GET_ALL_REPORT_SUCCESS',
      data: data.results || [],
      nextItems: data.next,
      previousItems: data.previous,
      totalCount: data.count
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const nextFetchReportData = (history) => async (dispatch, getState) => {
  try {
    const nextUrl = getState().notifications.nextItems;

    const { data } = await API.get(nextUrl);
    const reportData = getState().notifications.report_data;
    const dataUpdated = reportData.concat(data.results);

    dispatch({
      type: 'GET_ALL_REPORT_SUCCESS',
      data: dataUpdated || [],
      nextItems: data.next,
      previousItems: data.previous,
      totalCount: data.count
    });
  } catch (error) {
    if (error.response.status !== 400) {
      errorHandler(error, dispatch, history);
    }
  }
};

export const fetchRhDataleaksList = (orgId, type, history, search) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_RH_DATA_LEAKS_REQUEST' });
    const manual = type === 'manual' ? false : true;
    const baseUrl = `/orgs/${orgId}/dataleak-inventory/?manual_owner=${manual}`;
    const assetSearch = search ? `&asset=${search}` : '';
    const { data } = await APIv2.get(`${baseUrl}${assetSearch}`);
    dispatch({
      type: 'FETCH_RH_DATA_LEAKS_SUCCESS',
      dlList: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      dataLeaksCount: data.count
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchNextRhDataleaksList = (history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'FETCH_RH_DATA_LEAKS_REQUEST' });
    const url = getState().issueTable.nextItems;
    const { data } = await APIv2.get(url);
    dispatch({
      type: 'FETCH_RH_DATA_LEAKS_SUCCESS',
      dlList: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      dataLeaksCount: data.count
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchRhAssetExposureList = (
  orgId,
  type,
  history,
  search
) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_RH_ASSET_EXPOSURE_REQUEST' });
    const manual = type === 'manual' ? false : true;
    const baseUrl = `/orgs/${orgId}/asset-exposure/?manual_owner=${manual}`;
    const assetSearch = search ? `&asset=${search}` : '';
    const { data } = await APIv2.get(`${baseUrl}${assetSearch}`);
    dispatch({
      type: 'FETCH_RH_ASSET_EXPOSURE_SUCCESS',
      aeList: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      assetExposureCount: data.count
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextRhAssetExposureList = (history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'FETCH_RH_ASSET_EXPOSURE_REQUEST' });
    const url = getState().issueTable.nextItems;
    const { data } = await APIv2.get(url);
    dispatch({
      type: 'FETCH_RH_ASSET_EXPOSURE_SUCCESS',
      aeList: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      assetExposureCount: data.count
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const deleteKillScan = (history) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'FETCH_RH_ASSET_EXPOSURE_REQUEST' });
    const response = await API.post(`/orgs/kill-scan/`);
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Scans stopped successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(fetchDashboardStatus());
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const startKillScan = (history) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'FETCH_RH_ASSET_EXPOSURE_REQUEST' });
    const response = await API.delete(`/orgs/kill-scan/`);
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Scans resumed successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(fetchDashboardStatus());
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const stopScan = (orgId, history) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'FETCH_RH_ASSET_EXPOSURE_REQUEST' });
    const response = await API.post(`/orgs/kill-scan/`, { org_id: orgId });
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Scans stopped successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchScansOverview(history, orgId));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const resumeScan = (orgId, history) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'FETCH_RH_ASSET_EXPOSURE_REQUEST' });
    const response = await API.delete(`/orgs/kill-scan/`, {
      data: { org_id: orgId }
    });
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Scans resumed successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchScansOverview(history, orgId));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
