const intitialState = {
  key: new Date(), // This field makes the tour to re-render when we restart the tour
  stepsEnabled: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  hintsEnabled: false,
  steps: []
};

export default function(state = intitialState, action) {
  switch (action.type) {
    case 'START_TOUR':
      return { ...state, stepsEnabled: true };
    case 'RESET':
      return { ...state, stepIndex: 0 };
    case 'STOP_TOUR':
      return { ...state, stepsEnabled: false };
    case 'NEXT_PREV_TOUR':
      return { ...state, ...action.payload };
    case 'RESTART_TOUR':
      return {
        ...state,
        stepIndex: 0,
        stepsEnabled: true,
        loading: false,
        key: new Date()
      };
    default:
      return state;
  }
}
