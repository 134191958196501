import {
  SHOW_SUCCESS_MODAL,
  HIDE_SUCCESS_MODAL,
  SHOW_ADD_TARGET_MODAL,
  HIDE_ADD_TARGET_MODAL,
  SHOW_CONFIRM_DELETE_MODAL,
  HIDE_CONFIRM_DELETE_MODAL,
  SHOW_ADD_TAGS_MODAL,
  HIDE_ADD_TAGS_MODAL,
  SHOW_ADD_ISSUE_TRACKER_MODAL,
  HIDE_ADD_ISSUE_TRACKER_MODAL,
  SHOW_ASSIGN_USER_MODAL,
  HIDE_ASSIGN_USER_MODAL,
  SHOW_CONFIRM_DELETE_TAG_MODAL,
  HIDE_CONFIRM_DELETE_TAG_MODAL,
  SHOW_ADD_RH_ASSETS_MODAL,
  HIDE_ADD_RH_ASSETS_MODAL,
  SHOW_FAILURE_MODAL,
  HIDE_FAILURE_MODAL,
  SHOW_ADD_USER_MODAL,
  HIDE_ADD_USER_MODAL,
  SHOW_CHANGE_PASSWORD_MODAL,
  HIDE_CHANGE_PASSWORD_MODAL,
  SHOW_CONFIRM_DELETE_SCAN_MODAL,
  HIDE_CONFIRM_DELETE_SCAN_MODAL,
  SHOW_CONFIRM_RE_SCAN_MODAL,
  HIDE_CONFIRM_RE_SCAN_MODAL,
  SHOW_CONFIRM_DELETE_USER_MODAL,
  HIDE_CONFIRM_DELETE_USER_MODAL,
  SHOW_ADD_ORG_ASSET_MAPPING_MODAL,
  HIDE_ADD_ORG_ASSET_MAPPING_MODAL,
  SHOW_EDIT_ORG_MODAL,
  HIDE_EDIT_ORG_MODAL,
  SHOW_CREATE_DESCRIPTION_MODAL,
  HIDE_CREATE_DESCRIPTION_MODAL,
  SHOW_ADD_EDIT_DATALEAK_SIGNATURE_MODAL,
  HIDE_ADD_EDIT_DATALEAK_SIGNATURE_MODAL,
  SHOW_UPLOAD_RH_ASSETS_PROFILER_MODAL,
  HIDE_UPLOAD_RH_ASSETS_PROFILER_MODAL,
  SHOW_RH_NESSUS_FILE_MODAL,
  HIDE_RH_NESSUS_FILE_MODAL,
  SHOW_ADD_SETTINGS_TARGETS_MODAL,
  HIDE_ADD_SETTINGS_TARGETS_MODAL,
  SHOW_CANCEL_SUBSCRIPTION_MODAL,
  HIDE_CANCEL_SUBSCRIPTION_MODAL,
  SHOW_PAYMENT_AUTHENTICATION_MODAL,
  HIDE_PAYMENT_AUTHENTICATION_MODAL,
  SHOW_PAYMENT_METHOD_UPDATE_MODAL,
  HIDE_PAYMENT_METHOD_UPDATE_MODAL,
  SHOW_ADD_RH_TARGET_MODAL,
  HIDE_ADD_RH_TARGET_MODAL,
} from "action_types/modals";

export const showSuccessModal = () => (dispatch) => {
  dispatch({ type: SHOW_SUCCESS_MODAL });
};

export const hideSuccessModal = () => (dispatch) => {
  dispatch({ type: HIDE_SUCCESS_MODAL });
};

export const showFailureModal = () => (dispatch) => {
  dispatch({ type: SHOW_FAILURE_MODAL });
};

export const hideFailureModal = () => (dispatch) => {
  dispatch({ type: HIDE_FAILURE_MODAL });
};

export const showAddTargetModal = () => (dispatch) => {
  dispatch({ type: SHOW_ADD_TARGET_MODAL });
};

export const hideAddTargetModal = () => (dispatch) => {
  dispatch({ type: HIDE_ADD_TARGET_MODAL });
};

export const showConfirmDeleteModal = (assetKey) => (dispatch) => {
  dispatch({ type: SHOW_CONFIRM_DELETE_MODAL, assetKey });
};

export const hideConfirmDeleteModal = () => (dispatch) => {
  dispatch({ type: HIDE_CONFIRM_DELETE_MODAL });
};

export const showAddTagsModal = () => (dispatch) => {
  dispatch({ type: SHOW_ADD_TAGS_MODAL });
};

export const hideAddTagsModal = () => (dispatch) => {
  dispatch({ type: HIDE_ADD_TAGS_MODAL });
};
export const showAddIssueTrackerModal = () => (dispatch) => {
  dispatch({ type: SHOW_ADD_ISSUE_TRACKER_MODAL });
};

export const hideAddIssueTrackerModal = () => (dispatch) => {
  dispatch({ type: HIDE_ADD_ISSUE_TRACKER_MODAL });
};

export const showUpdateAssignUserModal = () => (dispatch) => {
  dispatch({ type: SHOW_ASSIGN_USER_MODAL });
};

export const hideUpdateAssignUserModal = () => (dispatch) => {
  dispatch({ type: HIDE_ASSIGN_USER_MODAL });
};

export const showConfirmDeleteTagModal = () => (dispatch) => {
  dispatch({ type: SHOW_CONFIRM_DELETE_TAG_MODAL });
};

export const hideConfirmDeleteTagModal = () => (dispatch) => {
  dispatch({ type: HIDE_CONFIRM_DELETE_TAG_MODAL });
};

export const showAddRhAssetsModal = () => (dispatch) => {
  dispatch({ type: SHOW_ADD_RH_ASSETS_MODAL });
};

export const hideAddRhAssetsModal = () => (dispatch) => {
  dispatch({ type: HIDE_ADD_RH_ASSETS_MODAL });
};

export const showAddUserModal = () => (dispatch) => {
  dispatch({ type: SHOW_ADD_USER_MODAL });
};

export const hideAddUserModal = () => (dispatch) => {
  dispatch({ type: HIDE_ADD_USER_MODAL });
};
export const showChangePasswordModal = () => (dispatch) => {
  dispatch({ type: SHOW_CHANGE_PASSWORD_MODAL });
};

export const hideChangePasswordModal = () => (dispatch) => {
  dispatch({ type: HIDE_CHANGE_PASSWORD_MODAL });
};

export const showConfirmDeleteScanModal = () => (dispatch) => {
  dispatch({ type: SHOW_CONFIRM_DELETE_SCAN_MODAL });
};

export const hideConfirmDeleteScanModal = () => (dispatch) => {
  dispatch({ type: HIDE_CONFIRM_DELETE_SCAN_MODAL });
};

export const showConfirmReScanModal = () => (dispatch) => {
  dispatch({ type: SHOW_CONFIRM_RE_SCAN_MODAL });
};

export const hideConfirmReScanModal = () => (dispatch) => {
  dispatch({ type: HIDE_CONFIRM_RE_SCAN_MODAL });
};

export const showConfirmDeleteUserModal = () => (dispatch) => {
  dispatch({ type: SHOW_CONFIRM_DELETE_USER_MODAL });
};

export const hideConfirmDeleteUserModal = () => (dispatch) => {
  dispatch({ type: HIDE_CONFIRM_DELETE_USER_MODAL });
};

export const showAddOrgAssetMappingModal = () => (dispatch) => {
  dispatch({ type: SHOW_ADD_ORG_ASSET_MAPPING_MODAL });
};

export const hideAddOrgAssetMappingModal = () => (dispatch) => {
  dispatch({ type: HIDE_ADD_ORG_ASSET_MAPPING_MODAL });
};

export const showEditOrgModal = () => (dispatch) => {
  dispatch({ type: SHOW_EDIT_ORG_MODAL });
};

export const hideEditOrgModal = () => (dispatch) => {
  dispatch({ type: HIDE_EDIT_ORG_MODAL });
};

export const showCreateDescriptionModal = () => (dispatch) => {
  dispatch({ type: SHOW_CREATE_DESCRIPTION_MODAL });
};

export const hideCreateDescriptionModal = () => (dispatch) => {
  dispatch({ type: HIDE_CREATE_DESCRIPTION_MODAL });
};

export const showAddEditDataleakSignatureModal = () => (dispatch) => {
  dispatch({ type: SHOW_ADD_EDIT_DATALEAK_SIGNATURE_MODAL });
};

export const hideAddEditDataleakSignatureModal = () => (dispatch) => {
  dispatch({ type: HIDE_ADD_EDIT_DATALEAK_SIGNATURE_MODAL });
};

export const showAddRhTargetModal = () => (dispatch) => {
  dispatch({ type: SHOW_ADD_RH_TARGET_MODAL });
};

export const hideAddRhTargetModal = () => (dispatch) => {
  dispatch({ type: HIDE_ADD_RH_TARGET_MODAL });
};

export const showUploadRhAssetProfilersModal = () => (dispatch) => {
  dispatch({ type: SHOW_UPLOAD_RH_ASSETS_PROFILER_MODAL });
};

export const hideUploadRhAssetsProfilersModal = () => (dispatch) => {
  dispatch({ type: HIDE_UPLOAD_RH_ASSETS_PROFILER_MODAL });
};

export const showUploadRhNessusFileModal = () => (dispatch) => {
  dispatch({ type: SHOW_RH_NESSUS_FILE_MODAL });
};

export const hideUploadRhNessusFileModal = () => (dispatch) => {
  dispatch({ type: HIDE_RH_NESSUS_FILE_MODAL });
};

export const showAddTargetsSettingsModal = () => (dispatch) => {
  dispatch({ type: SHOW_ADD_SETTINGS_TARGETS_MODAL });
};

export const hideAddTargetsSettingsModal = () => (dispatch) => {
  dispatch({ type: HIDE_ADD_SETTINGS_TARGETS_MODAL });
};

export const showCancelSubscriptionModal = () => (dispatch) => {
  dispatch({ type: SHOW_CANCEL_SUBSCRIPTION_MODAL });
};

export const hideCancelSubscriptionModal = () => (dispatch) => {
  dispatch({ type: HIDE_CANCEL_SUBSCRIPTION_MODAL });
};

export const showPaymentAuthenticationModal = () => (dispatch) => {
  dispatch({ type: SHOW_PAYMENT_AUTHENTICATION_MODAL });
};

export const hidePaymentAuthenticationModal = () => (dispatch) => {
  dispatch({ type: HIDE_PAYMENT_AUTHENTICATION_MODAL });
};

export const showPaymentMethodModal = () => (dispatch) => {
  dispatch({ type: SHOW_PAYMENT_METHOD_UPDATE_MODAL });
};

export const hidePaymentMethodModal = () => (dispatch) => {
  dispatch({ type: HIDE_PAYMENT_METHOD_UPDATE_MODAL });
};

export const showAddAssetToAssetInventoryModal = () => (dispatch) => {
  dispatch({ type: "SHOW_ADD_ASSET_TO_ASSET_INVENTORY_MODAL" });
};

export const showEditAssetToAssetInventoryModal = () => (dispatch) => {
  dispatch({ type: "SHOW_EDIT_ASSET_TO_ASSET_INVENTORY_MODAL" });
};

export const hideAddAssetToAssetInventoryModal = () => (dispatch) => {
  dispatch({ type: "HIDE_ADD_ASSET_TO_ASSET_INVENTORY_MODAL" });
};
export const hideEditAssetToAssetInventoryModal = () => (dispatch) => {
  dispatch({ type: "HIDE_EDIT_ASSET_TO_ASSET_INVENTORY_MODAL" });
};

export const showAddToIssueInventoryModal = () => (dispatch) => {
  dispatch({ type: "SHOW_ADD_TO_ISSUE_INVENTORY_MODAL" });
};

export const showEditToIssueInventoryModal = () => (dispatch) => {
  dispatch({ type: "SHOW_EDIT_TO_ISSUE_INVENTORY_MODAL" });
};

export const hideEditToIssueInventoryModal = () => (dispatch) => {
  dispatch({ type: "HIDE_EDIT_TO_ISSUE_INVENTORY_MODAL" });
};

export const showAddToDataleakInventoryModal = () => (dispatch) => {
  dispatch({ type: "SHOW_ADD_TO_DATALEAK_INVENTORY_MODAL" });
};

export const showEditToDataleakInventoryModal = () => (dispatch) => {
  dispatch({ type: "SHOW_EDIT_TO_DATALEAK_INVENTORY_MODAL" });
};

export const hideEditToDataleakInventoryModal = () => (dispatch) => {
  dispatch({ type: "HIDE_EDIT_TO_DATALEAK_INVENTORY_MODAL" });
};

export const showAddToAssetExposureModal = () => (dispatch) => {
  dispatch({ type: "SHOW_ADD_TO_ASSET_EXPOSURE_MODAL" });
};
export const showEditToAssetExposureModal = () => (dispatch) => {
  dispatch({ type: "SHOW_EDIT_TO_ASSET_EXPOSURE_MODAL" });
};

export const showEditAwsKeysModal = () => (dispatch) => {
  dispatch({ type: "SHOW_EDIT_AWS_KEYS_MODAL" });
};

export const hideEditAwsKeysModal = () => (dispatch) => {
  dispatch({ type: "HIDE_EDIT_AWS_KEYS_MODAL" });
};

export const hideEditToAssetExposureModal = () => (dispatch) => {
  dispatch({ type: "HIDE_EDIT_TO_ASSET_EXPOSURE_MODAL" });
};

export const hideAddToAssetExposureModal = () => (dispatch) => {
  dispatch({ type: "HIDE_ADD_TO_ASSET_EXPOSURE_MODAL" });
};
export const showJiraStatusModal = () => (dispatch) => {
  dispatch({ type: "SHOW_JIRA_STATUS_MODAL" });
};

export const hideJiraStatusModal = () => (dispatch) => {
  dispatch({ type: "HIDE_JIRA_STATUS_MODAL" });
};
export const showJiraDetailsModal = () => (dispatch) => {
  dispatch({ type: "SHOW_JIRA_DETAILS_MODAL" });
};

export const hideJiraDetailsModal = () => (dispatch) => {
  dispatch({ type: "HIDE_JIRA_DETAILS_MODAL" });
};
export const showSlackDetailsModal = () => (dispatch) => {
  dispatch({ type: "SHOW_SLACK_DETAILS_MODAL" });
};

export const hideSlackDetailsModal = () => (dispatch) => {
  dispatch({ type: "HIDE_SLACK_DETAILS_MODAL" });
};
export const showBlacklistModal = () => (dispatch) => {
  dispatch({ type: "SHOW_BLACKLIST_MODAL" });
};

export const hideBlacklistModal = () => (dispatch) => {
  dispatch({ type: "HIDE_BLACKLIST_MODAL" });
};
export const showUnsaveChangedModal = () => (dispatch) => {
  dispatch({ type: "SHOW_UNSAVED_CHANGED_MODAL" });
};

export const hideUnsaveChangedModal = () => (dispatch) => {
  dispatch({ type: "HIDE_UNSAVED_CHANGED_MODAL" });
};
export const showEditIssueInventoryVariantModal = () => (dispatch) => {
  dispatch({ type: "SHOW_ISSUE_INVENTORY_VARIANT_MODAL" });
};

export const hideEditIssueInventoryVariantModal = () => (dispatch) => {
  dispatch({ type: "HIDE_ISSUE_INVENTORY_VARIANT_MODAL" });
};
export const showRisksTagsModal = () => (dispatch) => {
  dispatch({ type: "SHOW_RISKS_TAG_MODAL" });
};

export const hideRisksTagsModal = () => (dispatch) => {
  dispatch({ type: "HIDE_RISKS_TAG_MODAL" });
};
export const showAddCommentModal = () => (dispatch) => {
  dispatch({ type: "SHOW_ADD_COMMENT_MODAL" });
};

export const hideAddCommentModal = () => (dispatch) => {
  dispatch({ type: "HIDE_ADD_COMMENT_MODAL" });
};
export const showConfirmActionModal = () => (dispatch) => {
  dispatch({ type: "SHOW_RH_ACTION_MODAL" });
};

export const hideConfirmActionModal = () => (dispatch) => {
  dispatch({ type: "HIDE_RH_ACTION_MODAL" });
};
export const showAddJiraTypeModal = () => (dispatch) => {
  dispatch({ type: "SHOW_ADD_JIRA_TYPE_MODAL" });
};

export const hideAddJiraTypeModal = () => (dispatch) => {
  dispatch({ type: "HIDE_ADD_JIRA_TYPE_MODAL" });
};
export const showConfirmApproveModal = () => (dispatch) => {
  dispatch({ type: "SHOW_CONFIRM_APPROVE_MODAL" });
};

export const hideConfirmApproveModal = () => (dispatch) => {
  dispatch({ type: "HIDE_CONFIRM_APPROVE_MODAL" });
};
export const showRhConfirmDeleteModal = () => (dispatch) => {
  dispatch({ type: "SHOW_RH_CONFIRM_DELETE_MODAL" });
};

export const hideRhConfirmDeleteModal = () => (dispatch) => {
  dispatch({ type: "HIDE_RH_CONFIRM_DELETE_MODAL" });
};
export const showConfirmMutedModal = () => (dispatch) => {
  dispatch({ type: "SHOW_CONFIRM_MUTED_MODAL" });
};

export const hideConfirmMutedModal = () => (dispatch) => {
  dispatch({ type: "HIDE_CONFIRM_MUTED_MODAL" });
};
export const showStopScanModal = () => (dispatch) => {
  dispatch({ type: "SHOW_STOP_SCAN_MODAL" });
};

export const hideStopScanModal = () => (dispatch) => {
  dispatch({ type: "HIDE_STOP_SCAN_MODAL" });
};
export const showFinishScanModal = () => (dispatch) => {
  dispatch({ type: "SHOW_FINISH_SCAN_MODAL" });
};

export const hideFinishScanModal = () => (dispatch) => {
  dispatch({ type: "HIDE_FINISH_SCAN_MODAL" });
};
export const showIntegrationDetailsModal = () => (dispatch) => {
  dispatch({ type: "SHOW_INTEGRATION_DETAILS_MODAL" });
};

export const hideIntegrationDetailsModal = () => (dispatch) => {
  dispatch({ type: "HIDE_INTEGRATION_DETAILS_MODAL" });
};
export const showMetadataModal = () => (dispatch) => {
  dispatch({ type: "SHOW_METADATA_MODAL" });
};
export const hideMetadataModal = () => (dispatch) => {
  dispatch({ type: "HIDE_METADATA_MODAL" });
};
export const showHeaderModal = () => (dispatch) => {
  dispatch({ type: "SHOW_HEADER_MODAL" });
};
export const hideHeaderModal = () => (dispatch) => {
  dispatch({ type: "HIDE_HEADER_MODAL" });
};
export const showNotificationModal = () => (dispatch) => {
  dispatch({ type: "SHOW_NOTIFICATION_MODAL" });
};
export const hideNotificationModal = () => (dispatch) => {
  dispatch({ type: "HIDE_NOTIFICATION_MODAL" });
};
export const showIssueTrackerModal = () => (dispatch) => {
  dispatch({ type: "SHOW_ISSUE_TRACKER_MODAL" });
};
export const hideIssueTrackerModal = () => (dispatch) => {
  dispatch({ type: "HIDE_ISSUE_TRACKER_MODAL" });
};
export const collapseSidebar = () => (dispatch) => {
  dispatch({ type: "SIDEBAR_COLLAPSED" });
};
export const expandSidebar = () => (dispatch) => {
  dispatch({ type: "SIDEBAR_EXPANDED" });
};
export const showHeaderWaring = () => (dispatch) => {
  dispatch({ type: "WARNING_VISIBLE" });
};
export const hideHeaderWarning = () => (dispatch) => {
  dispatch({ type: "WARNING_HIDDEN" });
};
export const showDeleteDomains = () => (dispatch) => {
  dispatch({ type: "SHOW_DELETE_DOMAINS" });
};
export const hideDeleteDomains = () => (dispatch) => {
  dispatch({ type: "HIDE_DELETE_DOMAINS" });
};

export const showAddDomains = () => (dispatch) => {
  dispatch({ type: "SHOW_ADD_DOMAINS" });
};
export const hideAddDomains = () => (dispatch) => {
  dispatch({ type: "HIDE_ADD_DOMAINS" });
};
export const showAwsErrorDataModal = () => (dispatch) => {
  dispatch({ type: "SHOW_AWS_ERROR" });
};
export const hideAwsErrorDataModal = () => (dispatch) => {
  dispatch({ type: "HIDE_AWS_ERROR" });
};
export const showConfirm2faModal = () => (dispatch) => {
  dispatch({ type: "SHOW_2FA_MODAL" });
};
export const hideConfirm2faModal = () => (dispatch) => {
  dispatch({ type: "HIDE_2FA_MODAL" });
}
export const showSuccessPaymentModal = () => (dispatch) => {
  dispatch({ type: "SHOW_SUCCESS_PAYMENT" });
};
export const hideSuccessPaymentModal = () => (dispatch) => {
  dispatch({ type: "HIDE_SUCCESS_PAYMENT" });
};
export const showCancelPaymentModal = () => (dispatch) => {
  dispatch({ type: "SHOW_CANCEL_PAYMENT" });
};
export const hideCancelPaymentModal = () => (dispatch) => {
  dispatch({ type: "HIDE_CANCEL_PAYMENT" });
};
export const showSubscriptionWarningModal = () => (dispatch) => {
  dispatch({ type: "SHOW_SUBSCRIPTION_WARNING" });
};
export const hideSubscriptionWarningModal = () => (dispatch) => {
  dispatch({ type: "HIDE_SUBSCRIPTION_WARNING" });
};
export const showTalkToSalesModal = () => (dispatch) => {
  dispatch({ type: "SHOW_TALK_TO_SALES" });
};
export const hideTalkToSalesModal = () => (dispatch) => {
  dispatch({ type: "HIDE_TALK_TO_SALES" });
};
export const showServiceNowConnectionModal = () => (dispatch) => {
  dispatch({ type: "SHOW_SERVICE_NOW_CONNECTION" });
};
export const hideServiceNowConnectionModal = () => (dispatch) => {
  dispatch({ type: "HIDE_SERVICE_NOW_CONNECTION" });
};
export const showServiceNowStatusModal = () => (dispatch) => {
  dispatch({ type: "SHOW_SERVICE_NOW_STATUS" });
};
export const hideServiceNowStatusModal = () => (dispatch) => {
  dispatch({ type: "HIDE_SERVICE_NOW_STATUS" });
};
export const showPriotityMappingModal = () => (dispatch) => {
  dispatch({ type: "SHOW_PRIORITY_MAPPING_MODAL" });
};
export const hidePriotityMappingModal = () => (dispatch) => {
  dispatch({ type: "HIDE_PRIORITY_MAPPING_MODAL" });
};
export const showDataleaksToSecurityRisksModal = () => (dispatch) => {
  dispatch({ type: "SHOW_DATALEAK_TO_SECURITY_RISKS_MODAL" });
};
export const hideDataleaksToSecurityRisksModal = () => (dispatch) => {
  dispatch({ type: "HIDE_DATALEAK_TO_SECURITY_RISKS_MODAL" });
};
export const showSkipInventoryModal = () => (dispatch) => {
  dispatch({ type: "SHOW_SKIP_INVENTORY_MODAL" });
};
export const hideSkipInventoryModal = () => (dispatch) => {
  dispatch({ type: "HIDE_SKIP_INVENTORY_MODAL" });
};
