import { createTheme } from '@material-ui/core/styles';
import { LIGHT_THEME_COLORS as COLORS, LIGHT_BOX_SHADOW } from './constants';

const darkTheme = createTheme({
  palette: {},
  props: { COLORS },
  typography: {
    fontFamily: 'Monsterrat, Roboto, Helvetica, Arial, sans-serif'
  },
  shadows: [...LIGHT_BOX_SHADOW],
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': 'Monsterrat'
      }
    }
  }
});

export default darkTheme;
