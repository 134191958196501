const initialState = {
  data: [],
  nextItems: null,
  count: null,
  previousItems: null,
  loading: false,
  issueListLoading: false,
  dataleakLoading: false,
  celeryTasks: [],
  categoryList: [],
  issueCondition: null,
  dataleakSignature: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'FETCH_CELERY_TASK_RESULTS_REQUEST':
    case 'FETCH_UNIQUE_CELERY_TASK_RESULTS_REQUEST':
    case 'FETCH_CELERY_TASK_PERMISSION_REQUEST':
    case 'FETCH_VULNERABILITY_TASK_RESULTS_REQUEST':
    case 'FETCH_ISSUE_CONDITION_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'FETCH_DATALEAK_SIGNATURES_REQUEST':
      return {
        ...state,
        // loading: true,
        dataleakLoading: true
      };
    case 'FETCH_NEXT_DATALEAK_SIGNATURES_REQUEST':
      return {
        ...state,
        dataleakLoading: true
      };
    case 'FETCH_DATALEAK_SIGNATURES_SUCCESS':
      return {
        ...state,
        loading: false,
        dataleakLoading: false,
        data: [...action.data],
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        count: action.count
      };
    case 'FETCH_ISSUE_CONDITION_DESCRIPTION_RECOMMENDATION_REQUEST':
      return {
        ...state,
        // loading: true,
        issueListLoading: true
      };
    case 'FETCH_NEXT_ISSUE_CONDITION_DESCRIPTION_RECOMMENDATION_REQUEST':
      return {
        ...state,
        issueListLoading: true
      };
    case 'FETCH_ISSUE_CONDITION_DESCRIPTION_RECOMMENDATION_SUCCESS':
      return {
        ...state,
        loading: false,
        issueListLoading: false,
        data: [...action.data],
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        count: action.count
      };
    case 'FETCH_CELERY_TASK_RESULTS_SUCCESS':
    case 'FETCH_UNIQUE_CELERY_TASK_RESULTS_SUCCESS':
    case 'FETCH_VULNERABILITY_TASK_RESULTS_SUCCESS':
    case 'FETCH_ISSUE_CONDITION_SUCCESS':
      return {
        ...state,
        loading: false,
        data: [...action.data],
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        count: action.count
      };
    case 'FETCH_CELERY_TASK_PERMISSIONS_SUCCESS':
      return {
        ...state,
        celeryTasks: [...action.tasks],
      };
    case 'FETCH_CATEGORY_LIST_SUCCESS':
      return {
        ...state,
        categoryList: [...action.data],
      };
    case 'FETCH_ISSUE_CONDITION_DETAILS':
      return {
        ...state,
        issueCondition: action.data
      };
    case 'FETCH_DATALEAK_SIGNATURE_DETAILS':
      return {
        ...state,
        dataleakSignature: action.data
      };
    default:
      return state;
  }
}
