import { isEmpty } from 'lodash';

const updateUrlAndFetchData = async (
  baseUrl,
  dispatch,
  fetchDataFunc,
  history,
  jobId = '',
  fristLoad
) => {
  let params = new URLSearchParams(history.location.search);

  const filterParams = JSON.parse(localStorage.getItem('filterParams'))
    ? JSON.parse(localStorage.getItem('filterParams'))
    : [];
  const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
    ? JSON.parse(localStorage.getItem('AssetGroupParams'))
    : [];
  const sortBy = localStorage.getItem('sortParams');
  const filter = filterParams.concat(assetGroupParams.length ? assetGroupParams : [])
  filter.forEach((param) => {
    params.set(param.name, param.value);
    if (isEmpty(param.value)) {
      params.delete(param.name);
    }
  });
  if (sortBy) {
    params.set('ordering', sortBy);
  } else {
    params.delete('ordering');
  }
  params.delete('reset');
  if (params.toString().includes('search=')) {
    params = params
      .toString()
      .replace(/%3D/g, '=')
      .replace(/%26/g, '&')
      .replace(/%2B/g, ',')
      .replace('search=', '');
  }
  const dataUrl = params.toString() ? `?${params.toString()}` : '';
  if (!fristLoad) {
    history.push(`${baseUrl}${dataUrl}`);
  }
  await dispatch(fetchDataFunc(dataUrl, history, jobId));
};

export default updateUrlAndFetchData;
