import {
  SHOW_SUCCESS_MODAL,
  HIDE_SUCCESS_MODAL,
  SHOW_ADD_TARGET_MODAL,
  HIDE_ADD_TARGET_MODAL,
  SHOW_CONFIRM_DELETE_MODAL,
  HIDE_CONFIRM_DELETE_MODAL,
  SHOW_ADD_TAGS_MODAL,
  HIDE_ADD_TAGS_MODAL,
  SHOW_ADD_ISSUE_TRACKER_MODAL,
  HIDE_ADD_ISSUE_TRACKER_MODAL,
  SHOW_ASSIGN_USER_MODAL,
  HIDE_ASSIGN_USER_MODAL,
  SHOW_CONFIRM_DELETE_TAG_MODAL,
  HIDE_CONFIRM_DELETE_TAG_MODAL,
  SHOW_ADD_RH_ASSETS_MODAL,
  HIDE_ADD_RH_ASSETS_MODAL,
  SHOW_FAILURE_MODAL,
  HIDE_FAILURE_MODAL,
  SHOW_ADD_USER_MODAL,
  HIDE_ADD_USER_MODAL,
  SHOW_CHANGE_PASSWORD_MODAL,
  HIDE_CHANGE_PASSWORD_MODAL,
  SHOW_CONFIRM_DELETE_SCAN_MODAL,
  HIDE_CONFIRM_DELETE_SCAN_MODAL,
  SHOW_CONFIRM_RE_SCAN_MODAL,
  HIDE_CONFIRM_RE_SCAN_MODAL,
  SHOW_CONFIRM_DELETE_USER_MODAL,
  HIDE_CONFIRM_DELETE_USER_MODAL,
  SHOW_ADD_ORG_ASSET_MAPPING_MODAL,
  HIDE_ADD_ORG_ASSET_MAPPING_MODAL,
  SHOW_EDIT_ORG_MODAL,
  HIDE_EDIT_ORG_MODAL,
  SHOW_CREATE_DESCRIPTION_MODAL,
  HIDE_CREATE_DESCRIPTION_MODAL,
  SHOW_ADD_EDIT_DATALEAK_SIGNATURE_MODAL,
  HIDE_ADD_EDIT_DATALEAK_SIGNATURE_MODAL,
  SHOW_UPLOAD_RH_ASSETS_MODAL,
  HIDE_UPLOAD_RH_ASSETS_MODAL,
  SHOW_UPLOAD_RH_ASSETS_PROFILER_MODAL,
  HIDE_UPLOAD_RH_ASSETS_PROFILER_MODAL,
  SHOW_RH_NESSUS_FILE_MODAL,
  HIDE_RH_NESSUS_FILE_MODAL,
  SHOW_ADD_SETTINGS_TARGETS_MODAL,
  HIDE_ADD_SETTINGS_TARGETS_MODAL,
  SHOW_CANCEL_SUBSCRIPTION_MODAL,
  HIDE_CANCEL_SUBSCRIPTION_MODAL,
  SHOW_PAYMENT_AUTHENTICATION_MODAL,
  HIDE_PAYMENT_AUTHENTICATION_MODAL,
  SHOW_PAYMENT_METHOD_UPDATE_MODAL,
  HIDE_PAYMENT_METHOD_UPDATE_MODAL,
  SHOW_ADD_RH_TARGET_MODAL,
  HIDE_ADD_RH_TARGET_MODAL,
} from "action_types/modals";

const initialState = {
  successModal: false,
  failureModal: false,
  addTargetsModal: false,
  confirmDelete: false,
  addTagsModal: false,
  addIssueTrackerModal: false,
  assignUserModal: false,
  confirmDeleteTags: false,
  rhAddAssetsModal: false,
  rhAddTargetsModal: false,
  addUserModal: false,
  changePasswordModal: false,
  confirmDeleteScan: false,
  confirmReScan: false,
  deleteUser: false,
  addOrgAssetMapping: false,
  editOrg: false,
  createDescriptionModal: false,
  addEditDataleakSignature: false,
  uploadRhAssetsModal: false,
  uploadRhAssetsProfilerModal: false,
  uploadRhNessusFileModal: false,
  addTargetsSettingsModal: false,
  cancelSubscription: false,
  paymentAuthenticationModal: false,
  paymentMethodUpdateModal: false,
  addAssetToAssetInventory: false,
  editAssetToAssetInventory: false,
  editToIssueInventory: false,
  editToDataleakInventory: false,
  addToAssetExposure: false,
  editToAssetExposure: false,
  jiraStatusModel: false,
  jiraDetailsModel: false,
  slackDetailsModel: false,
  blacklistModel: false,
  unsavedChangedModel: false,
  editToIssueInventoryVariant: false,
  risksTagModel: false,
  commentModal: false,
  rhActionModal: false,
  jiraTypeModal: false,
  confirmApproveModal: false,
  openStopScanModal: false,
  openFinishScanModal: false,
  integrationModal: false,
  rhConfirmDeleteModal: false,
  mutedModel: false,
  editAwsKeysModal: false,
  metadataModal: false,
  headerModal: false,
  sidebarCollapsed: false,
  warningVisible: false,
  issueTracker: false,
  notificationModal: false,
  deleteDomains: false,
  addDomains: false,
  confirm2faModal: false,
  successPaymentModal: false,
  cancelPaymentModal: false,
  showSubscrptionWarningModal: false,
  talkToSalesModal: false,
  serviceNowConnectionModal: false,
  serviceNowStatusModal: false,
  priorityMappingModal: false,
  dataleakTosecurityModal: false,
  skipInventoryModal: false,
  awsErrorModal: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_SUCCESS_MODAL:
      return {
        ...state,
        successModal: true,
      };
    case HIDE_SUCCESS_MODAL:
      return {
        ...state,
        successModal: false,
      };
    case SHOW_FAILURE_MODAL:
      return {
        ...state,
        failureModal: true,
      };
    case HIDE_FAILURE_MODAL:
      return {
        ...state,
        failureModal: false,
      };

    case SHOW_ADD_TARGET_MODAL:
      return {
        ...state,
        addTargetsModal: true,
      };
    case HIDE_ADD_TARGET_MODAL:
      return {
        ...state,
        addTargetsModal: false,
      };

    case SHOW_CONFIRM_DELETE_MODAL:
      return {
        ...state,
        confirmDelete: true,
      };
    case HIDE_CONFIRM_DELETE_MODAL:
      return {
        ...state,
        confirmDelete: false,
      };
    case SHOW_ADD_TAGS_MODAL:
      return {
        ...state,
        addTagsModal: true,
      };
    case SHOW_ADD_ISSUE_TRACKER_MODAL:
      return {
        ...state,
        addIssueTrackerModal: true,
      };
    case HIDE_ADD_ISSUE_TRACKER_MODAL:
      return {
        ...state,
        addIssueTrackerModal: false,
      };
    case SHOW_ASSIGN_USER_MODAL:
      return {
        ...state,
        assignUserModal: true,
      };
    case HIDE_ASSIGN_USER_MODAL:
      return {
        ...state,
        assignUserModal: false,
      };
    case HIDE_ADD_TAGS_MODAL:
      return {
        ...state,
        addTagsModal: false,
      };
    case SHOW_CONFIRM_DELETE_TAG_MODAL:
      return {
        ...state,
        confirmDeleteTags: true,
      };
    case HIDE_CONFIRM_DELETE_TAG_MODAL:
      return {
        ...state,
        confirmDeleteTags: false,
      };
    case SHOW_ADD_RH_ASSETS_MODAL:
      return {
        ...state,
        rhAddAssetsModal: true,
      };
    case HIDE_ADD_RH_ASSETS_MODAL:
      return {
        ...state,
        rhAddAssetsModal: false,
      };
    case SHOW_ADD_RH_TARGET_MODAL:
      return {
        ...state,
        rhAddTargetsModal: true,
      };
    case HIDE_ADD_RH_TARGET_MODAL:
      return {
        ...state,
        rhAddTargetsModal: false,
      };

    case SHOW_ADD_USER_MODAL:
      return {
        ...state,
        addUserModal: true,
      };
    case HIDE_ADD_USER_MODAL:
      return {
        ...state,
        addUserModal: false,
      };
    case SHOW_CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        changePasswordModal: true,
      };
    case HIDE_CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        changePasswordModal: false,
      };
    case SHOW_CONFIRM_DELETE_SCAN_MODAL:
      return {
        ...state,
        confirmDeleteScan: true,
      };
    case HIDE_CONFIRM_DELETE_SCAN_MODAL:
      return {
        ...state,
        confirmDeleteScan: false,
      };
    case SHOW_CONFIRM_RE_SCAN_MODAL:
      return {
        ...state,
        confirmReScan: true,
      };
    case HIDE_CONFIRM_RE_SCAN_MODAL:
      return {
        ...state,
        confirmReScan: false,
      };

    case SHOW_CONFIRM_DELETE_USER_MODAL:
      return {
        ...state,
        deleteUser: true,
      };
    case HIDE_CONFIRM_DELETE_USER_MODAL:
      return {
        ...state,
        deleteUser: false,
      };
    case "RESET_REDUCER_DATA":
      return {
        ...state,
        ...initialState,
      };
    case SHOW_ADD_ORG_ASSET_MAPPING_MODAL:
      return {
        ...state,
        addOrgAssetMapping: true,
      };
    case HIDE_ADD_ORG_ASSET_MAPPING_MODAL:
      return {
        ...state,
        addOrgAssetMapping: false,
      };

    case SHOW_EDIT_ORG_MODAL:
      return {
        ...state,
        editOrg: true,
      };
    case HIDE_EDIT_ORG_MODAL:
      return {
        ...state,
        editOrg: false,
      };
    case SHOW_CREATE_DESCRIPTION_MODAL:
      return {
        ...state,
        createDescriptionModal: true,
      };
    case HIDE_CREATE_DESCRIPTION_MODAL:
      return {
        ...state,
        createDescriptionModal: false,
      };
    case SHOW_ADD_EDIT_DATALEAK_SIGNATURE_MODAL:
      return {
        ...state,
        addEditDataleakSignature: true,
      };
    case HIDE_ADD_EDIT_DATALEAK_SIGNATURE_MODAL:
      return {
        ...state,
        addEditDataleakSignature: false,
      };
    case SHOW_UPLOAD_RH_ASSETS_MODAL:
      return {
        ...state,
        uploadRhAssetsModal: true,
      };
    case HIDE_UPLOAD_RH_ASSETS_MODAL:
      return {
        ...state,
        uploadRhAssetsModal: false,
      };
    case SHOW_UPLOAD_RH_ASSETS_PROFILER_MODAL:
      return {
        ...state,
        uploadRhAssetsProfilerModal: true,
      };
    case HIDE_UPLOAD_RH_ASSETS_PROFILER_MODAL:
      return {
        ...state,
        uploadRhAssetsProfilerModal: false,
      };
    case SHOW_RH_NESSUS_FILE_MODAL:
      return {
        ...state,
        uploadRhNessusFileModal: true,
      };
    case HIDE_RH_NESSUS_FILE_MODAL:
      return {
        ...state,
        uploadRhNessusFileModal: false,
      };
    case SHOW_ADD_SETTINGS_TARGETS_MODAL:
      return {
        ...state,
        addTargetsSettingsModal: true,
      };
    case HIDE_ADD_SETTINGS_TARGETS_MODAL:
      return {
        ...state,
        addTargetsSettingsModal: false,
      };
    case SHOW_CANCEL_SUBSCRIPTION_MODAL:
      return {
        ...state,
        cancelSubscription: true,
      };
    case HIDE_CANCEL_SUBSCRIPTION_MODAL:
      return {
        ...state,
        cancelSubscription: false,
      };
    case SHOW_PAYMENT_AUTHENTICATION_MODAL:
      return {
        ...state,
        paymentAuthenticationModal: true,
      };
    case HIDE_PAYMENT_AUTHENTICATION_MODAL:
      return {
        ...state,
        paymentAuthenticationModal: false,
      };
    case SHOW_PAYMENT_METHOD_UPDATE_MODAL:
      return {
        ...state,
        paymentMethodUpdateModal: true,
      };
    case HIDE_PAYMENT_METHOD_UPDATE_MODAL:
      return {
        ...state,
        paymentMethodUpdateModal: false,
      };
    case "SHOW_ADD_ASSET_TO_ASSET_INVENTORY_MODAL":
      return {
        ...state,
        addAssetToAssetInventory: true,
      };
    case "HIDE_ADD_ASSET_TO_ASSET_INVENTORY_MODAL":
      return {
        ...state,
        addAssetToAssetInventory: false,
      };
    case "SHOW_EDIT_ASSET_TO_ASSET_INVENTORY_MODAL":
      return {
        ...state,
        editAssetToAssetInventory: true,
      };
    case "HIDE_EDIT_ASSET_TO_ASSET_INVENTORY_MODAL":
      return {
        ...state,
        editAssetToAssetInventory: false,
      };
    case "SHOW_EDIT_TO_ISSUE_INVENTORY_MODAL":
      return {
        ...state,
        editToIssueInventory: true,
      };
    case "HIDE_EDIT_TO_ISSUE_INVENTORY_MODAL":
      return {
        ...state,
        editToIssueInventory: false,
      };
    case "SHOW_EDIT_TO_DATALEAK_INVENTORY_MODAL":
      return {
        ...state,
        editToDataleakInventory: true,
      };
    case "HIDE_EDIT_TO_DATALEAK_INVENTORY_MODAL":
      return {
        ...state,
        editToDataleakInventory: false,
      };
    case "SHOW_ADD_TO_ASSET_EXPOSURE_MODAL":
      return {
        ...state,
        addToAssetExposure: true,
      };
    case "HIDE_ADD_TO_ASSET_EXPOSURE_MODAL":
      return {
        ...state,
        addToAssetExposure: false,
      };
    case "SHOW_EDIT_TO_ASSET_EXPOSURE_MODAL":
      return {
        ...state,
        editToAssetExposure: true,
      };
    case "HIDE_EDIT_TO_ASSET_EXPOSURE_MODAL":
      return {
        ...state,
        editToAssetExposure: false,
      };
    case "SHOW_JIRA_STATUS_MODAL":
      return {
        ...state,
        jiraStatusModel: true,
      };
    case "HIDE_JIRA_STATUS_MODAL":
      return {
        ...state,
        jiraStatusModel: false,
      };
    case "SHOW_JIRA_DETAILS_MODAL":
      return {
        ...state,
        jiraDetailsModel: true,
      };
    case "HIDE_JIRA_DETAILS_MODAL":
      return {
        ...state,
        jiraDetailsModel: false,
      };
    case "SHOW_SLACK_DETAILS_MODAL":
      return {
        ...state,
        slackDetailsModel: true,
      };
    case "HIDE_SLACK_DETAILS_MODAL":
      return {
        ...state,
        slackDetailsModel: false,
      };
    case "SHOW_BLACKLIST_MODAL":
      return {
        ...state,
        blacklistModel: true,
      };
    case "HIDE_BLACKLIST_MODAL":
      return {
        ...state,
        blacklistModel: false,
      };
    case "SHOW_UNSAVED_CHANGED_MODAL":
      return {
        ...state,
        unsavedChangedModel: true,
      };
    case "HIDE_UNSAVED_CHANGED_MODAL":
      return {
        ...state,
        unsavedChangedModel: false,
      };
    case "SHOW_ISSUE_INVENTORY_VARIANT_MODAL":
      return {
        ...state,
        editToIssueInventoryVariant: true,
      };
    case "HIDE_ISSUE_INVENTORY_VARIANT_MODAL":
      return {
        ...state,
        editToIssueInventoryVariant: false,
      };
    case "SHOW_RISKS_TAG_MODAL":
      return {
        ...state,
        risksTagModel: true,
      };
    case "HIDE_RISKS_TAG_MODAL":
      return {
        ...state,
        risksTagModel: false,
      };
    case "SHOW_ADD_COMMENT_MODAL":
      return {
        ...state,
        commentModal: true,
      };
    case "HIDE_ADD_COMMENT_MODAL":
      return {
        ...state,
        commentModal: false,
      };
    case "SHOW_RH_ACTION_MODAL":
      return {
        ...state,
        rhActionModal: true,
      };
    case "HIDE_RH_ACTION_MODAL":
      return {
        ...state,
        rhActionModal: false,
      };
    case "SHOW_ADD_JIRA_TYPE_MODAL":
      return {
        ...state,
        jiraTypeModal: true,
      };
    case "HIDE_ADD_JIRA_TYPE_MODAL":
      return {
        ...state,
        jiraTypeModal: false,
      };
    case "SHOW_CONFIRM_APPROVE_MODAL":
      return {
        ...state,
        confirmApproveModal: true,
      };
    case "HIDE_CONFIRM_APPROVE_MODAL":
      return {
        ...state,
        confirmApproveModal: false,
      };
    case "SHOW_RH_CONFIRM_DELETE_MODAL":
      return {
        ...state,
        rhConfirmDeleteModal: true,
      };
    case "HIDE_RH_CONFIRM_DELETE_MODAL":
      return {
        ...state,
        rhConfirmDeleteModal: false,
      };
    case "SHOW_STOP_SCAN_MODAL":
      return {
        ...state,
        openStopScanModal: true,
      };
    case "HIDE_STOP_SCAN_MODAL":
      return {
        ...state,
        openStopScanModal: false,
      };
    case "SHOW_FINISH_SCAN_MODAL":
      return {
        ...state,
        openFinishScanModal: true,
      };
    case "HIDE_FINISH_SCAN_MODAL":
      return {
        ...state,
        openFinishScanModal: false,
      };
    case "SHOW_INTEGRATION_DETAILS_MODAL":
      return {
        ...state,
        integrationModal: true,
      };
    case "HIDE_INTEGRATION_DETAILS_MODAL":
      return {
        ...state,
        integrationModal: false,
      };
    case "SHOW_EDIT_AWS_KEYS_MODAL":
      return {
        ...state,
        editAwsKeysModal: true,
      };
    case "HIDE_EDIT_AWS_KEYS_MODAL":
      return {
        ...state,
        editAwsKeysModal: false,
      };
    case "SHOW_CONFIRM_MUTED_MODAL":
      return {
        ...state,
        mutedModel: true,
      };
    case "HIDE_CONFIRM_MUTED_MODAL":
      return {
        ...state,
        mutedModel: false,
      };
    case "SHOW_METADATA_MODAL":
      return {
        ...state,
        metadataModal: true,
      };
    case "HIDE_METADATA_MODAL":
      return {
        ...state,
        metadataModal: false,
      };
    case "SHOW_HEADER_MODAL":
      return {
        ...state,
        headerModal: true,
      };
    case "HIDE_HEADER_MODAL":
      return {
        ...state,
        headerModal: false,
      };
    case "SHOW_NOTIFICATION_MODAL":
      return {
        ...state,
        notificationModal: true,
      };
    case "HIDE_NOTIFICATION_MODAL":
      return {
        ...state,
        notificationModal: false,
      };
    case "SHOW_ISSUE_TRACKER_MODAL":
      return {
        ...state,
        issueTracker: true,
      };
    case "HIDE_ISSUE_TRACKER_MODAL":
      return {
        ...state,
        issueTracker: false,
      };
    case "SIDEBAR_COLLAPSED":
      return {
        ...state,
        sidebarCollapsed: true,
      };
    case "SIDEBAR_EXPANDED":
      return {
        ...state,
        sidebarCollapsed: false,
      };
    case "WARNING_VISIBLE":
      return {
        ...state,
        warningVisible: true,
      };
    case "WARNING_HIDDEN":
      return {
        ...state,
        warningVisible: false,
      };
    case "SHOW_DELETE_DOMAINS":
      return {
        ...state,
        deleteDomains: true,
      };
    case "HIDE_DELETE_DOMAINS":
      return {
        ...state,
        deleteDomains: false,
      };
    case "SHOW_ADD_DOMAINS":
      return {
        ...state,
        addDomains: true,
      };
    case "HIDE_ADD_DOMAINS":
      return {
        ...state,
        addDomains: false,
      };
    case "SHOW_AWS_ERROR":
      return {
        ...state,
        awsErrorModal: true,
      };
    case "HIDE_AWS_ERROR":
      return {
        ...state,
        awsErrorModal: false,
      };
    case "SHOW_2FA_MODAL":
      return {
        ...state,
        confirm2faModal: true,
      };
    case "HIDE_2FA_MODAL":
      return {
        ...state,
        confirm2faModal: false,
      }
    case "SHOW_SUCCESS_PAYMENT":
      return {
        ...state,
        successPaymentModal: true,
      };
    case "HIDE_SUCCESS_PAYMENT":
      return {
        ...state,
        successPaymentModal: false,
      };
    case "SHOW_CANCEL_PAYMENT":
      return {
        ...state,
        cancelPaymentModal: true,
      };
    case "HIDE_CANCEL_PAYMENT":
      return {
        ...state,
        cancelPaymentModal: false,
      };
    case "SHOW_SUBSCRIPTION_WARNING":
      return {
        ...state,
        showSubscrptionWarningModal: true,
      };
    case "HIDE_SUBSCRIPTION_WARNING":
      return {
        ...state,
        showSubscrptionWarningModal: false,
      };
    case "SHOW_TALK_TO_SALES":
      return {
        ...state,
        talkToSalesModal: true,
      };
    case "HIDE_TALK_TO_SALES":
      return {
        ...state,
        talkToSalesModal: false,
      };
    case "SHOW_SERVICE_NOW_CONNECTION":
      return {
        ...state,
        serviceNowConnectionModal: true,
      };
    case "HIDE_SERVICE_NOW_CONNECTION":
      return {
        ...state,
        serviceNowConnectionModal: false,
      };
    case "SHOW_SERVICE_NOW_STATUS":
      return {
        ...state,
        serviceNowStatusModal: true,
      };
    case "HIDE_SERVICE_NOW_STATUS":
      return {
        ...state,
        serviceNowStatusModal: false,
      };
    case "SHOW_PRIORITY_MAPPING_MODAL":
      return {
        ...state,
        priorityMappingModal: true,
      };
    case "HIDE_PRIORITY_MAPPING_MODAL":
      return {
        ...state,
        priorityMappingModal: false,
      };
    case "SHOW_DATALEAK_TO_SECURITY_RISKS_MODAL":
      return {
        ...state,
        dataleakTosecurityModal: true,
      };
    case "HIDE_DATALEAK_TO_SECURITY_RISKS_MODAL":
      return {
        ...state,
        dataleakTosecurityModal: false,
      };
    case "SHOW_SKIP_INVENTORY_MODAL":
      return {
        ...state,
        skipInventoryModal: true,
      };
    case "HIDE_SKIP_INVENTORY_MODAL":
      return {
        ...state,
        skipInventoryModal: false,
      };
    default:
      return state;
  }
}
