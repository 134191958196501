export const FETCH_ASSET_SCAN_DETAILS_SUCCESS =
  'FETCH_ASSET_SCAN_DETAILS_SUCCESS';

export const FETCH_SCAN_DETAILS_REQUEST = 'FETCH_SCAN_DETAILS_REQUEST';
export const FETCH_SCAN_DATA_GROUPED_BY_ASSET_REQUEST =
  'FETCH_SCAN_DATA_GROUPED_BY_ASSET_REQUEST';
export const FETCH_SCAN_DATA_GROUPED_BY_ASSET_SUCCESS =
  'FETCH_SCAN_DATA_GROUPED_BY_ASSET_SUCCESS';
export const FETCH_SCAN_DATA_GROUPED_BY_VULNERABILITY_REQUEST =
  'FETCH_SCAN_DATA_GROUPED_BY_VULNERABILITY_REQUEST';
export const FETCH_SCAN_DATA_GROUPED_BY_VULNERABILITY_SUCCESS =
  'FETCH_SCAN_DATA_GROUPED_BY_VULNERABILITY_SUCCESS';
export const GROUPED_BY_CARDS_ISSUES = 'GROUPED_BY_CARDS_ISSUES';
export const GROUPED_BY_CARDS_ASSETS = 'GROUPED_BY_CARDS_ASSETS';
