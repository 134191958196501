const initialState = {
  data: [],
  count: 0,
  nextItems: null,
  prevItems: null,
  loading: false,
  activeOrgs: [],
  inactiveOrgs: [],
  freeOrgs: [],
  orgs: [],
  supportOrgs: [],
  categoryCount: [],
  searchedOrg: [],
  reqDomain: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'GET_ORGS_REQUEST':
      return {
        ...state,
        data: action.orgs,
        count: action.count,
        nextItems: action.nextItems,
        prevItems: action.prevItems
      };
    case 'GET_ORGS_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.orgs,
        count: action.count,
        nextItems: action.nextItems,
        prevItems: action.prevItems
      };
    case 'FETCH_ACTIVE_ORGS_REQUEST':
    case 'FETCH_SUPPORT_ORGS_REQUEST':
    case 'FETCH_INACTIVE_ORGS_REQUEST':
    case 'FETCH_REQ_DOMAIN_REQUEST':
    case 'FETCH_ORGS_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'FETCH_FREE_ORGS_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'FETCH_SEARCHED_ORGS_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'FETCH_ACTIVE_ORGS_SUCCESS':
      return {
        ...state,
        activeOrgs: action.orgs,
        loading: false
      };
    case 'FETCH_ORGS_SUCCESS':
      return {
        ...state,
        loading: false,
        orgs: action.orgs,
        count: action.count,
        nextItems: action.nextItems,
        prevItems: action.prevItems
      };
    case 'FETCH_REQ_DOMAIN_SUCCESS':
      return {
        ...state,
        loading: false,
        reqDomain: action.data
      };
    case 'FETCH_INACTIVE__ORGS_SUCCESS':
      return {
        ...state,
        inactiveOrgs: action.orgs,
        loading: false
      };
    case 'FETCH_FREE_ORGS_SUCCESS':
      return {
        ...state,
        loading: false,
        freeOrgs: action.orgs
      };
    case 'FETCH_SEARCHED_ORG_SUCCESS':
      return {
        ...state,
        loading: false,
        searchedOrg: action.data
      };
    case 'FETCH_SUPPORT_ORGS_SUCCESS':
      return {
        ...state,
        loading: false,
        supportOrgs: action.orgs
      };
    case 'FETCH_CATEGORY_COUNT_SUCCESS':
      return {
        ...state,
        categoryCount: action.data
      };
    default:
      return state;
  }
}
