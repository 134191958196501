import { API, APIv2 } from "utils/api";
import { errorHandler, stripeErrorHandler } from "utils/error_handler";
import {
  hideAddTargetsSettingsModal,
  showSuccessModal,
  hidePaymentAuthenticationModal,
  showPaymentAuthenticationModal,
  showPaymentMethodModal,
  hidePaymentMethodModal,
  hideEditAwsKeysModal,
  showAwsErrorDataModal,
  hideAwsErrorDataModal,
  hideAddDomains
} from "./modals";
import { setAwsArnErrorData, setAwsErrorData } from "actions/error_data";
import { fetchLatestNotifications } from "actions/scan_details";
import { fetchDashboardStatus } from "./dashboard";
import { fetchUniqueAttackSurfaceAssets } from "actions/asset_table";
import updateUrlAndFetchData from "utils/update_data_url";
import { checkSeverity } from "utils/severity_check";

export const saveTargets = (targets, history) => async (dispatch) => {
  try {
    dispatch({
      type: "SAVE_TARGETS_REQUEST",
    });

    const requestBody = {
      targets,
    };
    const response = await API.post("/orgs/targets/", requestBody);
    if (response.status === 201) {
      dispatch(hideAddTargetsSettingsModal());
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: "New target is added to the list successfully.",
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
      dispatch(fetchLatestNotifications(history));
      dispatch(fetchDashboardStatus(history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const updateTargets = (targets, history, deleteTarget) => async (
  dispatch
) => {
  try {
    dispatch({
      type: "SAVE_TARGETS_REQUEST",
    });

    const requestBody = {
      targets,
    };
    const response = await API.put("/orgs/targets/", requestBody);
    if (response.status === 200) {
      dispatch(hideAddTargetsSettingsModal());
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: `Target is ${deleteTarget ? "deleted" : "edited"
            } successfully.`,
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
      dispatch(fetchLatestNotifications(history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const saveAWSkeys = (awsKeys, history, page) => async (dispatch) => {
  try {
    dispatch({
      type: "ADD_AWS_KEYS_REQUEST",
    });

    const requestBody = {
      aws_creds: awsKeys,
      other_targets: [],
    };
    const response = await API.post("/orgs/aws-keys/", requestBody);
    if (response.status === 200 || response.status === 201) {
      dispatch({ type: "ADD_AWS_KEYS_SUCCESS" });
      dispatch(hideAddTargetsSettingsModal());
      if (!page && page !== "modal") {
        dispatch({
          type: "SUCCESS_MESSAGE_MODAL",
          message: {
            title: "Success!",
            message: "New AWS keys are added to the list successfully.",
            redirectTo: "",
            buttonTitle: "Ok",
          },
        });
        dispatch(showSuccessModal());
      }
      dispatch(fetchTargets(history));
    }
  } catch (error) {
    await dispatch(
      setAwsErrorData(error.response.data.msg, error.response.data.invalid_keys)
    );
    await dispatch(showAwsErrorDataModal());
  }
};

export const deleteAwsKeys = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_AWS_KEYS_REQUEST",
    });

    const requestBody = {
      data: {
        id,
      },
    };
    const response = await API.delete("/orgs/aws-keys/", requestBody);
    if (response.status === 200 || response.status === 201) {
      dispatch({ type: "DELETE_AWS_KEYS_SUCCESS" });
      dispatch(fetchTargets(history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const saveIAMkeys = (iamKeys, history, page) => async (dispatch) => {
  try {
    dispatch({
      type: "SAVE_IAM_KEYS_REQUEST",
    });

    const requestBody = {
      aws_arn_key: iamKeys,
    };

    const response = await API.post("/orgs/aws-config/", requestBody);
    if (response.status === 201 || response.status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: "New IAM keys are added to the list successfully.",
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
    dispatch(fetchTargets());
  } catch (error) {
    await dispatch(
      setAwsArnErrorData(
        error.response.data.msg,
        error.response.data.invalid_list
      )
    );
    await dispatch(showAwsErrorDataModal());
    //dispatch(hideEditAwsKeysModal());
  }
};

export const deleteIAMkeys = (iamKeys, history) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_IAM_KEYS_REQUEST",
    });

    const response = await API.delete("/orgs/aws-config/", {
      data: {
        aws_arn_key: iamKeys,
        id: iamKeys.id
      },
    });
    if (response.status === 201 || response.status === 200) {
      dispatch({
        type: "DELETE_IAM_KEYS_SUCCESS",
      });
    }
    dispatch(fetchTargets());
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchTargets = (history, filterUrl) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_TARGETS_REQUEST",
    });

    const url = filterUrl ? `?target=${filterUrl}` : ``;
    const { data } = await API.get(`/orgs/targets/${url}`);
    const gcpId = data.gcp.length ? data.gcp[0].id : null
    dispatch({
      type: "FETCH_TARGET_SUCCESS",
      integrationData: data,
      cloudKeys: data.cloudflare,
      gcpId: gcpId,
      gcp: data.gcp.length ? JSON.parse(data.gcp[0].name).gcp ? JSON.parse(data.gcp[0].name).gcp : JSON.parse(data.gcp[0].name) : {},
      first_scan_triggered: data.first_scan_triggered || false,
      aws_arn_key: data.aws_arn_key,
      unvalidDomains: data.unvalidated_domains || [],
      validDomains: data.valid_domains || [],
      discoveredDomains: data.discovered_domains || [],
      notify_default: data.notify_default,
      nextItems: data.next,
      previousItems: data.previous,
      count: data.count,
      otherAssets: [...data.ip_addresses.map(el => {
        return {
          ...el,
          asset_type: 'IP_ADDRESS'
        }
      }), ...data.git_orgs.map(el => {
        return {
          ...el,
          asset_type: 'GIT_ORGS'
        }
      }), ...data.git_repos.map(el => {
        return {
          ...el,
          asset_type: 'GIT_REPOS'
        }
      }), ...data.cidr.map(el => {
        return {
          ...el,
          asset_type: 'CIDR'
        }
      })],
      ips: data.ip_addresses || [],
      org_user_emails: data.org_user_emails
        ? data.org_user_emails.map((email) => {
          return { value: email, label: email };
        })
        : [],
      email_notification_mails: data.email_notification_mails || [],
      gitRepos: data.git_repos || [],
      awsKeys: data.aws_keys || [],
      // otherTypes: res.data["other-types"],
    });
    // if (res.status === 200) {
    // }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchTargetsNext = (history, nextUrl) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_TARGETS_REQUEST",
    });

    const { data } = await API.get(nextUrl);
    const gcpId = data.gcp.length ? data.gcp[0].id : null
    dispatch({
      type: "FETCH_TARGET_SUCCESS",
      integrationData: data,
      cloudKeys: data.cloudflare,
      gcp: data.gcp.length ? JSON.parse(data.gcp[0].name).gcp ? JSON.parse(data.gcp[0].name).gcp : JSON.parse(data.gcp[0].name) : {},
      gcpId: gcpId,
      first_scan_triggered: data.first_scan_triggered || false,
      aws_arn_key: data.aws_arn_key,
      unvalidDomains: data.unvalidated_domains || [],
      validDomains: data.valid_domains || [],
      discoveredDomains: data.discovered_domains || [],
      notify_default: data.notify_default,
      nextItems: data.next,
      previousItems: data.previous,
      count: data.count,
      otherAssets: [...data.ip_addresses.map(el => {
        return {
          ...el,
          asset_type: 'IP_ADDRESS'
        }
      }), ...data.git_orgs.map(el => {
        return {
          ...el,
          asset_type: 'GIT_ORGS'
        }
      }), ...data.git_repos.map(el => {
        return {
          ...el,
          asset_type: 'GIT_REPOS'
        }
      }), ...data.cidr.map(el => {
        return {
          ...el,
          asset_type: 'CIDR'
        }
      })],
      ips: data.ip_addresses || [],
      org_user_emails: data.org_user_emails
        ? data.org_user_emails.map((email) => {
          return { value: email, label: email };
        })
        : [],
      email_notification_mails: data.email_notification_mails || [],
      gitRepos: data.git_repos || [],
      awsKeys: data.aws_keys || [],
      // otherTypes: res.data["other-types"],
    });
    // if (res.status === 200) {
    // }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchTargetsType = (history) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_TARGETS_TYPE_REQUEST",
    });

    const res = await API.get("/orgs/targets/target-types/");
    if (res.status === 200) {
      dispatch({
        type: "FETCH_TARGET_TYPE_SUCCESS",
        otherTypes: Object.entries(res.data.target_types).map(([label, value]) => ({ label, value: `${value}` }))
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const deleteTarget = (history, new_list, list) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_TARGET_REQUEST",
    });
    const requestBody = { domain_list: list, domain_list_v2: new_list };
    const res = await API.delete("/orgs/targets/", { data: requestBody });
    if (res.status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: "Domains deleted successfully.",
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const deleteOtherAssets = (list, history) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_OTHER_ASSETS_REQUEST",
    });
    const res = await API.delete("/orgs/delete_assets/", { data: list });
    if (res.status === 200) {
      dispatch({
        type: "DELETE_OTHER_ASSETS_SUCCESS",
      });

      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: "Assets deleted successfully.",
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchExistingRdp = (history, orgName, orgId) => async (
  dispatch
) => {
  try {
    dispatch({
      type: "FETCH_EXISTINGRDP_REQUEST",
    });
    dispatch({
      type: "SET_DOMAINRDP_SUCCESS",
      data: {},
    });

    const res = await API.get(`/orgs/rdp/?domain=${orgName}`);
    if (res.status === 200) {
      dispatch({
        type: "FETCH_EXISTINGRDP_SUCCESS",
        rdpValue: res.data.txt_record,
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const resetRdpRecord = () => async (dispatch) => {
  dispatch({
    type: "FETCH_EXISTINGRDP_SUCCESS",
    rdpValue: {},
  });
  dispatch({
    type: "SET_DOMAINRDP_SUCCESS",
    data: {},
  });
};

export const setDomainRdp = (history, domain) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_DOMAINRDP_REQUEST",
    });
    dispatch({
      type: "FETCH_EXISTINGRDP_SUCCESS",
      rdpValue: {},
    });
    const requestBody = {
      domain: domain.name,
      target_id: domain.id
    };
    const response = await API.post(`/orgs/rdp/`, requestBody);
    if (response.status === 200) {
      dispatch({
        type: "SET_DOMAINRDP_SUCCESS",
        data: response.data,
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const validateByDnsEntry = (history, orgId, domain) => async (
  dispatch
) => {
  try {
    dispatch({
      type: "VALIDATE_BYDNS_REQUEST",
    });

    // const requestBody = {
    //   domains: domain,
    // };
    const response = await API.post(`/orgs/rdp_validation/`, domain);
    if (response.status === 200) {
      dispatch({
        type: "VALIDATE_BYDNS_SUCCESS",
        data: response.data,
      });
      dispatch(fetchTargets(history));
      dispatch(fetchLatestNotifications(history));
      dispatch(fetchDashboardStatus(history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const validateBycustomerSupport = (history, orgId, domain) => async (
  dispatch
) => {
  try {
    dispatch({
      type: "VALIDATE_BYCUST_REQUEST",
    });

    const requestBody = {
      domains: domain,
    };
    const response = await API.post(
      `/orgs/${orgId}/request-domains/`,
      requestBody
    );
    if (response.status === 200) {
      dispatch({
        type: "VALIDATE_BYCUST_SUCCESS",
        data: response.data,
      });
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: `Validation request has been raised successfully`,
          buttonTitle: "Ok",
          showCancelButton: false,
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const createUnvalidatedDomains = (history, domains) => async (
  dispatch
) => {
  try {
    dispatch({
      type: "CREATE_UNVALIDATED_DOMAINS_REQUEST",
    });

    const requestBody = {
      domains,
    };
    const response = await API.post(`/orgs/unvalidated-domains/`, requestBody);
    if (response.status === 201) {
      dispatch({
        type: "CREATE_UNVALIDATED_DOMAINS_SUCCESS",
        data: response.data,
      });
      if (history.location.pathname === "/attack-surface") {
        const baseUrl = "/attack-surface";
        await updateUrlAndFetchData(
          baseUrl,
          dispatch,
          fetchUniqueAttackSurfaceAssets,
          history
        );
      }
      dispatch(fetchTargets(history));
    }
  } catch (error) {

    errorHandler(error, dispatch, history);
  }
};
export const createNewDomains = (history, domains) => async (
  dispatch
) => {
  try {
    dispatch({
      type: "CREATE_UNVALIDATED_DOMAINS_REQUEST",
    });
    const params = domains.map((el) => {
      return {

        target_name: el,
        target_type: "DOMAIN"

      }
    })
    const requestBody = {
      domains: params,
    };
    const response = await API.post(`/orgs/asset-group/target/`, requestBody);
    if (response.status === 201) {
      dispatch({
        type: "CREATE_UNVALIDATED_DOMAINS_SUCCESS",
        data: response.data,
      });
      if (history.location.pathname === "/attack-surface") {
        const baseUrl = "/attack-surface";
        await updateUrlAndFetchData(
          baseUrl,
          dispatch,
          fetchUniqueAttackSurfaceAssets,
          history
        );
      }
      dispatch(fetchTargets(history));
    }
  } catch (error) {
    dispatch({
      type: "CREATE_UNVALIDATED_DOMAINS_SUCCESS",
      data: [],
    });
    // dispatch(hideAddDomains(history));
    errorHandler(error, dispatch, history);
  }
};

export const setOtherAssets = (otherAsset, history, page) => async (
  dispatch
) => {
  try {
    dispatch({
      type: "SET_OTHER_ASSETS",
    });

    const data = { other_targets: otherAsset, aws_creds: [] };

    const response = await API.post(`/orgs/other-targets/`, data);
    if (response.status === 201 || response.status === 200) {
      dispatch({
        type: "SET_OTHER_ASSETS_SUCCESS",
        data: response.data.message,
      });
      if (!page && page !== "setup") {
        dispatch({
          type: "SUCCESS_MESSAGE_MODAL",
          message: {
            title: "Success!",
            message: `Asset added successfully`,
            redirectTo: "",
            buttonTitle: "Ok",
          },
        });
        dispatch(showSuccessModal());
      }
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchSubscriptionPlans = (history) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_SUBSCRIPTION_PLANS_REQUEST",
    });

    const { data } = await API.get("/stripe-prices/");
    dispatch({
      type: "FETCH_SUBSCRIPTION_PLANS_SUCCESS",
      subscriptionPlans: data,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const createSubscription = (
  requestData,
  stripe,
  planName,
  history
) => async (dispatch) => {
  try {
    dispatch({ type: "CREATE_SUBSCRIPTION_REQUEST" });

    const response = await APIv2.post(
      "/orgs/create-subscription/",
      requestData
    );
    if (response.status === 201) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: `You are successfully subscribed to our ${planName} plan`,
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    stripeErrorHandler(error, stripe, requestData, dispatch, planName, history);
  }
};

export const updatePaymentMethod = (requestData, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: "UPDATE_PAYMENT_METHOD_REQUEST" });

    const response = await APIv2.post(
      "/orgs/update-payment-method/",
      requestData
    );
    if (response.status === 201) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message:
            "You have successfully updated the card for future payments.",
          redirectTo: "/settings/billing",
          buttonTitle: "Ok",
        },
      });
      dispatch(hidePaymentAuthenticationModal());
      dispatch(hidePaymentMethodModal());
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const cancelSubscription = (history) => async (dispatch) => {
  try {
    dispatch({ type: "CANCEL_SUBSCRIPTION_REQUEST" });

    const response = await APIv2.delete("/orgs/cancel-subscription/", {
      data: {},
    });
    if (response.status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: "You have successfully cancelled the subscription plan.",
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchCurrentSubscriptionInfo = (history) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_CURRENT_SUBSCRIPTION_INFO_REQUEST" });

    const { data } = await APIv2.get("/orgs/billing/");
    dispatch({
      type: "FETCH_CURRENT_SUBSCRIPTION_INFO_SUCCESS",
      data,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const setHeader = (headerInfo) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_HEADER",
      headerInfo,
    });
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const setChangePlanTrue = () => (dispatch) => {
  dispatch({
    type: "CHANGE_SUBSCRIPTION_PLAN_TRUE",
  });
};

export const setChangePlanFalse = () => (dispatch) => {
  dispatch({
    type: "CHANGE_SUBSCRIPTION_PLAN_FALSE",
  });
};

export const updateSubscription = (
  requestData,
  stripe,
  planName,
  history
) => async (dispatch) => {
  try {
    dispatch({ type: "CREATE_SUBSCRIPTION_REQUEST" });

    const response = await APIv2.put("/orgs/update-subscription/", requestData);
    if (response.status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: `You are successfully subscribed to our ${planName}`,
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    stripeErrorHandler(error, stripe, requestData, dispatch, planName, history);
  }
};

export const getPaymentClientsPaymentInfo = (
  requestData,
  issue,
  history
) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_CLIENT_PAYMENT_INFO_REQUEST",
    });

    const { data } = await API.get(
      `/stripe-payment-failure/?requires_action=${requestData.token}&org_id=${requestData.id}`
    );

    dispatch({
      type: "FETCH_CLIENT_PAYMENT_INFO_SUCCESS",
      data,
    });
    issue === "REQUIRE_AUTH"
      ? dispatch(showPaymentAuthenticationModal())
      : dispatch(showPaymentMethodModal());
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const updateWebHook = (url, history, value) => async (dispatch) => {
  try {
    const response = await API.post(`/orgs/custom-web-hook/`, { custom_webhook_url: url });
    dispatch({
      type: "SUCCESS_MESSAGE_MODAL",
      message: {
        title: "Success!",
        message: `Webhook url ${value}.`,
        redirectTo: "",
        buttonTitle: "Ok",
      },
    });
    dispatch(showSuccessModal());
    dispatch(fetchTargets(history));
  } catch (error) {
    if (error.response.status !== 400) {
      errorHandler(error, dispatch, history);
    }
  }
};
export const testWebHook = (url, history) => async (dispatch) => {
  try {
    const response = await API.post(`/orgs/test/custom-web-hook/`, { webhook_url: url });
    dispatch({
      type: "SUCCESS_MESSAGE_MODAL",
      message: {
        title: response.data.success ? "Success!" : "Error!",
        message: response.data.success === false ? response.data.msg : 'Webhook URL Validated',
        redirectTo: "",
        buttonTitle: "Ok",
      },
    });
    dispatch(showSuccessModal());
  } catch (error) {
    if (error.response.status !== 400) {
      errorHandler(error, dispatch, history);
    }
  }
};
export const deleteWebHook = (url, history) => async (dispatch) => {
  try {
    const response = await API.delete(`/orgs/custom-web-hook/`, { data: { custom_webhook_url: url } });
    dispatch({
      type: "SUCCESS_MESSAGE_MODAL",
      message: {
        title: "Success!",
        message: `Webhook url Deleted.`,
        redirectTo: "",
        buttonTitle: "Ok",
      },
    });
    dispatch(showSuccessModal());
    dispatch(fetchTargets(history));
  } catch (error) {
    if (error.response.status !== 400) {
      errorHandler(error, dispatch, history);
    }
  }
};
export const getApiKeys = (history) => async (dispatch) => {
  try {
    const { data } = await API.get(`/apikey/`);

    dispatch({
      type: "FETCH_API_KYES_REQUEST",
      data: data.api_key ? data.api_key : "",
    });
  } catch (error) {
    if (error.response.status !== 400) {
      errorHandler(error, dispatch, history);
    }
  }
};

export const postApiKeys = (history) => async (dispatch) => {
  try {
    const response = await API.post(`/apikey/`, {});
    if (response.status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: `API key Generated.`,
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
      dispatch(getApiKeys(history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const updateApiKeys = (history) => async (dispatch) => {
  try {
    const { data } = await API.put(`/apikey/`, {});
    dispatch({
      type: "SUCCESS_MESSAGE_MODAL",
      message: {
        title: "Success!",
        message: `API key is Updated.`,
        redirectTo: "",
        buttonTitle: "Ok",
      },
    });
    dispatch(showSuccessModal());
    dispatch({
      type: "FETCH_API_KYES_REQUEST",
      data: data.api_key,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const deleteApiKeys = (history) => async (dispatch) => {
  try {
    const response = await API.delete(`/apikey/`);
    if (response.status === 200) {
      dispatch({
        type: "FETCH_API_KYES_REQUEST",
        data: "",
      });
    }
    if (response.status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: `API key is Deleted.`,
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const updateNotify = (history, params, position) => async (dispatch) => {
  try {
    dispatch({ type: "REMOVED_UNSAVED_CHANGED" });

    const response = await API.put(`/orgs/org-setting/`, params);
    if (response.status) {
      if (position && position === "previous") {
        dispatch(fetchTargets(history));
      } else {
        dispatch({
          type: "SUCCESS_MESSAGE_MODAL",
          message: {
            title: "Success!",
            message: `Preferences Updated.`,
            redirectTo: "",
            showIcon: true,
            buttonTitle: "Ok",
          },
        });
        dispatch(showSuccessModal());
        dispatch(fetchTargets(history));
      }
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const getNotify = (history,) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_NOTIFY_LIST_REQUEST" });
    const { data } = await API.get(`/orgs/notification-preference/`);
    dispatch({
      type: "FETCH_NOTIFY_LIST_SUCCESS",
      data: data,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const getUpdatedNotify = (history,) => async (dispatch) => {
  try {
    const { data } = await API.get(`/orgs/notification-preference/`);
    dispatch({
      type: "FETCH_NOTIFY_LIST_SUCCESS",
      data: data,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const getAllNotifyType = (history,) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_NOTIFY_LIST_REQUEST" });
    const { data } = await API.get(`/orgs/notification-constants/`);
    const list = data.types.map(el => {

      let obj = {
        ...el,
        channel: data.channels.map(i => {
          let ch = {
            ...i,
            is_enabled: false,
          }
          return ch;
        }),
        severityList: el === 'new_issue_count' ?

          severity : [0, 1, 2, 3, 4].map(sr => {
            let sev = {
              severity: checkSeverity(sr),
              channel: data.channels.map(i => {
                let ch = {
                  ...i,
                  is_enabled: false,
                  severity: sr,
                }
                return ch;
              }),
            }
            return sev
          })

      }
      return obj
    })
    dispatch({
      type: "FETCH_NOTIFY_TYPE_SUCCESS",
      data: list,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const updateNotifyPer = (params, history) => async (dispatch) => {
  try {
    const response = await API.put(`/orgs/notification-preference/`, params);
    dispatch({ type: "REMOVED_UNSAVED_CHANGED" });
    dispatch({
      type: "SUCCESS_MESSAGE_MODAL",
      message: {
        title: "Success!",
        message: `Preferences Updated.`,
        redirectTo: "",
        showIcon: true,
        buttonTitle: "Ok",
      },
    });
    dispatch(showSuccessModal());
    // dispatch(getUpdatedNotify());
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const addNotify = (params, history) => async (dispatch, getState) => {
  try {
    const { data } = await API.post(`/orgs/notification-preference/`, params);
    dispatch({ type: "REMOVED_UNSAVED_CHANGED" });
    dispatch({
      type: "SUCCESS_MESSAGE_MODAL",
      message: {
        title: "Success!",
        message: `Preferences Updated.`,
        redirectTo: "",
        showIcon: true,
        buttonTitle: "Ok",
      },
    });
    dispatch(showSuccessModal());
    // dispatch(getUpdatedNotify());
    const noti_data = getState().clientSettings.notifyList;


  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const updateSeverity = (params, history, position) => async (
  dispatch
) => {
  try {
    dispatch({ type: "REMOVED_UNSAVED_CHANGED" });

    const response = await API.put(`/orgs/tracker-threshold/`, params);
    if (response.status) {
      if (position && position === "previous") {
        dispatch(fetchTargets(history));
      } else {
        dispatch({
          type: "SUCCESS_MESSAGE_MODAL",
          message: {
            title: "Success!",
            message: `Preferences Updated.`,
            redirectTo: "",
            showIcon: true,
            buttonTitle: "Ok",
          },
        });
        dispatch(showSuccessModal());
        dispatch(fetchTargets(history));
      }
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const getIssueBlacklist = (filterUrl, history) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_BLACKLIST_ISSUE_REQUEST",
    });

    const { data } = await API.get(`/orgs/issues/muted/${filterUrl}`);

    dispatch({
      type: "FETCH_ISSUE_BLACKLIST_SUCCESS",
      data: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      count: data.count,
    });
  } catch (error) {
    if (error.response.status !== 400) {
      errorHandler(error, dispatch, history);
    }
  }
};
export const getIssueTitleBlacklist = (filterUrl, history) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_BLACKLIST_ISSUE_REQUEST",
    });

    const { data } = await API.get(`/orgs/issues/muted/title/${filterUrl}`);

    dispatch({
      type: "FETCH_ISSUE_BLACKLIST_SUCCESS",
      data: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      count: data.count,
    });
  } catch (error) {
    if (error.response.status !== 400) {
      errorHandler(error, dispatch, history);
    }
  }
};
export const getAssetExposureBlacklist = (filterUrl, history) => async (
  dispatch
) => {
  try {
    dispatch({
      type: "FETCH_BLACKLIST_DATALEAK_REQUEST",
    });

    const { data } = await API.get(`/orgs/asset-exposure/muted/${filterUrl}`);

    dispatch({
      type: "FETCH_ASSET_EXPOSURE_BLACKLIST_SUCCESS",
      data: data.results || [],
      nextItems: data.next,
      previousItems: data.previous,
      count: data.count,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
    // if (error.response.status !== 400) {
    // }
  }
};
export const getAssetInventoryBlacklist = (filterUrl, history) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_BLACKLIST_DATALEAK_REQUEST",
    });

    const { data } = await API.get(`/orgs/asset-inventory/muted/${filterUrl}`);

    dispatch({
      type: "FETCH_ASSET_INVENTORY_BLACKLIST_SUCCESS",
      data: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      count: data.count,
    });
  } catch (error) {
    if (error.response.status !== 400) {
      errorHandler(error, dispatch, history);
    }
  }
};

export const getDataleaksBlacklist = (filterUrl, history) => async (
  dispatch
) => {
  try {
    dispatch({
      type: "FETCH_BLACKLIST_DATALEAK_REQUEST",
    });

    const { data } = await API.get(`/orgs/dataleaks/muted/${filterUrl}`);

    dispatch({
      type: "FETCH_DATALEAKS_BLACKLIST_SUCCESS",
      data: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      count: data.count,
    });
  } catch (error) {
    if (error.response.status !== 400) {
      errorHandler(error, dispatch, history);
    }
  }
};
export const nextIssueBlacklist = (history) => async (dispatch, getState) => {
  try {
    const nextUrl = getState().clientSettings.nextItems;

    const { data } = await API.get(nextUrl);
    const muteData = getState().clientSettings.issueBlackList;
    const mutedDataUpdated = muteData.concat(data.results);
    dispatch({
      type: "FETCH_ISSUE_BLACKLIST_SUCCESS",
      data: mutedDataUpdated,
      nextItems: data.next,
      previousItems: data.previous,
      count: data.count,
      currentUrl: nextUrl ? `?${nextUrl.split("?")[1]}` : "",
    });
  } catch (error) {
    if (error.response.status !== 400) {
      errorHandler(error, dispatch, history);
    }
  }
};
export const nextAssetExposureBlacklist = (history) => async (
  dispatch,
  getState
) => {
  try {
    const nextUrl = getState().clientSettings.nextItems;

    const { data } = await API.get(nextUrl);
    const muteData = getState().clientSettings.assetExposureBlackList;
    const mutedDataUpdated = muteData.concat(data.results);

    dispatch({
      type: "FETCH_ASSET_EXPOSURE_BLACKLIST_SUCCESS",
      data: mutedDataUpdated,
      nextItems: data.next,
      previousItems: data.previous,
      count: data.count,
      currentUrl: nextUrl ? `?${nextUrl.split("?")[1]}` : "",
    });
  } catch (error) {
    if (error.response.status !== 400) {
      errorHandler(error, dispatch, history);
    }
  }
};
export const nextAssetInventoryBlacklist = (history) => async (
  dispatch,
  getState
) => {
  try {
    const nextUrl = getState().clientSettings.nextItems;

    const { data } = await API.get(nextUrl);
    const muteData = getState().clientSettings.assetInventoryBlackList;
    const mutedDataUpdated = muteData.concat(data.results);

    dispatch({
      type: "FETCH_ASSET_INVENTORY_BLACKLIST_SUCCESS",
      data: mutedDataUpdated,
      nextItems: data.next,
      previousItems: data.previous,
      count: data.count,
      currentUrl: nextUrl ? `?${nextUrl.split("?")[1]}` : "",
    });
  } catch (error) {
    if (error.response.status !== 400) {
      errorHandler(error, dispatch, history);
    }
  }
};

export const nextDataleaksBlacklist = (history) => async (
  dispatch,
  getState
) => {
  try {
    const nextUrl = getState().clientSettings.nextItems;

    const { data } = await API.get(nextUrl);
    const muteData = getState().clientSettings.dataleakBlackList;
    const mutedDataUpdated = muteData.concat(data.results);

    dispatch({
      type: "FETCH_DATALEAKS_BLACKLIST_SUCCESS",
      data: mutedDataUpdated,
      nextItems: data.next,
      previousItems: data.previous,
      count: data.count,
      currentUrl: nextUrl ? `?${nextUrl.split("?")[1]}` : "",
    });
  } catch (error) {
    if (error.response.status !== 400) {
      errorHandler(error, dispatch, history);
    }
  }
};

export const deleteBlacklistIssue = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_BLACKLIST_ISSUE_REQUEST",
    });
    const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
      ? JSON.parse(localStorage.getItem('AssetGroupParams'))
      : [{ name: "Default Group", value: '' }]
    const filterUrl = assetGroupParams[0].value ? `?asset_group=${assetGroupParams[0].value}` : ''
    const response = await API.put(`/orgs/issues/${id}/blacklist/`, {});
    if (response.status) {
      dispatch(getIssueBlacklist(filterUrl, history));
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: `Security Risks Unmuted Successfully`,
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const deleteBlacklistTitleIssue = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_BLACKLIST_ISSUE_REQUEST",
    });

    const response = await API.put(`/orgs/issues/${id}/blacklist/title/`, {
      mute: false
    });
    if (response.status) {
      const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
        ? JSON.parse(localStorage.getItem('AssetGroupParams'))
        : [{ name: "Default Group", value: '' }]
      const filterUrl = assetGroupParams[0].value ? `?asset_group=${assetGroupParams[0].value}` : ''
      dispatch(getIssueTitleBlacklist(filterUrl, history));
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: `Security Risks Title Unmuted Successfully`,
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const deleteBlacklistDataleak = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: "FETCH_BLACKLIST_DATALEAK_REQUEST",
    });

    const response = await API.put(`/orgs/dataleaks/${id}/blacklist/`, {});
    if (response.status) {
      const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
        ? JSON.parse(localStorage.getItem('AssetGroupParams'))
        : [{ name: "Default Group", value: '' }]
      const filterUrl = assetGroupParams[0].value ? `?asset_group=${assetGroupParams[0].value}` : ''
      dispatch(getDataleaksBlacklist(filterUrl, history));
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: `Dataleak unmuted successfully`,
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const deleteBlacklistAssetExposure = (id, history) => async (
  dispatch
) => {
  try {
    dispatch({
      type: "FETCH_BLACKLIST_DATALEAK_REQUEST",
    });

    const response = await API.put(`/orgs/asset-exposure/${id}/blacklist/`, {});
    if (response.status) {
      const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
        ? JSON.parse(localStorage.getItem('AssetGroupParams'))
        : [{ name: "Default Group", value: '' }]
      const filterUrl = assetGroupParams[0].value ? `?asset_group=${assetGroupParams[0].value}` : ''
      dispatch(getAssetExposureBlacklist(filterUrl, history));
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: `Asset unmuted successfully`,
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const deleteBlacklistAssetInventory = (id, history) => async (
  dispatch
) => {
  try {
    dispatch({
      type: "FETCH_BLACKLIST_DATALEAK_REQUEST",
    });

    const response = await API.put(
      `/orgs/asset-inventory/${id}/blacklist/`,
      {}
    );
    const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
      ? JSON.parse(localStorage.getItem('AssetGroupParams'))
      : [{ name: "Default Group", value: '' }]
    const filterUrl = assetGroupParams[0].value ? `?asset_group=${assetGroupParams[0].value}` : ''
    if (response.status) {
      dispatch(getAssetInventoryBlacklist(filterUrl, history));
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: `Asset unmuted successfully`,
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const startScan = (history) => async (dispatch) => {
  try {
    const response = await API.post(`/scan/create/`, {});
    if (response.status === 200) {
      dispatch({
        type: "SUCCESS_MESSAGE_MODAL",
        message: {
          title: "Success!",
          message: `Scan started successfully.`,
          redirectTo: "",
          buttonTitle: "Ok",
        },
      });
      dispatch(showSuccessModal());
      dispatch(fetchTargets(history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
