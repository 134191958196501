import {
  FILTER_SCAN,
  FETCH_SCANS_REQUEST,
  FETCH_SCANS_SUCCESS,
  TRIGGER_NEW_SCAN_REQUEST,
  TRIGGER_NEW_SCAN_SUCCESS,
} from "action_types/scan_table";
import { CATCH_ERROR } from "action_types/error";

const initialState = {
  data: [],
  loading: false,
  dlLoading: false,
  scanTableLoader: false,
  scansCount: null,
  Count: null,
  nextItems: null,
  previousItems: null,
  scanToDelete: null,
  scanToReRun: {},
  orgPendingScans: null,
  unvalidatedDomains: [],
  validateDomain: {},
  scanOverview: {},
  scanOverviewClient: {},
  fastScan: {},
  slowScan: {},
  scanOrg: "",
  scanOrgSubStatus: "",
  aeAggregate: {
    asset_list: []
  },
  srAggregate: {},
  dlAggregate: {},
  dlDetails: {},
  aeDetails: {},
  selectedIds: [],
  selectedId: '',
  fastScanReportData: {
    report_pdf: "",
    asset_exposure_csv: "",
    asset_inventory_csv: "",
    issue_inventory_csv: "",
    dataleak_inventory_csv: "",
  },
  slowScanReportData: {
    report_pdf: "",
    asset_exposure_csv: "",
    asset_inventory_csv: "",
    issue_inventory_csv: "",
    dataleak_inventory_csv: "",
  },
  lightScanReportData: {
    report_pdf: "",
    asset_exposure_csv: "",
    asset_inventory_csv: "",
    issue_inventory_csv: "",
    dataleak_inventory_csv: "",
  },
  dlList: [],
  scanSummaryList: [],
  scanSummaryJobIdsList: [],
  skipInventory: [],
  vulnerability: [],
  dataleaks: [],
  dlListAutomated: [],
  dlListManual: [],
  dlListInactive: [],
  aeList: [],
  aeListAutomated: [],
  aeListManual: [],
  aeListInactive: [],
  cloudHuntdata: {},

  paymentPlans: [],
  paymentUrl: "",
  modifyPaymentUrl: "",
  subscriptionStatus: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FILTER_SCAN:
      return {
        ...state,
        loading: false,
        data: [...action.newFilteredData],
      };

    case FETCH_SCANS_SUCCESS:
      return {
        ...state,
        data: [...action.payload],
        loading: false,
      };

    case TRIGGER_NEW_SCAN_REQUEST:
    case FETCH_SCANS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case "PAYMENT_PLANS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case TRIGGER_NEW_SCAN_SUCCESS:
    case CATCH_ERROR:
      return {
        ...state,
        loading: false,
      };
    case "SCANS_PAGE_LINKS":
      return {
        ...state,
        scansCount: action.scansCount,
        nextItems: action.nextItems,
        previousItems: action.previousItems,
      };
    case "RESET_REDUCER_DATA":
      const scanOverviewClient = state.scanOverviewClient;
      return {
        ...state,
        ...initialState,
        scanOverviewClient:scanOverviewClient
      };

    case "SET_SCAN_TO_DELETE":
      return {
        ...state,
        scanToDelete: action.scan,
        orgPendingScans: null,
      };
    case "SET_ORG_TO_DELETE_PENDING_SCANS":
      return {
        ...state,
        orgPendingScans: action.orgId,
        scanToDelete: null,
      };
    case "SET_SCAN_TO_RE_RUN":
      return {
        ...state,
        scanToReRun: action.scan,
      };

    case "FETCH_AWS_VALIDATION_REQUEST":
      return {
        ...state,
        awsValidation: true,
      };
    case "FETCH_SCANS_OVERVIEW_SUCCESS":
      return {
        ...state,
        scanOverview: {
          fast_scan: action.payload.fast_scan,
          light_scan: action.payload.light_scan,
          slow_scan: action.payload.slow_scan,
          org_name: action.payload.org_name,
          is_killed_scan: action.payload.is_killed_scan,
          killed_by: action.payload.killed_by,
          resumable: action.payload.resumable,
          killed_ts: action.payload.killed_ts,
        },
        fastScanReportData: action.payload.export_fast_scan,
        slowScanReportData: action.payload.export_slow_scan,
        lightScanReportData: action.payload.export_light_scan,
      };
    case "FETCH_SCANS_OVERVIEW_CLIENT_SUCCESS":
      return {
        ...state,
        scanOverviewClient: {
          fast_scan: action.payload.fast_scan,
          light_scan: action.payload.light_scan,
          slow_scan: action.payload.slow_scan,
          org_name: action.payload.org_name,
          is_killed_scan: action.payload.is_killed_scan,
          killed_by: action.payload.killed_by,
          resumable: action.payload.resumable,
          killed_ts: action.payload.killed_ts,
        },
      };
    case "FETCH_AWS_VALIDATION_SUCCESS":
    case "FETCH_AWS_VALIDATION_RESET":
      return {
        ...state,
        awsValidation: false,
      };
    case "FETCH_UNVALIDATED_DOMAINS_SUCCESS":
      return {
        ...state,
        unvalidatedDomains: action.payload,
      };
    case "VALIDATE_DOMAIN_SUCCESS":
      return {
        ...state,
        validateDomain: action.payload,
      };
    case "HANDLE_CLOUD_INTEGRATION_SUCCESS":
      return {
        ...state,
        cloudHuntdata: action.data,
      };
    case "FETCH_AE_AGGREGATE_SUCCESS":
      return {
        ...state,
        scanTableLoader: false,
        aeAggregate: action.payload,
      };
    case "FETCH_RH_ORGS_DATALEAKS_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        dlLoading: false,
        dlDetails: action.dlDetails,
        dlList: action.dlList,
        dlListAutomated: action.dlListAutomated,
        dlListManual: action.dlListManual,
        dlListInactive: action.dlListInactive,
      };
    case "FETCH_RH_ORGS_DATALEAKS_REQUEST":
    case "FETCH_RH_ORGS_SKIP_INVENTORY_REQUEST":
    case "FETCH_RH_ORGS_ASSET_EXPOSURE_REQUEST":
    case "FETCH_SCAN_SUMMARY_REQUEST":
      return {
        ...state,
        dlLoading: true,
        loading: true,
        aeList: [],
        aeListAutomated: [],
        scanSummaryList:[],
        aeListManual: [],
        aeListInactive: [],
      };
    case "FETCH_SR_AGGREGATE_REQUEST":
    case "FETCH_DL_AGGREGATE_REQUEST":
    case "FETCH_AE_AGGREGATE_REQUEST":
      return {
        ...state,
        scanTableLoader: true,
      };
    case "FETCH_RH_ORGS_ASSET_EXPOSURE_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        dlLoading: false,
        // aeDetails: action.aeDetails,
        aeList: action.aeList,
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        Count: action.Count,
        // aeListAutomated: action.aeListAutomated,
        // aeListManual: action.aeListManual,
        // aeListInactive: action.aeListInactive,
      };
    case "FETCH_RH_ORGS_SKIP_INVENTORY_SUCCESS":
      return {
        ...state,
        loading: false,
        dlLoading: false,
        skipInventory: action.data,
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        Count: action.Count,
      };
    case "FETCH_SCAN_SUMMARY_SUCCESS":
      return {
        ...state,
        loading: false,
        dlLoading: false,
        scanSummaryList: action.data,
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        Count: action.Count,
      };
    case "FETCH_SCAN_SUMMARY_JOBIDS_SUCCESS":
      return {
        ...state,
        loading: false,
        dlLoading: false,
        scanSummaryJobIdsList: action.data,
      };
    case "FETCH_RH_ORGS_VULNERABILITY_SUCCESS":
      return {
        ...state,
        vulnerability: action.data,
      };
    case "FETCH_RH_ORGS_DATALEAKS_SUCCESS":
      return {
        ...state,
        dataleaks: action.data,
      };
    case "FETCH_DL_AGGREGATE_SUCCESS":
      return {
        ...state,
        scanTableLoader: false,
        dlAggregate: action.payload,
      };
    case "FETCH_SR_AGGREGATE_SUCCESS":
      return {
        ...state,
        scanTableLoader: false,
        srAggregate: action.payload,
      };
    case "FETCH_SELECTED_IDS_SUCCESS":
      return {
        ...state,
        selectedIds: action.payload,
      };
    case "FETCH_SELECTED_ID_SUCCESS":
      return {
        ...state,
        selectedId: action.payload,
      };
    case "FETCH_SELECTED_IDS_RESET":
      return {
        ...state,
        selectedIds: [],
      };
    case "FETCH_AE_DETAILS_SUCCESS":
      return {
        ...state,
        aeDetails: action.aeDetails,
      };

    case "PAYMENT_PLANS_SUCCESS":
      return {
        ...state,
        loading: false,
        paymentPlans: action.data,
      };

    case "GET_PAYMENT_URL_SUCCESS":
      return {
        ...state,
        paymentUrl: action.data,
      };

    case "GET_MODIFY_PAYMENT_URL_SUCCESS":
      return {
        ...state,
        modifyPaymentUrl: action.data,
      };
    case "GET_SUBSCRIPTION_STATUS_SUCCESS":
      return {
        ...state,
        subscriptionStatus: action.data,
      };

    default:
      return state;
  }
}
