import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
// import AccountCircle from '@material-ui/icons/Person';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import LogOutIcon from '@material-ui/icons/ExitToAppRounded';
// import WalletIcon from '@material-ui/icons/AccountBalanceWalletRounded';
import { Divider } from '@material-ui/core';
import {
  getUser,
  handleLightThemeChanges,
  handleDarkThemeChanges
} from 'actions/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserProfileIcon from '@material-ui/icons/PersonRounded';
// import clsx from 'clsx';
import NotificationPopper from './../notification_popper';
import logo from 'assets/nvadrLogoOnboarding.png';
import darkLogo from 'assets/logo.png';
// import TourIcon from '@material-ui/icons/Flag';
import { restartTour } from 'actions/tour';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import LogoutButton from 'components/auth_logout';
import radar from 'assets/Radar.gif';
import newIcon from 'assets/whats-new.png';
import { WarningOutlined, HighlightOff } from '@material-ui/icons';
import moment from 'moment-timezone';
import { CustomTooltip } from 'components/tooltip';
import { showHeaderWaring, hideHeaderWarning } from 'actions/modals';
import WhatIsNew from 'components/attack_surface/what_is_new';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
  root: {
    display: 'flex'
  },
  appBar: {
    backgroundColor: 'var(--color-blueBG)',
    position: 'sticky',
    top: 0,
    // borderBottom: '1px solid #DBDBDB'
    // left: 0
    // boxShadow: 'var(--image-box-shadow)'
  },
  toolbar: {
    padding: '0rem 1.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  menu: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '1rem 2rem',
    marginTop: 0,
    marginBottom: 0,
    color: 'var(--color-white)',
    background: 'var(--color-background)'
    // boxShadow: 'var(--dark-mode-box-shadow)'
  },
  menuIcon: {
    paddingRight: '1rem',
    color: 'var(--color-light-text)'
  },
  profile: {
    display: 'flex',
    padding: '1rem',
    paddingLeft: '1.5rem',
    margin: 0,
    color: 'var(--color-text)',
    alignItems: 'flex-start',
    fontWeight: 500
  },
  accountIcon: {
    marginLeft: '0.5rem'
  },
  activeIcon: {
    color: 'var(--color-light-text)',
    backgroundColor: 'var(--color-bodyBG)',
    padding: '5px',
    borderRadius: '50px',
    fontSize: 20
  },

  headerStatus: {
    cursor:'pointer',
    // border: '2px solid var(--color-running)',
    padding: '3px 12px',
    borderRadius: 67,
    border: '1px solid var(--color-white)',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    marginLeft: 'auto'
    // marginRight: '30px'
  },
  listItemIcon: {
    color: 'var(--color-white)'
  },
  listLinks: {
    padding: '10px 10px',
    display: 'flex',
    alignItems: 'center',
    color: 'var(--color-white)',
    marginBottom: 0,
    fontWeight: '700 !important',
    opacity: 1,
    position: 'relative',
    transition: 'all 0.4s ease-in-out',
    '&:hover': {
      backgroundColor: 'var(--color-transparentDark)',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      marginTop: '1rem'
    },
    '&.active': {
      color: 'var(--color-white)',
      backgroundColor: 'var(--color-primary)',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      boxShadow: 'var(--image-box-shadow)',
      marginTop: '1rem',
      '&:hover': {
        color: 'var(--color-white) !important'
      }
    }
  },
  listItem: {
    padding: 0,
    marginRight: '10px',
    width: 'auto',
    display: 'flex'
  },
  status: {
    fontSize: '1em',
    color: 'var(--color-white)'
  },
  warning: {
    backgroundColor: 'var(--color-warning100)',
    // borderBottom: '1px solid var(--color-warning500)',
    lineHeight: '3',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center'
  },
  warningText: {
    color: 'var(--color-text)',
    fontWeight: '500',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: 16,
    '&:hover': {
      textDecoration: 'underline',
      color: 'var(--color-primary)'
    }
  },
  tag: {
    padding: '5px 10px',
    background: '#424446',
    color: 'var(--color-white)',
    textTransform: 'uppercase',
    borderRadius: 4,
    cursor: 'pointer',
    fontSize: 14
  }
,
label:{
  margin: '10px 0',
}
});
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    fontSize:14,
    border: '1px solid #dadde9',
    boxShadow: 'var(--dark-mode-box-shadow)'
  },
}))(Tooltip);

class Header extends PureComponent {
  state = {
    anchorEl: null,
    open: false,
    isAccountReady:
      this.props.role !== 'PENDING' &&
      this.props.role !== 'ORG_PENDING' &&
      this.props.role !== 'VERIFIED',
    darkTheme: JSON.parse(localStorage.getItem('darkTheme')) || false,
    monitoringStatus: '',
    profilePic: localStorage.getItem('profile_pic'),
    isUnvalidated: false,
    isKilledScan: false,
    killed_ts: null,
    killed_by: null,
    dashboardScanStatus: localStorage.getItem('dashboardStatus') ? JSON.parse(localStorage.getItem('dashboardStatus')) : {},
    subscriptionStatus: localStorage.getItem('subscriptionStatus') ? JSON.parse(localStorage.getItem('subscriptionStatus')) : { status: "", product: "", interval: "" }
  };

  componentDidMount() {
    if (this.state.isAccountReady) {
      const parsed = localStorage.getItem('dashboardStatus') ? JSON.parse(localStorage.getItem('dashboardStatus')) : {}
      if (Object.keys(parsed).length !== 0) {
        this.setState({
          dashboardScanStatus: parsed,
          monitoringStatus: parsed.status,
          isUnvalidated: parsed.is_unvalidated,
          isKilledScan: parsed.is_killed_scan,
          killed_ts: parsed.killed_ts,
          killed_by: parsed.killed_by
        });
      } else {
        const monitoringStatus = this.props.status;
        this.setState({
          monitoringStatus,
          isUnvalidated: this.props.unvalidated,
          isKilledScan: this.props.is_killed_scan
        });
      }
      if (parsed && (parsed.is_unvalidated || parsed.is_killed_scan)) {
        this.props.showHeaderWaring();
      } else {
        this.props.hideHeaderWarning();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dashboardStatus !== this.props.dashboardStatus) {
      const parsed = localStorage.getItem('dashboardStatus') ? JSON.parse(localStorage.getItem('dashboardStatus')) : {}
      this.setState({
        monitoringStatus: parsed.status,
        isUnvalidated: parsed.is_unvalidated,
        isKilledScan: parsed.is_killed_scan,
        killed_ts: parsed.killed_ts,
        killed_by: parsed.killed_by
      });
      if (parsed.is_unvalidated || parsed.is_killed_scan) {
        this.props.showHeaderWaring();
      } else {
        this.props.hideHeaderWarning();
      }
    }
    if (prevProps.subscriptionStatus !== this.props.subscriptionStatus) {
      this.setState({
        subscriptionStatus: localStorage.getItem('subscriptionStatus') ? JSON.parse(localStorage.getItem('subscriptionStatus')) : { status: "", product: "", interval: "" }
      });
    } else {
      this.setState({
        subscriptionStatus: this.props.subscriptionStatus
      });
    }
  }

  handleMenu = (event) =>
    this.setState({ anchorEl: event.currentTarget, open: true });

  handleClose = () => this.setState({ anchorEl: null, open: false });

  goToProfile = () => {
    this.setState({ anchorEl: null, open: false });
    localStorage.setItem('settingsPage', '/settings/profile');
    this.props.history.push('/settings/profile');
  };

  handleLogout = () => {
    localStorage.setItem('wasSessionExpired', false);
    this.props.logout(this.props.history, true);
  };

  handleTheme = () => {
    localStorage.setItem('darkTheme', !this.state.darkTheme);
    this.state.darkTheme
      ? this.props.handleLightThemeChanges()
      : this.props.handleDarkThemeChanges();

    this.setState({
      darkTheme: !this.state.darkTheme
    });
  };

  handleCloseWarning = (e) => {
    this.props.hideHeaderWarning();
    sessionStorage.setItem('warningHiddenManually', true);
    e.preventDefault();
  };

  startTour = () => {
    this.props.restartTour();
  };

  getWarningToDisplay = () => {
    if (
      (this.state.isKilledScan && this.state.isUnvalidated) ||
      (this.state.isKilledScan && !this.state.isUnvalidated)
    ) {
      return 'killScan';
    } else if (!this.state.isKilledScan && this.state.isUnvalidated) {
      return 'unvalidated';
    }
    return null;
  };

  render() {
    const { classes, warningVisible, scanOverview } = this.props;
    let warningToDisplay = this.getWarningToDisplay();
    return (
      <div
        className={JSON.stringify(classes.root)}
        style={{
          position: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          top: 0,
          width: '100%',
          zIndex: 1000
        }}>
        {warningToDisplay &&
          warningToDisplay === 'killScan' &&
          warningVisible ? (
          <>
            <div className={classes.warning}>
              <NavLink
                style={{
                  width: 'fit-content',
                  height: 'fit-content',
                  display: 'flex',
                  flexDirection: 'row'
                }}
                to="/settings/billing?stop-all-scans">
                <CustomTooltip title="Open Kill Scans Page.">
                  <p className={classes.warningText}>
                    <WarningOutlined
                      style={{
                        marginTop: 13,
                        width: 18,
                        height: 18,
                        marginRight: 10,
                        color: 'var(--color-warning500)'
                      }}
                    />
                    We are not scanning your assets since{' '}
                    {`${moment
                      .utc(this.state.killed_ts)
                      .format('lll')} UTC`}{' '}
                    on request of {this.state.killed_by}. To
                    resume your scans again go to Settings/Kill Scan page.
                  </p>
                </CustomTooltip>
                <CustomTooltip title="Close">
                  <HighlightOff
                    onClick={(e) => this.handleCloseWarning(e)}
                    style={{
                      width: '18px',
                      marginLeft: 8,
                      color: 'var(--color-primary)',
                      cursor: 'pointer'
                    }}
                  />
                </CustomTooltip>
              </NavLink>
            </div>
          </>
        ) : warningToDisplay &&
          warningToDisplay === 'unvalidated' &&
          warningVisible ? (
          <>
            <div className={classes.warning}>
              <NavLink
                style={{ width: 'fit-content', height: 'fit-content' }}
                to="/settings">
                <CustomTooltip title="Open Settings">
                  <p className={classes.warningText}>
                    <WarningOutlined
                      style={{
                        marginTop: 13,
                        width: 18,
                        height: 18,
                        marginRight: 10,
                        color: 'var(--color-warning500)'
                      }}
                    />
                    Action Needed : You have unverified domains. ASM scans are
                    performed only on verified domains.
                  </p>
                </CustomTooltip>
              </NavLink>
              <CustomTooltip title="Close">
                <HighlightOff
                  onClick={(e) => this.handleCloseWarning(e)}
                  style={{
                    width: '18px',
                    marginLeft: 8,
                    color: 'var(--color-blueBG)',
                    cursor: 'pointer'
                  }}
                />
              </CustomTooltip>
            </div>
          </>
        ) : null}
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomTooltip title="Dashboard">
                <Link to="/">
                  <div className="logo" style={{ paddingLeft: 0 }}>
                    <img
                      src={this.state.darkTheme ? darkLogo : darkLogo}
                      width="150"
                      height="30"
                      alt="NVADR Logo"
                    />
                  </div>
                </Link>
              </CustomTooltip>
              {this.state.subscriptionStatus && this.state.subscriptionStatus.product && this.state.subscriptionStatus.status !== 'inactive' && this.state.subscriptionStatus.status !== 'expired' ?
                <span className={classes.tag} onClick={() => this.props.history.push('/settings/billing')}>{this.state.subscriptionStatus.status === 'trialing' ? '30 DAYS TRIAL' : this.state.subscriptionStatus.product}</span>
                : this.state.subscriptionStatus && (this.state.subscriptionStatus.status === 'inactive' || this.state.subscriptionStatus.status === 'expired') ? <span className={classes.tag} onClick={() => this.props.history.push('/settings/billing')}>Upgrade</span> : null
              }
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
              <div className={'header_status'} style={{ marginRight: 30 }}>
                {this.state.monitoringStatus &&
                  this.state.monitoringStatus !== 'NA' ? (
                    <HtmlTooltip
        title={
          <React.Fragment>
            <h2 color="inherit" style={{borderBottom: '1px solid #ddd', paddingBottom:12}}>Scan Details</h2>
            {scanOverview.slow_scan ? 
            <div>
              <p className={classes.label}><span style={{color:'#878787'}}>Status</span> : <span style={{
                    color:
                      scanOverview.slow_scan.status === 'RUNNING'
                        ? '#FF5403'
                        : 'var(--color-admin)',
                    fontSize: 16,
                    fontWeight: 500
                  }}>
                  {scanOverview.slow_scan.status}
                </span> </p>
              <p className={classes.label}><span style={{color:'#878787'}}>Last Scan Date</span> : {moment
                          .utc(scanOverview.slow_scan.triggered_ts)
                          .format('ll')}</p>
              <p className={classes.label}><span style={{color:'#878787'}}>Next Scheduled Date</span> : {moment
                          .utc(scanOverview.slow_scan.next_scan_ts)
                          .format('ll')}</p>
            </div>
            : null
            }
          </React.Fragment>
        }
      >
        {/* <Button>HTML</Button> */}
                  <div className={classes.headerStatus}>
                    {this.state.monitoringStatus === 'SCANNING' ? (
                      <img
                        src={radar}
                        alt="radar"
                        style={{ width: 24, marginRight: 10, filter: 'invert(1)' }}
                      />
                    ) : this.state.monitoringStatus === 'MONITORING' ? (
                      <FontAwesomeIcon
                        icon={faClock}
                        spin
                        color="var(--color-white)"
                        style={{ marginRight: '0.5rem' }}
                      />
                    ) : null}
                    <span className={classes.status} style={{ fontSize: 14 }}>
                      {this.state.monitoringStatus &&
                        this.state.monitoringStatus !== 'NA'
                        ? this.state.monitoringStatus
                        : 'No Scan Running'}
                    </span>
                  </div>
      </HtmlTooltip>
                ) : null}
              </div>
              {this.state.isAccountReady ? (
                <>
                  <span>
                    <WhatIsNew />
                  </span>
                  <span>
                    <NotificationPopper />
                  </span>

                  <div className={classes.accountIcon}>
                    <LogoutButton header={true} />

                    <Menu
                      id="menu-appbar"
                      anchorEl={this.state.anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      open={this.state.open}
                      onClose={this.handleClose}>
                      {this.props.role !== 'MANAGER' ? (
                        <MenuItem
                          onClick={this.goToProfile}
                          className={classes.menu}>
                          <UserProfileIcon
                            className={classes.menuIcon}
                            style={{ fontSize: 20 }}
                          />
                          Profile
                        </MenuItem>
                      ) : null}
                      <Divider />
                      {/* <Link to="/transactions">
                      <MenuItem
                        onClick={this.handleClose}
                        className={classes.menu}>
                        <WalletIcon
                          className={classes.menuIcon}
                          style={{ fontSize: 20 }}
                        />
                        Transactions
                      </MenuItem>
                    </Link>
                    <Divider /> */}
                      <MenuItem className={classes.menu}>
                        {/* <LogOutIcon
                        className={classes.menuIcon}
                        style={{ fontSize: 20 }}
                      /> */}
                        <LogoutButton header={true} />
                      </MenuItem>
                    </Menu>
                  </div>
                </>
              ) : (
                <div
                  className={classes.accountIcon}
                  style={{ zIndex: 2002, position: 'fixed', right: '32px' }}>
                  {/* <CustomTooltip title="Logout"> */}
                  <IconButton onClick={this.handleLogout} size="small">
                    <LogOutIcon
                      className={classes.menuIcon}
                      style={{
                        color: 'var(--color-white)',
                        backgroundColor: 'var(--color-primary)',
                        padding: '7px',
                        borderRadius: '50px',
                        fontSize: 20
                      }}
                    />
                  </IconButton>
                  {/* </CustomTooltip> */}
                </div>
              )}
            </div>
          </Toolbar>
        </AppBar>
        {/* {this.state.isUnvalidated ? (
          <>
            <br />
            <br />
          </>
        ) : null} */}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.authentication.user,
  role: state.authentication.role,
  headerInfo: state.clientSettings.headerInfo,
  status: state.dashboard.status,
  unvalidated: state.dashboard.unvalidated,
  setupFinished: state.dashboard.setupFinished,
  dashboardStatus: localStorage.getItem('dashboardStatus'),
  killScan: state.dashboard.is_killed_scan,
  scanOverview: state.scanTable.scanOverviewClient,
  subscriptionStatus: state.scanTable.subscriptionStatus,
  warningVisible: state.modals.warningVisible
});

export default withRouter(
  connect(mapStateToProps, {
    getUser,
    restartTour,
    handleLightThemeChanges,
    handleDarkThemeChanges,
    showHeaderWaring,
    hideHeaderWarning
  })(withStyles(styles)(Header))
);
