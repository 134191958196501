import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { makeStyles } from '@material-ui/core/styles';
import LogOutIcon from '@material-ui/icons/ExitToAppRounded';
import { useLocation } from 'react-router';
import { CustomTooltip } from 'components/tooltip';
import { logoutUser, authLogout } from "actions/auth";
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  typography: {
    width: '320px',
    backgroundColor: 'var(--color-bodyBG)',
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.14)'
  },
  container: {
    // border: '2px solid var(--color-running)',
    padding: '5px 12px',
    borderRadius: 67,
    border: '1px solid #BABABA',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 500,
    marginLeft: 'auto',
    marginRight: '20px',
    cursor: 'pointer'
  },
  viewAll: {
    padding: '1rem',
    color: 'var(--color-primary)',
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: 700
  }
}));

const LogoutButton = ({ header }) => {
  // const darkTheme = JSON.parse(localStorage.getItem('darkTheme')) || false;
  const role = localStorage.getItem('role');
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  // const { logout } = useAuth0();

  const userLogout = async (url) => {
    await dispatch(authLogout())
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("tour", 1);
    // logout(url);
  };

  return location.pathname === '/user/email-verification' ||
    location.pathname === '/user/org-verification' ||
    location.pathname === '/user/admin-confirmation' ||
    location.pathname === '/user/login-form' ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: 'var(--color-primary)',
        cursor: 'pointer'
      }}
      onClick={() =>
        userLogout({ returnTo: `${window.location.origin}/login` })
      }>
      <p>Logout</p>
      <LogOutIcon
        style={{
          paddingLeft: 7,
          borderRadius: '50px',
          fontSize: 26
        }}
      />
    </div>
  ) : (
    <CustomTooltip title="Logout">
      <span
        className={classes.container}
        onClick={() =>
          userLogout({ returnTo: `${window.location.origin}/login` })
        }>
        {header ? (
          <LogOutIcon
            style={{
              color: role ? role.startsWith("rh") ? 'var(--color-primary)' : 'var(--color-white)' : 'var(--color-white)',
              padding: '0px 10px',
              fontSize: 20
            }}
          />
        ) : (
          'Logout'
        )}
      </span>
    </CustomTooltip>
  );
};

export default LogoutButton;
