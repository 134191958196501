import { FETCH_ASSET_SCAN_DETAILS_SUCCESS } from 'action_types/scan_details';

const initialState = {
  data: [],
  issuesCount: null,
  previousItems: null,
  nextItems: null,
  is_jira_integrated: false,
  loading: false,
  srLoading: false,
  dropdownLoader: true,
  issueTypesWeAccept: [],
  assetWeAccept: [],
  category: [],
  issueTrackerStatus: [],
  issueTrackerUser: [],
  dataleakSignaturesWeAccept: [],
  issueTracker: {},
  dataLeaks: [],
  dataLeaksCard: {
    dataleak_count: 0,
    last_change_days_delta: '',
    missing_dataleak_count: 0,
    missing_dataleak_count_percent_change: 0,
    new_dataleak_count: 0,
    new_dataleak_count_percent_change: 0,
    active_dataleak_count: 0,
    active_dataleak_count_percent_change: 0
  },
  issuesCard: {
    issue_count: 0,
    last_change_days_delta: '',
    missing_issue_count: 0,
    missing_issue_count_percent_change: 0,
    new_issue_count: 0,
    new_issue_count_percent_change: 0,
    active_issue_count: 0,
    active_issue_count_percent_change: 0
  },
  vulnerabilitiesCard: {},
  dataLeaksCount: null,
  issueTrackerCount: 0,
  dataLeaksSignature: [],
  uniqueTypesLoading: false,
  dataLeaksTypes: [],
  loadingUnique: false,
  issueTypesUnique: [],
  issueTitle: [],
  issueAssets: [],
  assetsTypes: [],
  issuePorts: [],
  signatureList: [],
  targetList: [],
  severityTypesUnique: [],
  uniqueConditions: [],
  conditionInfo: {
    affected_hosts: [],
    condition_detail: {}
  },
  loadingConditionInfo: false,
  currentUrl: '',
  last_run_at: '',
  srList: [],
  srListAutomated: [],
  exportToJira: false,
  srListManual: [],
  srListInactive: [],
  srDetails: {},
  issueTrackerDetails: {},
  issueTypesRaw: [],
  assetTypeList: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ASSET_SCAN_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.issueList],
        issuesCount: action.issuesCount,
        dataLeaks: [...action.dataLeaks],
        dataLeaksCount: action.dataLeaksCount
      };
    case 'FETCH_SCAN_DETAILS_ISSUES_REQUEST':
    case 'FETCH_SCAN_DETAILS_DATA_LEAKS_REQUEST':
    case 'FETCH_RH_DATA_LEAKS_REQUEST':
    case 'FETCH_RH_ASSET_EXPOSURE_REQUEST':
    case 'FETCH_ORGS_DATA_LEAK_DATA_REQUEST':
    case 'FETCH_ORGS_SECURITY_RISKS_DATA_REQUEST':
    case 'FETCH_RH_ORGS_SECURITY_RISKS_TABLE_DATA_REQUEST':
    case 'FETCH_ISSUE_CONDITIONS_REQUEST':
      return {
        ...state,
        loading: true,
        loadingUnique: true
      };
    case 'FETCH_ISSUE_TRACKER_REQUEST':
      return {
        ...state,
        loading: true,
        loadingUnique: true
      };
    case 'FETCH_DROPDOWN_REQUEST':
      return {
        ...state,
        dropdownLoader: true,
      };
    case 'EXPORT_TO_JIRA_REQUEST':
      return {
        ...state,
        loading: true,
        exportToJira: true
      };
    case 'EXPORT_TO_JIRA_SUCCESS':
      return {
        ...state,
        loading: false,
        exportToJira: false
      };
    case 'EXPORT_TO_JIRA_FAILED':
      return {
        ...state,
        loading: false,
        exportToJira: false,
        loadingUnique: false
      };
    case 'REQUEST_FAILED':
      return {
        ...state,
        loading: false,
        data: [],
        dataLeaks: [],
        exportToJira: false,
        loadingUnique: false,
        nextItems: null,
        previousItems: null,
        issuesCount: 0,
        dataLeaksCount: 0,
        currentUrl: null
      };
    case 'ADD_ISSUE_TRACKER_REQUEST_SUCCESS':
      return {
        ...state,
        loading: false,
        loadingUnique: false
      };
    case 'UPDATE_ISSUE_TRACKER_REQUEST_SUCCESS':
      return {
        ...state,
        loading: false,
        loadingUnique: false,
        issueTrackerDetails: action.data
      };
    case 'FETCH_SCAN_DETAILS_ISSUES_SUCCESS':
    case 'FETCH_ORGS_SECURITY_RISKS_DATA_SUCCESS':
      return {
        ...state,
        loading: false,
        loadingUnique: false,
        data: [...action.issueList],
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        issuesCount: action.issuesCount,
        currentUrl: action.currentUrl
      };
    // case 'FETCH_RH_ASSET_EXPOSURE_SUCCESS':
    //   return {
    //     ...state,
    //     loading: false,
    //     loadingUnique: false,
    //     aeList: [...action.aeList],
    //     nextItems: action.nextItems,
    //     previousItems: action.previousItems,
    //     assetExposureCount: action.assetExposureCount,
    //     currentUrl: action.currentUrl
    //   };
    case 'FETCH_SCAN_DETAILS_DATA_LEAKS_SUCCESS':
    case 'FETCH_ORGS_DATA_LEAK_DATA_SUCCESS':
      return {
        ...state,
        loading: false,
        loadingUnique: false,
        dataLeaks: [...action.dataLeaks],
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        dataLeaksCount: action.dataLeaksCount,
        currentUrl: action.currentUrl
      };
    // case 'FETCH_RH_DATA_LEAKS_SUCCESS':
    //   return {
    //     ...state,
    //     loading: false,
    //     loadingUnique: false,
    //     dlList: [...action.dlList],
    //     nextItems: action.nextItems,
    //     previousItems: action.previousItems,
    //     dataLeaksCount: action.dataLeaksCount,
    //     currentUrl: action.currentUrl
    //   };
    case 'FETCH_VULNERABILITIES_CONDITIONS_DATA_SUCCESS':
      return {
        ...state,
        loading: false,
        // vulnerabilitiesCard: action.vulnerabilitiesCard,
        uniqueConditions: action.vulnerabilitiesCard.unique_conditions,
        uniqueConditionCount:
          action.vulnerabilitiesCard.unique_conditions.length
      };
    case 'FETCH_ISSUE_TRACKER_DATA_SUCCESS':
      return {
        ...state,
        loading: false,
        loadingUnique: false,
        issueTracker: action.issueTracker,
        // nextItems: action.nextItems,
        // previousItems: action.previousItems,
        issueTrackerCount: action.issueTrackerCount,
        is_jira_integrated: action.is_jira_integrated,
        last_run_at: action.last_run_at
        // currentUrl: action.currentUrl
      };
    case 'FETCH_RH_ORGS_SECURITY_RISKS_TABLE_DATA_SUCCESS':
      return {
        ...state,
        loading: false,
        loadingUnique: false,
        srList: [...action.srList],
        issuesCount: action.issuesCount,
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        issueTrackerCount: action.issueTrackerCount,
        currentUrl: action.currentUrl
      };
    case 'RESET_REDUCER_DATA':
      return {
        ...state,
        ...initialState
      };
    case 'FETCH_ISSUE_UNIQUE_TYPES':
      return {
        ...state,
        issueTypes: [...action.data]
      };
    case 'FETCH_RH_ORGS_SECURITY_RISKS_DETAILS_SUCCESS':
      return {
        ...state,
        loading: false,
        loadingUnique: false,
        srDetails: action.srDetails,
        srList: action.srList,
        srListAutomated: action.srListAutomated,
        srListManual: action.srListManual,
        srListInactive: action.srListInactive,
        srLoading: false
      };
    case 'FETCH_RH_ORGS_SECURITY_RISKS_DETAILS_REQUEST':
      return {
        ...state,
        srLoading: true,
        loading: true,
        loadingUnique: true
      };
    case 'FETCH_ISSUE_TITLE_LIST':
      return {
        ...state,
        issueTitle: [...action.data]
      };
    case 'FETCH_ISSUE_ASSETS_LIST':
      return {
        ...state,
        issueAssets: [...action.data]
      };
    case 'FETCH_ASSETS_TYPE_LIST':
      return {
        ...state,
        assetsTypes: [...action.data]
      };
    case 'FETCH_ISSUE_PORTS_LIST':
      return {
        ...state,
        issuePorts: [...action.data]
      };
    case 'FETCH_SIGNATURE_LIST':
      return {
        ...state,
        signatureList: [...action.data]
      };
    case 'FETCH_ASSET_TYPE_LIST':
      return {
        ...state,
        assetTypeList: [...action.data]
      };
    case 'FETCH_DATALEAK_TARGET_LIST':
      return {
        ...state,
        targetList: [...action.data]
      };
    case 'FETCH_ALL_ISSUE_UNIQUE_TYPES':
      return {
        ...state,
        issueTypesWeAccept: [...action.data],
        assetWeAccept: [...action.assets],
        category: [...action.category],
        dropdownLoader: false
      };
    case 'FETCH_ISSUE_STATUS_REQUEST':
      return {
        ...state,
        issueTrackerStatus: [...action.data]
      };
    case 'FETCH_ISSUE_TRACKER_USER_REQUEST':
      return {
        ...state,
        issueTrackerUser: [...action.data]
      };
    case 'FETCH_DATA_LEAKS_SIGNATURE_TYPES':
      return {
        ...state,
        dataLeaksSignature: [...action.data]
      };
    case 'FETCH_ALL_UNIQUE_DATALEAK_SIGNATURES':
      return {
        ...state,
        dataleakSignaturesWeAccept: [...action.data],
        dropdownLoader: false,
      };

    case 'FETCH_DATA_LEAKS_TYPE_REQUEST':
    case 'FETCH_SECURITY_RISK_TYPES_REQUEST':
      return {
        ...state,
        uniqueTypesLoading: true
      };
    case 'FETCH_DATA_LEAKS_TYPE_SUCCESS':
      return {
        ...state,
        uniqueTypesLoading: false,
        dataLeaksTypes: action.dataLeaks,
        dataLeaksCard: {
          dataleak_count: action.dataLeaksCard.dataleak_count,
          last_change_days_delta: action.dataLeaksCard.last_change_days_delta,
          missing_dataleak_count: action.dataLeaksCard.missing_dataleak_count,
          missing_dataleak_count_percent_change:
            action.dataLeaksCard.missing_dataleak_count_percent_change,
          new_dataleak_count: action.dataLeaksCard.new_dataleak_count,
          new_dataleak_count_percent_change:
            action.dataLeaksCard.new_dataleak_count_percent_change,
          active_dataleak_count:
            action.dataLeaksCard.active_dataleak_count || 0,
          active_dataleak_count_percent_change:
            action.dataLeaksCard.active_dataleak_count_percent_change || 0
        }
      };
    case 'FETCH_SECURITY_RISK_TYPES_SUCCESS':
      return {
        ...state,
        uniqueTypesLoading: false,
        issueTypesRaw: action.issueTypesRaw,
        issueTypesUnique: action.issueTypes,
        severityTypesUnique: action.severityTypes,
        issuesCard: {
          issue_count: action.issuesCard.issue_count,
          last_change_days_delta: action.issuesCard.last_change_days_delta,
          last_change_slow_days_delta:
            action.issuesCard.last_change_slow_days_delta,
          missing_issue_count: action.issuesCard.missing_issue_count,
          missing_issue_count_percent_change:
            action.issuesCard.missing_issue_count_percent_change,
          new_issue_count: action.issuesCard.new_issue_count,
          new_issue_count_percent_change:
            action.issuesCard.new_issue_count_percent_change,
          active_issue_count: action.issuesCard.active_issue_count || 0,
          active_issue_count_percent_change:
            action.issuesCard.active_issue_count_percent_change || 0
        }
      };
    case 'FETCH_ISSUE_CONDITIONS':
      return {
        ...state,
        loading: false,
        uniqueConditions: action.data,
        uniqueConditionCount: action.count
      };
    case 'FETCH_ISSUE_CONDITIONS_INFO':
      return {
        ...state,
        conditionInfo: action.data,
        vulnerabilitiesCard: action.data.overview,
        loadingConditionInfo: false
      };
    case 'FETCH_CONDITION_INFO_REQUEST':
      return {
        ...state,
        loadingConditionInfo: true
      };
    case 'FETCH_JIRA_SYNC_TRACKER_SUCCESS':
      return {
        ...state,
        last_run_at: action.data
      };
    case 'FETCH_CSV_SUCCESS':
      return {
        ...state,
        loading: false,
        loadingUnique: false
      };
    default:
      return state;
  }
}
