import {
  FETCH_ASSET_SCAN_DETAILS_SUCCESS,
  FETCH_SCAN_DETAILS_REQUEST
} from 'action_types/scan_details';

const initialState = {
  numberCards: [
    {
      key: 1,
      title: 'Total Assets',
      value: 0
    },
    {
      key: 2,
      title: 'Domains Count',
      value: 0
    },
    {
      key: 3,
      title: 'Sub-Domains Count',
      value: 0
    },
    {
      key: 4,
      title: 'IP Count',
      value: 0
    },
    {
      key: 5,
      title: 'Storage Object Count',
      value: 0
    },
    {
      key: 6,
      title: 'Severity',
      value: '--'
    },
    {
      key: 7,
      title: 'Scan Start Date',
      value: '--'
    },
    {
      key: 8,
      title: 'Scan Completion Date',
      value: '--'
    }
  ],
  allAssetTypes: null,
  loading: false,
  targets: [],
  screenshotUrls: [],
  nextItems: null,
  previousItems: null,
  screenshotCount: null,
  thirdPartyScreenshots: [],
  thirdPartyScreenshotCount: null,
  showingAssetScreenshots: true,
  showingThirdPartyScreenshots: false,
  scanId: null,
  scanType: null,
  scanStartDate: null,
  scanName: null,
  disabledTasks: [],
  reportDownloadUrl: null,
  reportDownloadUrlStatus: null,
  dataDownloadUrl: null,
  dataDownloadUrlStatus: null,
  loadingScansPageDownloadUrls: false,
  relatedScans: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SCAN_DETAILS_REQUEST:
    case 'FETCH_SCREENSHOTS_PENDING':
    case 'REGENERATE_REPORT_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'REGENERATE_REPORT_SUCCESS':
      return {
        ...state,
        loading: false
      };
    case FETCH_ASSET_SCAN_DETAILS_SUCCESS:
      return {
        ...state,
        numberCards: [...action.numberCards],
        loading: false,
        assetsCount: action.assetsCount,
        targets: [...action.targets],
        allAssetTypes: action.allAssetTypes,
        disabledTasks: [...action.disabledTasks],
        reportDownloadUrl: action.reportDownloadUrl,
        reportDownloadUrlStatus: action.reportDownloadUrlStatus,
        dataDownloadUrl: action.dataDownloadUrl,
        dataDownloadUrlStatus: action.dataDownloadUrlStatus,
        relatedScans: [...action.relatedScans]
      };
    case 'RESET_REDUCER_DATA':
      return {
        ...state,
        ...initialState
      };

    case 'SAVE_SCREENSHOT_URLS':
      return {
        ...state,
        screenshotUrls: [...action.data],
        screenshotCount: action.count,
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        loading: false
      };
    case 'SAVE_THIRD_PARTY_SCREENSHOT_URLS':
      return {
        ...state,
        thirdPartyScreenshots: [...action.data],
        thirdPartyScreenshotCount: action.data.length,
        loading: false
      };

    case 'SHOW_ASSET_APPLICATION_SCREENSHOTS':
      return {
        ...state,
        showingAssetScreenshots: true,
        showingThirdPartyScreenshots: false
      };
    case 'SHOW_THIRD_PARTY_ASSOCIATION_SCREENSHOTS':
      return {
        ...state,
        showingAssetScreenshots: false,
        showingThirdPartyScreenshots: true
      };
    case 'SCAN_META_DATA':
      return {
        ...state,
        scanId: action.scanId,
        scanType: action.scanType,
        scanStartDate: action.scanStartDate,
        scanName: action.scanName,
        targets: action.targets
      };
    default:
      return state;
  }
}
