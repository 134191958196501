import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

export const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: 'var(--color-text)',
    boxShadow: 'var(--dark-mode-box-shadow)',
    color: 'var(--color-background)',
    fontSize: '16px',
    maxWidth: 800,
    padding: '5px 10px',
    marginRight: 10,
    '&::before': {
      borderWidth: '0 1em 1em 1em',
      borderColor: `transparent transparent var(--color-text) transparent`
    }
  }
})(Tooltip);
