import {
  SAVE_ERROR_DATA,
  SAVE_AWS_KEY_ERROR_DATA,
  JIRA_ERROR,
} from "action_types/error_data";

export const setErrorData = (assetData, errorMessages) => ({
  type: SAVE_ERROR_DATA,
  assetData,
  errorMessages,
});

export const setAwsErrorData = (awsErrorMessages, data) => ({
  type: SAVE_AWS_KEY_ERROR_DATA,
  awsErrorMessages,
  data,
});

export const setAwsSubscriptionError = (awsErrorMessages) => ({
  type: "AWS_NO_SUBSCRIPTION_ERROR",
  awsErrorMessages,
});

export const setAwsIamSubscriptionError = (awsErrorMessages) => ({
  type: "AWS_IAM_NO_SUBSCRIPTION_ERROR",
  awsErrorMessages,
});

export const setAwsArnErrorData = (awsErrorMessages, data) => ({
  type: "AWS_ARN_ERROR_DATA",
  awsErrorMessages,
  data,
});

export const jiraErrorData = (errorMessage) => ({
  type: JIRA_ERROR,
  errorMessage,
});

export const otpError = (errorMessage) => ({
  type: "OTP_ERROR",
  errorMessage,
});

export const subscriptionError = (errorMessage) => ({
  type: "SUBSCRIPTION_ERROR",
  errorMessage,
});

export const orgCodeError = (errorMessage) => ({
  type: "ORG_CODE_ERROR",
  errorMessage,
});

export const gcpErrorData = (errorMessage) => ({
  type: "GCP_ERROR",
  errorMessage,
});
export const doErrorData = (errorMessage) => ({
  type: "DO_ERROR",
  errorMessage,
});
export const cloudflareErrorData = (errorMessage) => ({
  type: "CLOUDFLARE_ERROR",
  errorMessage,
});
export const createDescriptionError = (errorMessages) => ({
  type: "CREATE_DESCRIPTION_ERROR",
  errorMessages,
});

export const passwordChangeError = (errorMessage) => ({
  type: "PASSWORD_CHANGE_ERROR",
  errorMessage,
});
