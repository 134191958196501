import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';
import './index.css';
import App from './app';
import * as serviceWorker from './serviceWorker';
import { setCurrentUser } from 'actions/auth';
import Favicon from 'react-favicon';
import faviconUrl from 'favicon.ico';
const store = configureStore();

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

if (GA_TRACKING_ID) {
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', GA_TRACKING_ID);
  };
}
const role = localStorage.getItem('role');
if (role) {
  store.dispatch(setCurrentUser());
}
console.log(process.env.REACT_APP_VERSION);
ReactDOM.render(
  <Provider store={store}>
    <Favicon url={faviconUrl} />
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
