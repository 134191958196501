import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './app.css';
import 'styles/common.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from 'routes';
import SessionModal from 'components/material_components/material_modals/session_modal';
import {
  ThemeProvider,
  createStyles,
  makeStyles
} from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { SIZES } from 'constants/constants';
import darkTheme from 'constants/dark_theme';
import lightTheme from 'constants/light_theme';
import Auth0ProviderWithHistory from 'auth/auth0-provider-with-history';
import { pageLoadCookie } from 'utils/helpers';

const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      html: {
        // ---- BASIC theme.props.COLORS ---- //

        '--color-text': theme.props.COLORS.text,
        '--color-textBlack': theme.props.COLORS.textBlack,
        '--color-stroke': theme.props.COLORS.stroke,
        '--color-light-text': theme.props.COLORS.lightText,
        '--color-bodyBG': theme.props.COLORS.bodyBG,
        '--color-pageBG': theme.props.COLORS.pageBG,
        '--color-hoverRow': theme.props.COLORS.hoverRow,
        '--color-background': theme.props.COLORS.background,
        '--color-primary': theme.props.COLORS.primary,
        '--color-blueBG': theme.props.COLORS.blueBG,
        '--color-primary500': theme.props.COLORS.primary500,
        '--color-primary600': theme.props.COLORS.primary600,
        '--color-primary400': theme.props.COLORS.primary400,
        '--color-primary300': theme.props.COLORS.primary300,
        '--color-primary200': theme.props.COLORS.primary200,
        '--color-primary100': theme.props.COLORS.primary100,
        '--color-secondary': theme.props.COLORS.secondary,
        '--color-secondary500': theme.props.COLORS.secondary500,
        '--color-secondary400': theme.props.COLORS.secondary400,
        '--color-secondary300': theme.props.COLORS.secondary300,
        '--color-secondary200': theme.props.COLORS.secondary200,
        '--color-secondary100': theme.props.COLORS.secondary100,
        '--color-white': theme.props.COLORS.white,
        '--color-grey': theme.props.COLORS.grey,
        '--color-theme-text': theme.props.COLORS.themeText,
        '--color-dark-grey': theme.props.COLORS.darkGrey,
        '--color-light-grey': theme.props.COLORS.lightGrey,
        '--color-lighter-grey': theme.props.COLORS.lighterGrey,
        '--color-black': theme.props.COLORS.black,
        '--color-muted': theme.props.COLORS.muted,
        '--color-transparentDark': theme.props.COLORS.transparentDark,
        '--color-highlightedBG': theme.props.COLORS.highlightedBG,
        '--color-highlightedBGLight': theme.props.COLORS.highlightedBGLight,
        '--color-sidebar-0': theme.props.COLORS.gradientColorsSix[0],
        '--color-sidebar-1': theme.props.COLORS.gradientColorsSix[1],
        '--color-hover': theme.props.COLORS.hover,

        // ---- ALERT COLORS --- //

        '--color-error': theme.props.COLORS.error,
        '--color-info': theme.props.COLORS.info,
        '--color-light-info': theme.props.COLORS.lightInfo,
        '--color-warning': theme.props.COLORS.warning,
        '--color-warning500': theme.props.COLORS.warning500,
        '--color-warning400': theme.props.COLORS.warning400,
        '--color-warning300': theme.props.COLORS.warning300,
        '--color-warning200': theme.props.COLORS.warning200,
        '--color-warning100': theme.props.COLORS.warning100,
        '--color-success': theme.props.COLORS.success,
        '--color-success500': theme.props.COLORS.success500,
        '--color-success400': theme.props.COLORS.success400,
        '--color-success300': theme.props.COLORS.success300,
        '--color-success200': theme.props.COLORS.success200,
        '--color-success100': theme.props.COLORS.success100,
        '--color-danger': theme.props.COLORS.danger,
        '--color-danger500': theme.props.COLORS.danger500,
        '--color-danger400': theme.props.COLORS.danger400,
        '--color-danger300': theme.props.COLORS.danger300,
        '--color-danger200': theme.props.COLORS.danger200,
        '--color-danger100': theme.props.COLORS.danger100,

        '--color-moderate100': theme.props.COLORS.moderate100,
        '--color-moderate600': theme.props.COLORS.moderate600,
        '--color-critical100': theme.props.COLORS.critical100,
        // ----- New Gradients ----- //
        '--color-gradient-primaryDark': theme.props.COLORS.gradientPrimaryDark,
        '--color-gradient-primaryLight':
          theme.props.COLORS.gradientPrimaryLight,
        '--color-gradient-secondaryDark':
          theme.props.COLORS.gradientSecondaryDark,
        '--color-gradient-secondaryLigth':
          theme.props.COLORS.gradientSecondaryLight,

        // ---- TAG theme.props.COLORS ----- //

        '--color-critical': theme.props.COLORS.critical,
        '--color-high': theme.props.COLORS.high,
        '--color-moderate': theme.props.COLORS.moderate,
        '--color-low': theme.props.COLORS.low,

        // ---- STATUS theme.props.COLORS ----- //

        '--color-scheduled': theme.props.COLORS.scheduled,
        '--color-pending': theme.props.COLORS.pending,
        '--color-running': theme.props.COLORS.running,
        '--color-aborted': theme.props.COLORS.aborted,
        '--color-validating': theme.props.COLORS.validating,
        '--color-finished': theme.props.COLORS.finished,

        '--color-gradient-one-0': theme.props.COLORS.gradientColorsOne[0],
        '--color-gradient-one-1': theme.props.COLORS.gradientColorsOne[1],
        '--color-gradient-two-0': theme.props.COLORS.gradientColorsTwo[0],
        '--color-gradient-two-1': theme.props.COLORS.gradientColorsTwo[1],
        '--color-gradient-three-0': theme.props.COLORS.gradientColorsThree[0],
        '--color-gradient-three-1': theme.props.COLORS.gradientColorsThree[1],
        '--color-gradient-four-0': theme.props.COLORS.gradientColorsFour[0],
        '--color-gradient-four-1': theme.props.COLORS.gradientColorsFour[1],
        '--color-gradient-five-0': theme.props.COLORS.gradientColorsFive[0],
        '--color-gradient-five-1': theme.props.COLORS.gradientColorsFive[1],

        // ---- ROLE theme.props.COLORS ----- //

        '--color-admin': theme.props.COLORS.admin,
        '--color-manager': theme.props.COLORS.manager,
        '--color-reporter': theme.props.COLORS.reporter,
        '--color-disabled': theme.props.COLORS.disabled,
        '--color-deleted': theme.props.COLORS.deleted,
        '--color-verified': theme.props.COLORS.verified,
        '--color-errorBackground': theme.props.COLORS.errorBackground,

        // ---- FONT SIZES ----- //

        '--font-tooltip': SIZES.font10,
        '--font-body': SIZES.font12,
        '--font-p': SIZES.font14,
        '--font-title': SIZES.font16,
        '--font-h3': SIZES.font18,
        '--font-h2': SIZES.font24,
        '--font-h1': SIZES.font32,

        // ---- BOX SHADOW ----- //

        '--box-shadow': theme.shadows[0],
        '--image-box-shadow': theme.shadows[1],
        '--dark-mode-box-shadow': theme.shadows[2],
        '--light-mode-box-shadow': theme.shadows[3]
      }
    }
  })
);

export const GlobalStyles = () => {
  useStyles();

  return null;
};
toast.configure({ autoClose: 3000, draggable: false });

const App = () => {
  const dispatch = useDispatch()
  // pageLoadCookie();
  useEffect(() => {

    const handleWindowResize = () => {
      dispatch({
        type: 'CHANGE_WINDOW_SIZE',
        size: window.innerWidth
      })
    };
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [])
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.getItem("login_time");
      if (Date.parse(localStorage.getItem("login_time")) < Date.parse(new Date())) {
        // if (window.location.pathname !== '/historical-downloads') {
        //   localStorage.removeItem("userProfile");
        //   localStorage.removeItem("selectedGroup");
        //   localStorage.removeItem("AssetGroupParams");
        // }
      }
    };

    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [])
  const isDarkThemeSet = useSelector((state) => state.authentication.darkTheme);
  return (
    <BrowserRouter>
      <ThemeProvider theme={isDarkThemeSet ? darkTheme : lightTheme}>
          <SessionModal />
          <GlobalStyles />
          <Routes />
        <Auth0ProviderWithHistory>
        </Auth0ProviderWithHistory>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
