import {
  FETCH_PIE_CHART_SERIES_REQUEST,
  FETCH_VULNERABILITY_CHART_SERIES_REQUEST,
  FETCH_PIE_CHART_SERIES_SUCCESS,
  FETCH_ASSETS_CHART_SERIES_REQUEST
} from 'action_types/charts';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';
import { checkSeverity } from 'utils/severity_check';

export const fetchPieChartData = (pieData) => (dispatch) => {
  dispatch({ type: FETCH_PIE_CHART_SERIES_REQUEST });

  const pieChartLabels = map(pieData, (data) => checkSeverity(data.severity));

  const pieChartSeries = map(pieData, (data) => data.count);

  dispatch({
    type: FETCH_PIE_CHART_SERIES_SUCCESS,
    pieChartSeries,
    pieChartLabels
  });
};

export const fetchRhPieChartData = (pieData) => (dispatch) => {
  dispatch({ type: FETCH_PIE_CHART_SERIES_REQUEST });
  let pieChartLabels = Object.keys(pieData);

  let pieChartSeries = Object.values(pieData);

  dispatch({
    type: FETCH_PIE_CHART_SERIES_SUCCESS,
    pieChartSeries,
    pieChartLabels
  });
};

export const fetchVulnerabilityChartData = (data) => (dispatch) => {
  dispatch({ type: FETCH_VULNERABILITY_CHART_SERIES_REQUEST });

  const lastSixScans = sortBy(data, ['date']);

  const vulnerabilityChartSeriesData = map(lastSixScans, (scan) => ({
    x: moment.utc(scan.date).format('MMM DD'),
    y: scan.issue_count
  }));

  const vulnerabilityChartSeries = [
    {
      name: 'Issues Count',
      data: vulnerabilityChartSeriesData
    }
  ];
  return vulnerabilityChartSeries;
};

export const fetchAssetsChartData = (data) => (dispatch) => {
  dispatch({ type: FETCH_ASSETS_CHART_SERIES_REQUEST });
  const lastSixScans = sortBy(data, ['date']);

  const assetsChartSeriesData = map(lastSixScans, (scan) => ({
    x: moment.utc(scan.date).format('MMM DD'),
    y: scan.asset_count
  }));

  const assetsChartSeries = [
    {
      name: 'Assets Count',
      data: assetsChartSeriesData
    }
  ];
  return assetsChartSeries;
};

export const fetchAreaChartData = (data) => (dispatch) => {
  const assetsChartSeries = dispatch(fetchAssetsChartData(data));
  const issuesChartSeries = dispatch(fetchVulnerabilityChartData(data));

  dispatch({
    type: 'FETCH_AREA_CHART_SERIES_SUCCESS',
    data: [...assetsChartSeries, ...issuesChartSeries]
  });
};
