import * as errorTypes from 'action_types/error';

const initialState = {
  notifications: [],
  activityNotifications: [],
  nextItems: null,
  previousItems: null,
  totalCount: null,
  unreadCount: null,
  loading: false,
  latestNotifications: [],
  report_data: [],
  notificationId: ''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'GET_ALL_NOTIFICATIONS_REQUEST':
    case 'GET_LATEST_NOTIFICATIONS_REQUEST':
    case 'GET_ALL_REPORT_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'GET_ALL_NOTIFICATIONS_SUCCESS':
      return {
        ...state,
        loading: false,
        notifications: [...action.notifications],
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        totalCount: action.totalCount,
        unreadCount: action.unreadCount
      };
    case 'GET_ACTIVITY_NOTIFICATIONS_SUCCESS':
      return {
        ...state,
        loading: false,
        activityNotifications: [...action.notifications],
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        totalCount: action.totalCount,
        unreadCount: action.unreadCount
      };
    case 'GET_ALL_REPORT_SUCCESS':
      return {
        ...state,
        loading: false,
        report_data: [...action.data]
      };
    case 'GET_LATEST_NOTIFICATIONS_SUCCESS':
      return {
        ...state,
        loading: false,
        latestNotifications: [...action.latestNotifications],
        totalCount: action.totalCount,
        unreadCount: action.unreadCount
      };
    case 'SET_NOTIFICATION_ID':
      return {
        notificationId: action.id
      };
    case errorTypes.CATCH_ERROR:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
