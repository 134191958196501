export function fetchURLParams() {
    let jsonifyQueryParam = {};
    if (typeof window !== undefined) {
      let locationURLParam = window.location.href.split("?")[1];
      if (locationURLParam) {
        locationURLParam.split("&").forEach(function(param) {
          param = param.split("=");
          jsonifyQueryParam[param[0]] = decodeURIComponent(param[1] || "");
        });
      }
    }
    return jsonifyQueryParam;
  }
  


  export const getDomain = function (url = window.location.origin) {
    if (url.includes("localhost")) {
      return "";
    }
  
    url = url.replace(/(https?:\/\/)?(www.)?/i, "");
  
    url = url.split(".");
  
    url = url.slice(url.length - 2).join(".");
  
    if (url.indexOf("/") !== -1) {
      url = url.split("/")[0];
    }
  
    return `.${url}`;
  };

// fetch a particular cookie
export function getCookie(name) {
    const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return v ? v[2] : null;
  }
  
  export const setCookie = (key, val, expiry) => {
    const domain = getDomain();
    if (!expiry) {
      expiry = new Date(
        new Date().setMonth(new Date().getMonth() + 1)
      ).toUTCString();
    }
    return (document.cookie = `${key}=${val};domain=${domain};expires=${expiry};path=/; ${
      ["production", "preview"].includes(process.env.REACT_APP_ENV) ? "" : ""
    }`);
  };
  
  export const remCookie = key => {
    const domain = getDomain();
    document.cookie = `${key}=; domain=${domain}; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  };



  export const searchParam = (param) => {
    let jsonifyQueryParam = fetchURLParams();
    if (!_isEmpty(jsonifyQueryParam)) {
      return jsonifyQueryParam[param];
    }
    return null;
  };
export const calculateAndStoreUTM = () => {
    const domain = getDomain();
    console.log("calculateAndStoreUTM called for : " + domain);
    let utmCookieName = "URLParams";
  
    let jsonifyQueryParam = fetchURLParams();
    // console.log("jsonifyQueryParam: " + JSON.stringify(jsonifyQueryParam));
    if (jsonifyQueryParam) {
      let filterdUTMParams = {};
      // filtering selective params with values only
      Object.keys(jsonifyQueryParam).forEach(param => {
        // console.log("filterdUTMParams: " + JSON.stringify(param));
        if (
          param.startsWith("utm") ||
          [
            "offer_id",
            "click_id",
            "cjevent",
            "fbclid",
            "gclid",
            "voluumtid",
            "refCode",
            "x-amzn-marketplace-token"
          ].includes(param) || param === "x-amzn-marketplace-token"
        ) {
          let paramValue = jsonifyQueryParam[param];
  
          if (![undefined, null, ""].includes(paramValue)) {
            filterdUTMParams[param] = paramValue;
          }
        }
      });
      if (Object.keys(filterdUTMParams).length) {
        let utmParamCookie = "";
        let utmCookieValue = {};
        try {
          utmParamCookie = decodeURIComponent(getCookie(utmCookieName) || "");
          if (utmParamCookie) {
            utmCookieValue = JSON.parse(utmParamCookie);
          }
        } catch (e) {}
        let cookieExpiryDate = new Date();
        cookieExpiryDate.setDate(cookieExpiryDate.getDate() + 1);
  
        utmCookieValue = {
          ...utmCookieValue,
          ...filterdUTMParams
        };
        console.log("utmCookieName: " + utmCookieName + JSON.stringify(utmCookieValue));
        document.cookie = `${utmCookieName}=${JSON.stringify(
          utmCookieValue
        )};domain=${domain};expires=${cookieExpiryDate};path=/;`;
      }
    } else {
      return;
    }
  };

function parameterize(obj) {
  let str = "";
  for (let key in obj) {
    if (str != "") {
      str += "&";
    }
    str += key + "=" + encodeURIComponent(obj[key]);
  }
  return str;
}

export const pageLoadCookie = () => {
    console.log("pageLoadCookie called");
    let locationURLParam = window.location.href.split("?")[1];
    if (locationURLParam) {
      let jsonifyQueryParam = {};
      locationURLParam.split("&").forEach(function (param) {
        param = param.split("=");
        jsonifyQueryParam[param[0]] = decodeURIComponent(param[1] || "");
      });
      if (jsonifyQueryParam.jwt_token) {
        setCookie("token", jsonifyQueryParam.jwt_token);
        delete jsonifyQueryParam.jwt_token;
        let search = parameterize(jsonifyQueryParam);
        if (search) {
          search = "?" + search;
        }
        window.history.replaceState(
          null,
          "",
          document.location.origin + document.location.pathname + search
        );
      }
    }
  
    calculateAndStoreUTM();
  };

  
  