import React, { Component } from 'react';
// import Loader from 'react-loader-spinner';
import { makeStyles } from '@material-ui/core/styles';
import 'styles/loader.css';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '166px',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  flex: {
    flexDirection: 'column'
  }
}));
const Loading = (props) => {

  const classes = useStyles();
  return (

    <div style={props.loaderStyle} className={classes.flex}>
      {/* <Loader
          type={this.props.type}
          color={this.props.color || 'var(--color-primary)'}
          height={this.props.height}
          width={this.props.width || '5px'}
        /> */}
      <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  );
}

export default Loading
