import { CATCH_ERROR } from 'action_types/error';

const initialState = {
  message: '',
  title: '',
  showJiraStatus: false,
  aiDalete: false,
  showIcon: false,
  data: [],
  checkActive: false,
  inventoryData: [],
  orgId: null,
  redirectTo: '',
  buttonTitle: '',
  loadingModal: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'SUCCESS_MESSAGE_MODAL':
      return {
        ...state,
        ...action.message,
        data: action.message.data ? action.message.data : [],
        aiDalete: action.message.aiDalete ? action.message.aiDalete : false,
        orgId: action.message.orgId ? action.message.orgId : null,
        inventoryData: action.message.inventoryData
          ? action.message.inventoryData
          : [],
        checkActive: action.message.checkActive
          ? action.message.checkActive
          : false,
        showIcon: action.message.showIcon ? action.message.showIcon : false,
        loadingModal: false
      };

    case 'RESET_REDUCER_DATA':
      return {
        ...state,
        ...initialState
      };
    case 'NEW_ASSET_ADD_REQUEST':
    case 'SHOW_MODAL_LOADER':
    case 'GET_ORGS_REQUEST':
    case 'CHANGE_USER_PASSWORD_REQUEST':
    case 'SLACK_NOTIFICATION_REQUEST':
    case 'UPDATE_CELERY_TASK_PERMISSION_REQUEST':
    case 'UPDATE_SCHEDULED_SCAN_REQUEST':
    case 'UPDATE_SCAN_REQUEST':
    case 'REVERSE_CREDITS_REQUEST':
    case 'UPDATE_RH_ORGANISATION_LIST_REQUEST':
    case 'UPDATE_RH_USERS_LIST_REQUEST':
    case 'ISSUE_POC_UPLOAD_REQUEST':
    case 'DELETE_ALL_PENDING_ERRORED_SCANS':
    case 'EDIT_ORGANISATION_DETAILS_REQUEST':
    case 'FETCH_NEW_ISSUE_CONDITION_REQUEST':
    case 'CREATE_ISSUE_CONDITION_DESCRIPTION_RECOMMENDATION_REQUEST':
    case 'DELETE_ISSUE_CONDITION_DESCRIPTION_RECOMMENDATION_REQUEST':
    case 'EDIT_ISSUE_CONDITION_DESCRIPTION_RECOMMENDATION_REQUEST':
    case 'ISSUE_UPDATE_REQUEST':
    case 'DATALEAK_UPDATE_REQUEST':
    case 'CREATE_NEW_DATALEAK_SIGNATURE_REQUEST':
    case 'DELETE_DATALEAK_SIGNATURE_REQUEST':
    case 'EDIT_DATALEAK_SIGNATURE_REQUEST':
    case 'SEND_NESSUS_POST_REQUEST':
    case 'SAVE_TARGETS_REQUEST':
    case 'CREATE_SUBSCRIPTION_REQUEST':
      return {
        ...state,
        loadingModal: true
      };
    case CATCH_ERROR:
      return {
        ...state,
        loadingModal: false
      };
    default:
      return state;
  }
}
