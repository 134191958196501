import {
  faSmile,
  faServer,
  faBug,
  faClock,
  faCheckCircle,
  faRunning,
  faBan,
  faExclamationTriangle,
  faExclamationCircle,
  faExclamation,
  faCheckDouble,
  faQrcode,
  faLink,
  faRadiation,
  faNetworkWired,
  faProjectDiagram,
  faDatabase,
  faShareAlt,
  faGlobe,
  faBookmark,
  faBuilding,
  faListAlt,
  faTrashAlt,
  faListUl
} from '@fortawesome/free-solid-svg-icons';

import {
  faTwitter,
  faAndroid,
  faAppStoreIos,
  faFacebookF,
  faLinkedinIn,
  faGithub,
  faGitAlt
} from '@fortawesome/free-brands-svg-icons';
import toLower from 'lodash/toLower';
import startsWith from 'lodash/startsWith';

export const critical = faExclamationTriangle;
export const high = faExclamationCircle;
export const moderate = faExclamation;
export const normal = faSmile;

export const assets = faServer;
export const scans = faQrcode;
export const bug = faBug;

export const scheduled = faClock;
export const completed = faCheckCircle;
export const running = faRunning;
export const reported = faCheckDouble;
export const aborted = faBan;

export const abort = faBan;
export const deleted = faTrashAlt;

const icons = {
  subdomain: faNetworkWired,
  domain: faLink,
  'associated domain': faProjectDiagram,
  'domain alias': faRadiation,
  twitter: faTwitter,
  facebook: faFacebookF,
  linkedin: faLinkedinIn,
  crunchbase: faShareAlt,
  'associated bucket': faDatabase,
  'android app': faAndroid,
  'ios app': faAppStoreIos,
  'code repo': faGithub,
  'code repo group': faGitAlt,
  'ip address': faGlobe,
  'parent company': faBuilding,
  'child company': faBuilding,
  'sister company': faBuilding,
  'ip range': faListAlt,
  cidr: faListUl
};

export const showAssetTypeIcons = (type) => {
  type = toLower(type);
  if (startsWith(type, 'social media')) {
    type = type.split(': ').pop();
  }

  return icons[type] ? icons[type] : faBookmark;
};
