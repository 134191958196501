import {
  FETCH_PIE_CHART_SERIES_REQUEST,
  FETCH_VULNERABILITY_CHART_SERIES_REQUEST,
  FETCH_PIE_CHART_SERIES_SUCCESS,
  FETCH_ASSETS_CHART_SERIES_REQUEST
} from 'action_types/charts';

const initialState = {
  pieChartSeries: [0, 0, 0, 0],
  pieChartLabels: ['Low', 'Moderate', 'High', 'Critical'],
  loadingPieChart: false,
  loadingVulnerabilityBarChart: false,
  loadingAssetsBarChart: false,
  chartSeries: [
    {
      name: 'Issues Count',
      data: []
    },
    {
      name: 'Assets Count',
      data: []
    }
  ]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_PIE_CHART_SERIES_REQUEST:
      return {
        ...state,
        loadingPieChart: true
      };
    case FETCH_VULNERABILITY_CHART_SERIES_REQUEST:
      return {
        ...state,
        loadingVulnerabilityBarChart: true
      };
    case FETCH_PIE_CHART_SERIES_SUCCESS:
      return {
        ...state,
        pieChartSeries: [...action.pieChartSeries],
        loadingPieChart: false,
        pieChartLabels: [...action.pieChartLabels]
      };

    case FETCH_ASSETS_CHART_SERIES_REQUEST:
      return {
        ...state,
        loadingAssetsBarChart: true
      };

    case 'RESET_REDUCER_DATA':
      return {
        ...state,
        ...initialState
      };

    case 'FETCH_AREA_CHART_SERIES_SUCCESS':
      return {
        ...state,
        chartSeries: [...action.data]
      };
    default:
      return state;
  }
}
