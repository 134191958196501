import {
  SAVE_ORGANISATION_DETAILS_SUCCESS,
  SAVE_ORGANISATION_DETAILS_REQUEST,
  GET_ORGANISATION_DETAILS_REQUEST
} from 'action_types/onboarding';
import * as errorTypes from 'action_types/error';

const initialState = {
  organisationName: '',
  org_code: '',
  website: '',
  billingAddress: '',
  industry: '',
  status: '',
  users: [],
  asset_groups: [],
  pendingUsers: [],
  inviteUsers: [],
  loading: false,
  jiraStatusLoading: false,
  userRoles: [],
  nextItems: null,
  previousItems: null,
  usersCount: null,
  slackChannel: '',
  webhookUrl: '',
  industryList: [],
  orgStatusList: [],
  mappungJiraStatus: {},
  mappungJiraToRhlStatus: {},
  jiraStatus: [],
  jiraPriority: [],
  serviceNowStatus: [],
  serviceNowMappingStatus: {},
  jiraMappingStatus: {},
  jiraTypeList: [],
  issueTrackerStatus: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_ORGANISATION_DETAILS_SUCCESS:
    case 'FETCH_RH_ORGANISATION_DETAILS_SUCCCESS':
      return {
        ...state,
        ...action.orgDetails,
        loading: false
      };
    case SAVE_ORGANISATION_DETAILS_REQUEST:
    case GET_ORGANISATION_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case 'GET_USERS_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'GET_USERS_SUCCESS':
      return {
        ...state,
        loading: false,
        users: [...action.users],
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        usersCount: action.usersCount
      };

    case 'FETCH_ALL_USER_ROLES':
      return {
        ...state,
        userRoles: [...action.roles]
      };
    case 'GET_JIRA_STATUS':
      return {
        ...state,
        jiraStatus: [...action.data]
      };
    case 'GET_JIRA_PRIORITY':
      return {
        ...state,
        jiraPriority: [...action.data]
      };
    case 'GET_JIRA_PRIORITY_MAPPING':
      return {
        ...state,
        jiraMappingStatus: action.data
      };
    case 'GET_SERVICE_NOW_STATUS':
      return {
        ...state,
        serviceNowStatus: [...action.data]
      };
    case 'GET_SERVICE_NOW_MAPPING_STATUS':
      return {
        ...state,
        serviceNowMappingStatus: action.data
      };
    case 'GET_ISSUE_TRACKER_STATUS':
      return {
        ...state,
        issueTrackerStatus: [...action.data]
      };
    case 'FETCH_MAPPING_JIRA_STATUS':
      return {
        ...state,
        mappungJiraStatus: action.data
      };
    case 'FETCH_MAPPING_JIRA_TO_RHL_STATUS':
      return {
        ...state,
        mappungJiraToRhlStatus: action.data
      };
    case 'FETCH_JIRA_STATUS_SUCCESS_REQUEST':
      return {
        ...state,
        jiraStatusLoading: true
      };
    case 'FETCH_JIRA_STATUS_SUCCESS':
      return {
        ...state,
        jiraStatusLoading: false
      };
    case 'FETCH_ORGS_STATUS_LIST':
      return {
        ...state,
        orgStatusList: [...action.payload]
      };
    case 'FETCH_INDUSTRY_LIST':
      return {
        ...state,
        industryList: [...action.data]
      };
    case 'FETCH_JIRA_TYPE_SUCCESS':
      return {
        ...state,
        jiraTypeList: [...action.data]
      };
    case 'RESET_REDUCER_DATA':
      return {
        ...state,
        ...initialState
      };
    case errorTypes.CATCH_ERROR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
