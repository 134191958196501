const initialState = {
  unvalidatedDomains: [],
  validDomains: [],
  discoveredDomains: [],
  otherAssets: [],
  ips: [],
  gitRepos: [],
  awsKeys: [],
  apiKeys: "",
  subscriptionPlans: [],
  notify_default: {},
  currentSubscriptionInfo: null,
  loading: false,
  dataleakLoading: false,
  issueLoading: false,
  notificationLoading: false,
  changePlan: false,
  integrationData: {},
  headerInfo: {},
  clientInfo: {},
  reportUrl: {},
  rdpValue: {},
  setDomainRdp: {},
  validateByDns: {},
  validateByCustSupport: {},
  createUnvalidatedDomainsResponse: {},
  loadingExistingDomains: false,
  otherAssetTypes: [],
  issueBlackList: [],
  assetExposureBlackList: [],
  assetInventoryBlackList: [],
  dataleakBlackList: [],
  email_notification_mails: [],
  org_user_emails: [],
  nextItems: null,
  previousItems: null,
  count: null,
  currentUrl: null,
  addOtherAssetSuccess: "",
  checkedUnsaveChanged: false,
  first_scan_triggered: true,
  doToken: {},
  cloudflareKey: {},
  gcpKey: {},
  gcpId: null,
  assetMsg: "",
  aws_arn_key: [],
  notifyList: [],
  notifyType: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "RESET_REDUCER_DATA":
    case "RESET_ERROR_INFO": {
      const reportUrl = state.reportUrl;
      return {
        ...state,
        ...initialState,
        reportUrl,
      };
    }

    case "FETCH_TARGET_SUCCESS":
      return {
        ...state,
        loading: false,
        doToken: action.cloudKeys.do,
        cloudflareKey: action.cloudKeys.cloudflare,
        gcpKey: action.gcp,
        gcpId: action.gcpId,
        ips: [...action.ips],
        gitRepos: [...action.gitRepos],
        awsKeys: [...action.awsKeys],
        unvalidatedDomains: [...action.unvalidDomains],
        validDomains: [...action.validDomains],
        discoveredDomains: [...action.discoveredDomains],
        otherAssets: [...action.otherAssets],
        integrationData: action.integrationData,
        first_scan_triggered: action.first_scan_triggered,
        email_notification_mails: action.email_notification_mails,
        org_user_emails: action.org_user_emails,
        notify_default: action.notify_default,
        // otherAssetTypes: action.otherTypes,
        aws_arn_key: action.aws_arn_key,
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        count: action.count,
      };
    case "FETCH_TARGET_TYPE_SUCCESS":
      return {
        ...state,
        otherAssetTypes: action.otherTypes,
      };
    case "FETCH_EXISTINGRDP_SUCCESS":
      return {
        ...state,
        rdpValue: action.rdpValue,
      };
    case "FETCH_SUBSCRIPTION_PLANS_REQUEST":
    case "FETCH_CURRENT_SUBSCRIPTION_INFO_REQUEST":
    case "FETCH_TARGETS_REQUEST":
    case "FETCH_CLIENT_PAYMENT_INFO_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_BLACKLIST_ISSUE_REQUEST":
      return {
        ...state,
        issueLoading: true,
      };
    case "FETCH_BLACKLIST_DATALEAK_REQUEST":
      return {
        ...state,
        dataleakLoading: true,
      };
    case "FETCH_SUBSCRIPTION_PLANS_SUCCESS":
      return {
        ...state,
        subscriptionPlans: action.subscriptionPlans,
        loading: false,
      };
    case "FETCH_CURRENT_SUBSCRIPTION_INFO_SUCCESS":
      return {
        ...state,
        currentSubscriptionInfo: action.data,
        loading: false,
      };
    case "SET_HEADER":
      return {
        ...state,
        headerInfo: action.headerInfo,
      };
    case "SET_DOMAINRDP_SUCCESS":
      return {
        ...state,
        setDomainRdp: action.data,
      };
    case "VALIDATE_BYDNS_SUCCESS":
      return {
        ...state,
        validateByDns: action.data,
      };
    case "VALIDATE_BYCUST_SUCCESS":
      return {
        ...state,
        validateByCustSupport: action.data,
      };
    case "CREATE_UNVALIDATED_DOMAINS_REQUEST":
      return {
        loadingExistingDomains: true,
      };
    case "CREATE_UNVALIDATED_DOMAINS_SUCCESS":
      return {
        ...state,
        createUnvalidatedDomainsResponse: action.data,
        loadingExistingDomains: false,
      };
    case "FETCH_API_KYES_REQUEST":
      return {
        ...state,
        apiKeys: action.data,
      };
    case "CHANGE_SUBSCRIPTION_PLAN_TRUE":
      return {
        ...state,
        changePlan: true,
      };
    case "CHANGE_SUBSCRIPTION_PLAN_FALSE":
      return {
        ...state,
        changePlan: false,
      };
    case "FETCH_CLIENT_PAYMENT_INFO_SUCCESS":
      return {
        ...state,
        clientInfo: action.data,
      };
    case "DOWNLOAD_REPORT_URL_RESPONSE":
      return {
        ...state,
        reportUrl: action.data,
      };
    case "SET_OTHER_ASSETS_SUCCESS":
      return {
        ...state,
        addOtherAssetSuccess: action.data,
      };
    case "SET_UNSAVED_CHANGED":
      return {
        ...state,
        checkedUnsaveChanged: true,
      };
    case "REMOVED_UNSAVED_CHANGED":
      return {
        ...state,
        checkedUnsaveChanged: false,
      };
    case "FETCH_NOTIFY_LIST_REQUEST":
      return {
        ...state,
        notificationLoading: true,
      };
    case "FETCH_NOTIFY_LIST_SUCCESS":
      return {
        ...state,
        notifyList: action.data,
        notificationLoading: false,
      };
    case "FETCH_NOTIFY_TYPE_SUCCESS":
      return {
        ...state,
        notifyType: action.data,
        notificationLoading: false,
      };
    case "FETCH_ISSUE_BLACKLIST_SUCCESS":
      return {
        ...state,
        loading: false,
        issueLoading: false,
        issueBlackList: action.data,
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        count: action.count,
      };
    case "FETCH_ASSET_EXPOSURE_BLACKLIST_SUCCESS":
      return {
        ...state,
        loading: false,
        issueLoading: false,
        dataleakLoading: false,
        assetExposureBlackList: action.data,
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        count: action.count,
      };
    case "FETCH_ASSET_INVENTORY_BLACKLIST_SUCCESS":
      return {
        ...state,
        loading: false,
        issueLoading: false,
        dataleakLoading: false,
        assetInventoryBlackList: action.data,
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        count: action.count,
      };
    case "FETCH_DATALEAKS_BLACKLIST_SUCCESS":
      return {
        ...state,
        loading: false,
        dataleakLoading: false,
        dataleakBlackList: action.data,
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        count: action.count,
      };
    case "FETCH_ASSET_EXPOSURE_BACKLIST_MSG":
      return {
        ...state,
        assetMsg: action.message,
      };
    default:
      return state;
  }
}
