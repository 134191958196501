import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import Bar from 'assets/bar-graph.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  no_data_found: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 8,
    alignItems: 'center',
    padding: 10
  },

  no_data_found_icon: {
    fontSize: '30px',
    color: 'var(--color-theme-text)'
  },
  no_data_found_body_text: {
    textTransform: 'uppercase',
    color: 'var(--color-theme-text)',
    paddingTop: '1rem',
    fontWeight: 500,
    fontSize: 12
  }
}));

const NoDataFound = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.no_data_found} style={props.style}>
      {/* <FontAwesomeIcon
        icon={faDatabase}
        className={classes.no_data_found_icon}
      /> */}
      <img src={Bar} alt="bar" width="40" />
      <p className={classes.no_data_found_body_text}>
        THERE IS NO DATA TO DISPLAY
      </p>
    </div>
  );
};

export default NoDataFound;
