export const checkSeverity = (severityNumber) => {
  let severity;
  switch (severityNumber) {
    case 1:
      severity = 'LOW';
      break;
    case 2:
      severity = 'MODERATE';
      break;
    case 3:
      severity = 'HIGH';
      break;
    case 4:
      severity = 'CRITICAL';
      break;
    case 0:
      severity = 'INFO';
      break;
    default:
      severity = 'NONE';
      break;
  }
  return severity;
};

export const getSeverity = (severityNumber) => {
  let severity;
  switch (severityNumber) {
    case 'LOW':
      severity = 1;
      break;
    case 'MODERATE':
      severity = 2;
      break;
    case 'HIGH':
      severity = 3;
      break;
    case 'CRITICAL':
      severity = 4;
      break;
    case 'INFO':
      severity = 0;
      break;
    default:
      severity = 'NONE';
      break;
  }
  return severity;
};


export const getColorBasedOnNumber = (value) => {
  if (value >= 90) {
    return '#006400'; // Dark Green
  } else if (value >= 70 && value < 90) {
    return '#81c081'; // Light Green
  } else if (value >= 40 && value < 70) {
    return '#FFA500'; // Yellow
  } else if (value >= 1 && value < 40) {
    return '#FF4500'; // Orange
  } else {
    return '#6b7c8d'; // Default to Dark Green for out-of-range values
  }
}
export const getScoreRating = (value) => {
  if (value >= 90) {
    return 'Awesome'; // Dark Green
  } else if (value >= 70 && value < 90) {
    return 'Good'; // Light Green
  } else if (value >= 40 && value < 70) {
    return 'Average'; // Yellow
  } else if (value >= 1 && value < 40) {
    return 'Poor'; // Orange
  } else {
    return 'N/A'; // Default to Dark Green for out-of-range values
  }
}