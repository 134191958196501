export const SHOW_SUCCESS_MODAL = 'SHOW_SUCCESS_MODAL';
export const HIDE_SUCCESS_MODAL = 'HIDE_SUCCESS_MODAL';
export const SHOW_FAILURE_MODAL = 'SHOW_FAILURE_MODAL';
export const HIDE_FAILURE_MODAL = 'HIDE_FAILURE_MODAL';
export const SHOW_ADD_TARGET_MODAL = 'SHOW_ADD_TARGET_MODAL';
export const HIDE_ADD_TARGET_MODAL = 'HIDE_ADD_TARGET_MODAL';
export const SHOW_CONFIRM_DELETE_MODAL = 'SHOW_CONFIRM_DELETE_MODAL';
export const HIDE_CONFIRM_DELETE_MODAL = 'HIDE_CONFIRM_DELETE_MODAL';
export const SHOW_CONFIRM_DELETE_TAG_MODAL = 'SHOW_CONFIRM_DELETE_TAG_MODAL';
export const HIDE_CONFIRM_DELETE_TAG_MODAL = 'HIDE_CONFIRM_DELETE_TAG_MODAL';
export const SHOW_ADD_TAGS_MODAL = 'SHOW_ADD_TAGS_MODAL';
export const HIDE_ADD_TAGS_MODAL = 'HIDE_ADD_TAGS_MODAL';
export const SHOW_ADD_ISSUE_TRACKER_MODAL = 'SHOW_ADD_ISSUE_TRACKER_MODAL';
export const HIDE_ADD_ISSUE_TRACKER_MODAL = 'HIDE_ADD_ISSUE_TRACKER_MODAL';
export const SHOW_ASSIGN_USER_MODAL = 'SHOW_ASSIGN_USER_MODAL';
export const HIDE_ASSIGN_USER_MODAL = 'HIDE_ASSIGN_USER_MODAL';
export const SHOW_ADD_RH_ASSETS_MODAL = 'SHOW_ADD_RH_ASSETS_MODAL';
export const HIDE_ADD_RH_ASSETS_MODAL = 'HIDE_ADD_RH_ASSETS_MODAL';
export const SHOW_ADD_USER_MODAL = 'SHOW_ADD_USER_MODAL';
export const HIDE_ADD_USER_MODAL = 'HIDE_ADD_USER_MODAL';
export const SHOW_CHANGE_PASSWORD_MODAL = 'SHOW_CHANGE_PASSWORD_MODAL';
export const HIDE_CHANGE_PASSWORD_MODAL = 'HIDE_CHANGE_PASSWORD_MODAL';
export const SHOW_CONFIRM_DELETE_SCAN_MODAL = 'SHOW_CONFIRM_DELETE_SCAN_MODAL';
export const HIDE_CONFIRM_DELETE_SCAN_MODAL = 'HIDE_CONFIRM_DELETE_SCAN_MODAL';
export const SHOW_CONFIRM_RE_SCAN_MODAL = 'SHOW_CONFIRM_RE_SCAN_MODAL';
export const HIDE_CONFIRM_RE_SCAN_MODAL = 'HIDE_CONFIRM_RE_SCAN_MODAL';
export const SHOW_CONFIRM_DELETE_USER_MODAL = 'SHOW_CONFIRM_DELETE_USER_MODAL';
export const HIDE_CONFIRM_DELETE_USER_MODAL = 'HIDE_CONFIRM_DELETE_USER_MODAL';
export const SHOW_ADD_ORG_ASSET_MAPPING_MODAL =
  'SHOW_ADD_ORG_ASSET_MAPPING_MODAL';
export const HIDE_ADD_ORG_ASSET_MAPPING_MODAL =
  'HIDE_ADD_ORG_ASSET_MAPPING_MODAL';
export const SHOW_EDIT_ORG_MODAL = 'SHOW_EDIT_ORG_MODAL';
export const HIDE_EDIT_ORG_MODAL = 'HIDE_EDIT_ORG_MODAL';
export const SHOW_CREATE_DESCRIPTION_MODAL = 'SHOW_CREATE_DESCRIPTION_MODAL';
export const HIDE_CREATE_DESCRIPTION_MODAL = 'HIDE_CREATE_DESCRIPTION_MODAL';
export const SHOW_ADD_EDIT_DATALEAK_SIGNATURE_MODAL =
  'SHOW_ADD_EDIT_DATALEAK_SIGNATURE_MODAL';
export const HIDE_ADD_EDIT_DATALEAK_SIGNATURE_MODAL =
  'HIDE_ADD_EDIT_DATALEAK_SIGNATURE_MODAL';
export const SHOW_UPLOAD_RH_ASSETS_MODAL = 'SHOW_UPLOAD_RH_ASSETS_MODAL';
export const HIDE_UPLOAD_RH_ASSETS_MODAL = 'HIDE_UPLOAD_RH_ASSETS_MODAL';
export const SHOW_UPLOAD_RH_ASSETS_PROFILER_MODAL = 'SHOW_UPLOAD_RH_ASSETS_PROFILER_MODAL';
export const HIDE_UPLOAD_RH_ASSETS_PROFILER_MODAL = 'HIDE_UPLOAD_RH_ASSETS_PROFILER_MODAL';
export const SHOW_RH_NESSUS_FILE_MODAL = 'SHOW_RH_NESSUS_FILE_MODAL';
export const HIDE_RH_NESSUS_FILE_MODAL = 'HIDE_RH_NESSUS_FILE_MODAL';
export const SHOW_ADD_SETTINGS_TARGETS_MODAL =
  'SHOW_ADD_SETTINGS_TARGETS_MODAL';
export const HIDE_ADD_SETTINGS_TARGETS_MODAL =
  'HIDE_ADD_SETTINGS_TARGETS_MODAL';
export const SHOW_CANCEL_SUBSCRIPTION_MODAL = 'SHOW_CANCEL_SUBSCRIPTION_MODAL';
export const HIDE_CANCEL_SUBSCRIPTION_MODAL = 'HIDE_CANCEL_SUBSCRIPTION_MODAL';
export const SHOW_PAYMENT_AUTHENTICATION_MODAL =
  'SHOW_PAYMENT_AUTHENTICATION_MODAL';
export const HIDE_PAYMENT_AUTHENTICATION_MODAL =
  'HIDE_PAYMENT_AUTHENTICATION_MODAL';
export const SHOW_PAYMENT_METHOD_UPDATE_MODAL =
  'SHOW_PAYMENT_METHOD_UPDATE_MODAL';
export const HIDE_PAYMENT_METHOD_UPDATE_MODAL =
  'HIDE_PAYMENT_METHOD_UPDATE_MODAL';
export const SHOW_ADD_RH_TARGET_MODAL =
  'SHOW_ADD_RH_TARGET_MODAL';
export const HIDE_ADD_RH_TARGET_MODAL =
  'HIDE_ADD_RH_TARGET_MODAL';
