export const LIGHT_THEME_COLORS = {
  text: '#1A1A1A',
  textBlack: '#000000',
  background: '#FFFFFF',
  stroke: '#DBDBDB',
  bodyBG: '#F7F8FD',
  pageBG: '#F6F7FD',
  blueBG: '#13135D',
  primary: '#150578',
  primary600: '#0E0E52',
  // primary: '#AE2B22',
  primary500: '##192BC2',
  primary400: '#C73127',
  primary300: '#DFE1F6',
  primary200: '#E8EAF9',
  primary100: '#F6F7FD',
  secondary: '#0057FF',
  secondary500: '#0050EA',
  secondary400: '#2E76FF',
  secondary300: '#8FB5FF',
  secondary200: '#CFDFFF',
  secondary100: '#EFF4FF',
  white: '#FFFFFF',
  black: '#000',
  muted: '#C7C3DF',
  highlightedBG: '#E8EAF9',
  highlightedBGLight: '#fcf6f5',
  themeText: '#4D4D4D',
  grey: '#919eab',
  transparentDark: '#0000002f',
  darkGrey: '#6b7c8d',
  lightGrey: '#e3eaee',
  lightText: '#333',
  lighterGrey: '#f6f8fa',
  darkModeBoxShadow: 'transparent',
  errorBackground: '#FCF0EF',
  hover: '#FEF9F9',
  // ---- Alert Colors ---- //

  error: '#f44336',
  info: '#0C0199',
  lightInfo: '#E3F6FE',
  success: '#116D09',
  success500: '#13770A',
  success400: '#1CB20F',
  success300: '#85F47B',
  success200: '#E4FDE2',
  success100: '#F7FEF6',
  danger: '#D01509',
  danger500: '#E3170A',
  danger400: '#F53225',
  danger300: '#F9847C',
  danger200: '#FEE0DE',
  danger100: '#FFF9F8',
  warning: '#EC4C00',
  warning500: '#FF5403',
  warning400: '#FF712D',
  warning300: '#FF9B6C',
  warning200: '#FFD4C0',
  warning100: '#FFF6F2',

  moderate100: '#FFFBEF',
  moderate600: '#735600',
  critical100: '#FFEDEC',

  // ---- Gradients -----//
  gradientPrimaryDark: 'linear-gradient(92.65deg, #AE2B22 0%, #DA4B42 100%)',
  gradientPrimaryLight: 'linear-gradient(93.51deg, #E99690 0%, #FCF0EF 100%)',
  gradientSecondaryDark: 'linear-gradient(92.65deg, #0057FF 0%, #508BFF 100%)',
  gradientSecondaryLight: 'linear-gradient(92.65deg, #8FB5FF 0%, #EFF4FF 100%)',

  // ---- Tags Colors ----- //

  // high: '#FF7900',
  // critical: '#c00',
  // moderate: '#e6c21a',
  // low: '#43a047',
  high: '#FD8C00',
  critical: '#DC0000',
  moderate: '#BD6B00',
  low: '#00AC46',

  // ---- Status Colors ----- //

  scheduled: '#38158a',
  pending: '#fdc04f',
  running: '#2096f3',
  aborted: '#a1a0a0',
  validating: '#491071',
  finished: '#43a047',

  // ---- Role Colors ----- //

  admin: '#43a047',
  manager: '#491071',
  reporter: '#2096f3',
  disabled: '#807e7e',
  deleted: '#a8a8a8',
  verified: '#4caf50',

  // ---- Card Colors ---- //

  gradientColorsOne: ['#FC3A6D', '#FF7E58'],
  gradientColorsTwo: ['#7843EF', '#647DF1'],
  gradientColorsThree: ['#EE7C41', '#ffb60a'],
  gradientColorsFour: ['#4aa866', '#79C4AC'],
  gradientColorsFive: ['#aaa', '#777'],
  gradientColorsSix: ['#29020a', '#AE2B23']
};

export const DARK_THEME_COLORS = {
  text: '#b4becf',
  textBlack: '#b4becf',
  stroke: '#DBDBDB',
  background: '#161e25',
  bodyBG: '#10151a',
  primary: '#b14941',
  primary500: '#A0271F',
  primary400: '#C73127',
  primary300: '#E99690',
  primary200: '#F6D2D0',
  primary100: '#FCF0EF',
  secondary: '#0057FF',
  secondary500: '#0050EA',
  secondary400: '#2E76FF',
  secondary300: '#8FB5FF',
  secondary200: '#CFDFFF',
  secondary100: '#EFF4FF',
  white: '#fff',
  black: '#000',
  muted: '#646464',
  highlightedBG: '#212c3b',
  grey: '#35465b',
  themeText: '#b4becf',
  boxShadow: '#0000001c',
  darkPrimary: '#29020a',
  lightGrey: '#1c2630',
  lightText: '#8A9AB5',
  darkModeBoxShadow: '#0f141aa1',
  transparentDark: '#0000002f',
  darkGrey: '#546f91',
  lighterGrey: '#161e25',
  errorBackground: '#10151a',
  hover: '#212c3b',
  // ---- Alert Colors ---- //

  error: '#f44336',
  info: '#2196f3',
  lightInfo: '#053155',
  success: '#116D09',
  success500: '#13770A',
  success400: '#1CB20F',
  success300: '#85F47B',
  success200: '#E4FDE2',
  success100: '#F7FEF6',
  danger: '#D01509',
  danger500: '#E3170A',
  danger400: '#F53225',
  danger300: '#F9847C',
  danger200: '#FEE0DE',
  danger100: '#FFF9F8',
  warning: '#EC4C00',
  warning500: '#FF5403',
  warning400: '#FF712D',
  warning300: '#FF9B6C',
  warning200: '#FFD4C0',
  warning100: '#FFF6F2',

  moderate100: '#FFFBEF',
  moderate600: '#735600',

  // ---- Tags Colors ----- //

  // high: '#ef8157',
  // critical: '#c00',
  // moderate: '#e6c21a',
  // low: '#43a047',
  high: '#FDA50F',
  critical: '#FF0000',
  moderate: '#F7FD04',
  low: '#14FF00',

  // ---- Status Colors ----- //

  scheduled: '#38158a',
  pending: '#fdc04f',
  running: '#2096f3',
  aborted: '#a1a0a0',
  validating: '#ab55e7',
  finished: '#43a047',

  // ---- Role Colors ----- //

  admin: '#43a047',
  manager: '#491071',
  reporter: '#2096f3',
  disabled: '#807e7e',
  deleted: '#a8a8a8',
  verified: '#4caf50',

  // ---- Card Colors ---- //

  gradientColorsOne: ['#d8463d', '#FF7E58'],
  gradientColorsTwo: ['#7843EF', '#647DF1'],
  gradientColorsThree: ['#EE7C41', '#ffb60a'],
  gradientColorsFour: ['#4aa866', '#79C4AC'],
  gradientColorsFive: ['#aaa', '#777'],
  gradientColorsSix: ['#151C25', '#222D3B']
};

export const SIZES = {
  font10: 10,
  font12: 12,
  font14: 14,
  font16: 16,
  font18: 18,
  font24: 24,
  font32: 32
};

export const FONTS = {
  fontFamily: {
    body:
      'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'Georgia, serif'
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125
  },
  letterSpacings: {
    body: 'normal',
    caps: '0.2em'
  }
};

export const BREAKPOINTS = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
  }
};

export const LIGHT_BOX_SHADOW = [
  `2px 2px 80px ${LIGHT_THEME_COLORS.transparentDark}`,
  `-8px 12px 34px -15px ${DARK_THEME_COLORS.darkModeBoxShadow}`,
  `rgba(0, 0, 0, 0.1) 0px 10px 50px`,
  `1px 3px 8px rgba(0, 0, 0, 0.06)`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`
];

export const DARK_BOX_SHADOW = [
  `2px 2px 80px ${DARK_THEME_COLORS.darkModeBoxShadow}`,
  `-8px 12px 20px 0 ${DARK_THEME_COLORS.darkModeBoxShadow};`,
  `-8px 12px 34px -15px ${DARK_THEME_COLORS.darkModeBoxShadow}`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`,
  `none`
];
