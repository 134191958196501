import map from 'lodash/map';
import { API, fileAPI, APIv2, fileAPIv2 } from 'utils/api';
import { errorHandler } from 'utils/error_handler';
import { getSelectedIssueIds } from 'utils/selected_assets';
import { checkSeverity } from 'utils/severity_check';
import { showSuccessModal, showConfirmMutedModal, hideEditIssueInventoryVariantModal } from 'actions/modals';
import moment from 'moment-timezone';
import shortid from 'shortid';
import {
  fetchUniqueAttackSurfaceAssets,
  fetchAssetInventoryTableData,
  getAssetExposuresTopCardNumber
} from 'actions/asset_table';
import updateUrlAndFetchData from 'utils/update_data_url';

export const getAllIssueTypesWeAccept = () => async (dispatch) => {

  try {
    const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
      ? JSON.parse(localStorage.getItem('AssetGroupParams'))
      : [{ name: "Default Group", value: '' }]
    const filterUrl = assetGroupParams[0].value ? `?asset_group=${assetGroupParams[0].value}` : ''
    dispatch({ type: 'FETCH_DROPDOWN_REQUEST' });
    const { data } = await API.get(`/issue-types/${filterUrl}`);
    dispatch({
      type: 'FETCH_ALL_ISSUE_UNIQUE_TYPES',
      data: data.issue_types,
      assets: data.assets.filter((el) => el !== null) || [],
      category: data.category.filter((el, i) => el !== null) || []
    });
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const getAllIssueTypesForFilter = (orgId) => async (dispatch) => {
  try {
    const { data } = await API.get(`/issue-types/?orgId=${orgId}`);
    dispatch({
      type: 'FETCH_ALL_ISSUE_UNIQUE_TYPES',
      data: data.issue_types
    });
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const getAllDataleakSignaturesWeAccept = () => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_DROPDOWN_REQUEST' });
    const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
      ? JSON.parse(localStorage.getItem('AssetGroupParams'))
      : [{ name: "Default Group", value: '' }]
    const filterUrl = assetGroupParams[0].value ? `?asset_group=${assetGroupParams[0].value}` : ''
    const { data } = await API.get(`/dataleak-types/${filterUrl}`);
    dispatch({
      type: 'FETCH_ALL_UNIQUE_DATALEAK_SIGNATURES',
      data: data.dataleak_types
    });
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const handleIssuePocUpload = (file, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'ISSUE_POC_UPLOAD_REQUEST' });
    const selectedIssues = getState().issueTable.selectedData.split('\n');

    const issuesToUpdate = getSelectedIssueIds(selectedIssues);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    const imageFile = new FormData();
    imageFile.append('poc_file', file, file.name);
    imageFile.append('issue_ids', JSON.stringify(issuesToUpdate));

    const response = await fileAPI.put('/issue/poc/', imageFile, config);

    if (response.status === 201) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Issue POC is added successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      window.location.reload(false);
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handlePocUpload = (file, ids, orgId, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'ISSUE_POC_UPLOAD_REQUEST' });
    // const selectedIssues = getState().issueTable.selectedData.split('\n');

    // const dataleakToUpdate = getSelectedIssueIds(selectedIssues);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    const imageFile = new FormData();
    imageFile.append('action', 'add_poc');
    imageFile.append('poc_filename', file, file.name);
    imageFile.append('id', ids);

    const response = await fileAPIv2.put(`/orgs/${orgId}/vulnerability-inventory/`, imageFile, config);

    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'POC is added successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(hideEditIssueInventoryVariantModal());
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleIssueUpdate = (data, history) => async (dispatch) => {
  try {
    dispatch({ type: 'ISSUE_UPDATE_REQUEST' });

    const response = await API.put('/scans/issues/update/', { issues: data });

    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Issue is updated successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      window.location.reload(false);
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleDataleakUpdate = (data, history) => async (dispatch) => {
  try {
    dispatch({ type: 'DATALEAK_UPDATE_REQUEST' });

    const response = await API.put('/scans/dataleaks/update/', {
      dataleaks: data
    });

    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Dataleak is updated successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      window.location.reload(false);
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextOrgDataleakData = (history, nextUrl) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_ORGS_DATA_LEAK_DATA_REQUEST' });

    const { data } = await API.get(nextUrl);
    const dataLeaks = map(data.results, (item) => ({
      ...item,
      key: `${item.id}#${item.signature}`,
      id: item.id,
      asset: item.asset,
      signature: item.signature,
      references: item.references ? item.references.join(', ') : 'N/A',
      description: item.description || '',
      recommendation: item.recommendation || '',
      sensitive_info: item.sensitive_info,
      sensitive_url: item.sensitive_url,

      first_seen: item.created
        ? moment.utc(item.created).format('ll')
        : item.created,

      last_seen: item.last_seen
        ? moment.utc(item.last_seen).format('ll')
        : item.last_seen
    }));
    dispatch({
      type: 'FETCH_ORGS_DATA_LEAK_DATA_SUCCESS',
      dataLeaks,
      nextItems: data.next,
      previousItems: data.previous,
      dataLeaksCount: data.count,
      currentUrl: nextUrl ? `?${nextUrl.split('?')[1]}` : ''
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchOrgDataleakData = (filterUrl, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_ORGS_DATA_LEAK_DATA_REQUEST' });

    const baseURL = `/orgs/dataleaks/`;
    let data = await API.get(`${baseURL}${filterUrl}`);
    const dataLeaks = map(data.data.results, (item) => ({
      ...item,
      key: `${item.id}#${item.signature}`,
      id: item.id,
      asset: item.asset,
      signature: item.signature,
      is_muted: item.is_muted,
      references: item.references ? item.references.join(', ') : 'N/A',
      description: item.description || '',
      recommendation: item.recommendation || '',
      sensitive_info: item.sensitive_info,
      sensitive_url: item.sensitive_url,
      first_seen: item.created
        ? moment.utc(item.created).format('ll')
        : item.created,

      last_seen: item.last_seen
        ? moment.utc(item.last_seen).format('ll')
        : item.last_seen
    }));
    dispatch({
      type: 'FETCH_ORGS_DATA_LEAK_DATA_SUCCESS',
      dataLeaks,
      nextItems: data.data.next,
      previousItems: data.data.previous,
      dataLeaksCount: data.data.count,
      currentUrl: filterUrl
    });
  } catch (error) {
    dispatch({ type: 'REQUEST_FAILED' });
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextSecurityRisksTableData = (history, nextUrl) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_ORGS_SECURITY_RISKS_DATA_REQUEST' });

    const { data } = await API.get(nextUrl);
    const issueList = map(data.results, (item) => ({
      ...item,
      key: shortid.generate(),
      id: item.id,
      asset: item.asset,
      condition_id: item.condition_id || '',
      category: item.category || '',
      title: item.title,
      is_new: item.is_new,
      is_obsolete: item.is_obsolete,
      category: item.category,
      variant_description: item.variant_description,
      references: item.references ? item.references.join(', ') : 'N/A',
      severity: checkSeverity(item.severity),
      description: item.description || '',
      recommendation: item.recommendation || '',
      first_seen: moment.utc(item.created).format('ll'),
      last_seen: moment.utc(item.last_seen).format('ll')
    }));

    dispatch({
      type: 'FETCH_ORGS_SECURITY_RISKS_DATA_SUCCESS',
      issueList,
      nextItems: data.next,
      previousItems: data.previous,
      issuesCount: data.count,
      currentUrl: nextUrl ? `?${nextUrl.split('?')[1]}` : ''
    });
  } catch (error) {
    dispatch({ type: 'REQUEST_FAILED' });
    errorHandler(error, dispatch, history);
  }
};

export const fetchSecurityRisksTableData = (filterUrl, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_ORGS_SECURITY_RISKS_DATA_REQUEST' });

    const baseURL = `/orgs/issues/`;
    let data = await API.get(`${baseURL}${filterUrl}`);
    const issueList = map(data.data.results, (item) => ({
      ...item,
      key: shortid.generate(),
      id: item.id,
      asset: item.asset,
      condition_id: item.condition_id || '',
      category: item.category || '',
      title: item.title,
      is_muted: item.is_muted,
      is_new: item.is_new,
      is_obsolete: item.is_obsolete,
      category: item.category,
      asset_is_obsolete: item.asset_is_obsolete || false,
      asset_is_new: item.asset_is_new || false,
      variant_description: item.variant_description,
      references: item.references ? item.references.join(', ') : 'N/A',
      severity: checkSeverity(item.severity),
      description: item.description || '',
      recommendation: item.recommendation || '',
      asset_id: item.asset_id,
      asset_extra_info: item.asset_extra_info || {},
      first_seen: moment.utc(item.created).format('ll'),
      last_seen: moment.utc(item.last_seen).format('ll')
    }));

    dispatch({
      type: 'FETCH_ORGS_SECURITY_RISKS_DATA_SUCCESS',
      issueList,
      nextItems: data.data.next,
      previousItems: data.data.previous,
      issuesCount: data.data.count,
      currentUrl: filterUrl
    });
  } catch (error) {
    dispatch({ type: 'REQUEST_FAILED' });
    errorHandler(error, dispatch, history);
  }
};

export const handleDeleteSelectedDataleaks = (
  history,
  assetsToDelete
) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'FETCH_ORGS_DATA_LEAK_DATA_REQUEST' });

    const response = await API.delete('/orgs/dataleaks/', {
      data: {
        ids: assetsToDelete
      }
    });

    if (response.status === 200) {
      const currentUrl = getState().issueTable.currentUrl;
      dispatch(fetchOrgDataleakData(currentUrl, history));
    }
  } catch (error) {
    dispatch({ type: 'REQUEST_FAILED' });
    errorHandler(error, dispatch, history);
  }
};

export const handleDeleteSelectedIssues = (history, assetsToDelete) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'FETCH_ORGS_SECURITY_RISKS_DATA_REQUEST' });

    const response = await API.delete('/orgs/issues/', {
      data: {
        ids: assetsToDelete
      }
    });

    if (response.status === 200) {
      const currentUrl = getState().issueTable.currentUrl;
      dispatch(fetchSecurityRisksTableData(currentUrl, history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const downloadOrganisationIssues = (history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'DOWNLOAD_SCAN_REPORT' });
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob'
    };
    const { data } = await fileAPI.get(`/orgs/issues/downloads/`, config);

    let blob = new Blob([data]);

    const orgName = getState().authentication.user.organisation;
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `Issues_${orgName}.csv`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const downloadOrganisationDataleaks = (history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'DOWNLOAD_SCAN_REPORT' });
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob'
    };
    const { data } = await fileAPI.get(`/orgs/dataleaks/downloads/`, config);

    let blob = new Blob([data]);

    const orgName = getState().authentication.user.organisation;
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `Dataleaks_${orgName}.csv`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextUniqueDataLeaks = (history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'FETCH_ORGS_DATA_LEAK_DATA_REQUEST' });
    const nextUrl = getState().issueTable.nextItems;

    const { data } = await API.get(nextUrl);
    const newDataLeaks = map(data.results, (item) => ({
      key: `${item.id}#${item.signature}`,
      id: item.id,
      asset: item.asset,
      signature: item.signature,
      references: item.references ? item.references.join(', ') : 'N/A',
      description: item.description || '',
      recommendation: item.recommendation || '',
      sensitive_info: item.sensitive_info,
      sensitive_url: item.sensitive_url,
      first_seen: item.created
        ? moment.utc(item.created).format('ll')
        : item.created,

      last_seen: item.last_seen
        ? moment.utc(item.last_seen).format('ll')
        : item.last_seen
    }));

    const dataLeaksData = getState().issueTable.dataLeaks;
    const dataLeaksUpdated = dataLeaksData.concat(newDataLeaks);

    dispatch({
      type: 'FETCH_ORGS_DATA_LEAK_DATA_SUCCESS',
      dataLeaks: newDataLeaks,
      nextItems: data.next,
      previousItems: data.previous,
      dataLeaksCount: data.count,
      currentUrl: nextUrl ? `?${nextUrl.split('?')[1]}` : ''
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextUniqueSecurityRisks = (history, nextUrl) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'FETCH_ORGS_SECURITY_RISKS_DATA_REQUEST' });
    // const nextUrl = getState().issueTable.nextItems;
    const { data } = await API.get(nextUrl);
    const issueList = map(data.results, (item) => ({
      ...item,
      key: shortid.generate(),
      id: item.id,
      asset: item.asset,
      condition_id: item.condition_id || '',
      title: item.title,
      is_muted: item.is_muted,
      is_new: item.is_new,
      is_obsolete: item.is_obsolete,
      asset_is_obsolete: item.asset_is_obsolete || false,
      asset_is_new: item.asset_is_new || false,
      variant_description: item.variant_description,
      references: item.references ? item.references.join(', ') : 'N/A',
      severity: checkSeverity(item.severity),
      description: item.description || '',
      recommendation: item.recommendation || '',
      asset_id: item.asset_id,
      asset_extra_info: item.asset_extra_info || {},
      first_seen: moment.utc(item.created).format('ll'),

      last_seen: moment.utc(item.last_seen).format('ll')
    }));
    // const issueListData = getState().issueTable.data;
    // const issueListUpdated = issueListData.concat(issueList);

    dispatch({
      type: 'FETCH_ORGS_SECURITY_RISKS_DATA_SUCCESS',
      issueList: issueList,
      nextItems: data.next,
      previousItems: data.previous,
      issuesCount: data.count,
      currentUrl: nextUrl ? `?${nextUrl.split('?')[1]}` : ''
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchIssueConditionInfo = (
  condition,
  history,
  cardfilter,
  date
) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_CONDITION_INFO_REQUEST' });
    let cardParams = cardfilter.name
      ? `${cardfilter.name}=${!!cardfilter.value[0]}`
      : cardfilter;
    const dateFilter = date ? `&start_date=${date}` : '';
    const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
      ? JSON.parse(localStorage.getItem('AssetGroupParams'))
      : [{ group_name: "Default Group", id: null }];
    const filterUrl = assetGroupParams[0].value ? `&asset_group=${assetGroupParams[0].value}` : ''
    let url = `/orgs/issues/group-by-vulnerability/?condition_id=${condition}${cardParams}${dateFilter}${filterUrl}`;
    const { data } = await API.get(url);
    dispatch({
      type: 'FETCH_ISSUE_CONDITIONS_INFO',
      data
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const getIssueTrackerStatus = (history) => async (dispatch) => {
  try {
    const { data } = await API.get(`/orgs/vulnerability-tracker/status/`);
    dispatch({
      type: 'FETCH_ISSUE_STATUS_REQUEST',
      data
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const getIssueTrackerUser = (history) => async (dispatch) => {
  try {
    const { data } = await API.get(`/users/?existing=true`);
    dispatch({
      type: 'FETCH_ISSUE_TRACKER_USER_REQUEST',
      data: data.results
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchIssueTrackerList = (filterUrl, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_ISSUE_TRACKER_REQUEST' });

    const baseURL = `/orgs/vulnerability-tracker/`;
    const { data } = await API.get(`${baseURL}${filterUrl ? filterUrl : ''} `);
    // const issueTrackerlist = data;
    dispatch({
      type: 'FETCH_ISSUE_TRACKER_DATA_SUCCESS',
      issueTracker: data,
      // nextItems: data.next,
      // previousItems: data.previous,
      issueTrackerCount: data._meta.count ? data._meta.count : 0,
      is_jira_integrated: data._meta ? data._meta.is_jira_integrated : false,
      last_run_at: data._meta ? `${moment.utc(data._meta.last_run_at).format('lll')} UTC` : false
      // currentUrl: filterUrl
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextIssueTrackerList = (filterUrl, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'FETCH_ISSUE_TRACKER_REQUEST' });

    const nextUrl = getState().issueTable.nextItems;
    if (nextUrl) {
      const { data } = await API.get(`${nextUrl} `);
      const newIssueTracker = getState().issueTable.issueTracker;
      const issueTrackerUpdated = newIssueTracker.concat(data.results);
      dispatch({
        type: 'FETCH_ISSUE_TRACKER_DATA_SUCCESS',
        issueTracker: issueTrackerUpdated,
        nextItems: data.next,
        previousItems: data.previous,
        issueTrackerCount: data.count,
        currentUrl: filterUrl
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const addDataleaksSecurityRisks = (issue, history) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_ISSUE_TRACKER_REQUEST' });

    const response = await API.post(`/orgs/dataleaks/security-risk/`, issue);
    if (response.status === 201 || response.status === 200) {
      dispatch({ type: 'ADD_ISSUE_TRACKER_REQUEST_SUCCESS' });
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Dataleaks send to security risks successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    dispatch({ type: 'ADD_ISSUE_TRACKER_REQUEST_SUCCESS' });
    errorHandler(error, dispatch, history);
  }
};
export const addNewIssueTracker = (issue, history) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_ISSUE_TRACKER_REQUEST' });

    const response = await API.post(`/orgs/vulnerability-tracker/`, issue);
    if (response.status === 201 || response.status === 200) {
      dispatch({ type: 'ADD_ISSUE_TRACKER_REQUEST_SUCCESS' });
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Issue added successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    dispatch({ type: 'ADD_ISSUE_TRACKER_REQUEST_SUCCESS' });
    errorHandler(error, dispatch, history);
  }
};
export const addNewAssetTracker = (asset, history) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_ISSUE_TRACKER_REQUEST' });

    const response = await API.post(`/orgs/asset-tracker/`, asset);
    if (response.status === 201 || response.status === 200) {
      dispatch({ type: 'ADD_ISSUE_TRACKER_REQUEST_SUCCESS' });
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Asset added successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    dispatch({ type: 'ADD_ISSUE_TRACKER_REQUEST_SUCCESS' });
    errorHandler(error, dispatch, history);
  }
};
export const addNewDataleakTracker = (dataleak, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_ISSUE_TRACKER_REQUEST' });

    const response = await API.post(`/orgs/dataleak-tracker/`, dataleak);
    if (response.status === 201 || response.status === 200) {
      dispatch({ type: 'ADD_ISSUE_TRACKER_REQUEST_SUCCESS' });
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Dataleak added successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    dispatch({ type: 'ADD_ISSUE_TRACKER_REQUEST_SUCCESS' });
    errorHandler(error, dispatch, history);
  }
};

export const updateIssueTracker = (issue, history) => async (dispatch) => {
  try {
    const response = await API.put(`/orgs/vulnerability-tracker/`, issue);
    if (response.status === 201 || response.status === 200) {
      dispatch({
        type: 'UPDATE_ISSUE_TRACKER_REQUEST_SUCCESS',
        data: response.data
      });

      dispatch(fetchIssueTrackerList('', history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const updateDateleakTracker = (dataleak, history) => async (
  dispatch
) => {
  try {
    const response = await API.put(`/orgs/dataleak-tracker/`, dataleak);
    if (response.status === 201 || response.status === 200) {
      dispatch(fetchIssueTrackerList('', history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const updateAssetTracker = (asset, history) => async (dispatch) => {
  try {
    const response = await API.put(`/orgs/asset-tracker/`, asset);
    if (response.status === 201 || response.status === 200) {
      dispatch(fetchIssueTrackerList('', history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const updateOnDNDIssueTracker = (issue, type, history) => async (
  dispatch
) => {
  try {
    // let url =
    //   type === 'asset'
    //     ? '/orgs/asset-tracker/'
    //     : type === 'dataleak'
    //     ? '/orgs/dataleak-tracker/'
    //     : `/ orgs / vulnerability - tracker / `;
    const response = await API.put(`/orgs/vulnerability-tracker/`, issue);
    if (response.status === 201 || response.status === 200) {
      // dispatch(fetchIssueTrackerList('', history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

// export const deleteIssueTracker = (issue, history) => async (dispatch) => {
//   try {
//     const response = await API.delete(`/ orgs / vulnerability - tracker / `, {
//       data: {
//         id: issue.id
//       }
//     });
//     if (response.status === 204 || response.status === 200) {
//       dispatch(fetchIssueTrackerList('', history));
//       dispatch({
//         type: 'SUCCESS_MESSAGE_MODAL',
//         message: {
//           title: 'Success!',
//           message: 'Issue Deleted successfully.',
//           redirectTo: '',
//           buttonTitle: 'Ok'
//         }
//       });
//       dispatch(showSuccessModal());
//     }
//   } catch (error) {
//     errorHandler(error, dispatch, history);
//   }
// };
export const deleteAssetTracker = (asset, history) => async (dispatch) => {
  try {
    const response = await API.delete(`/orgs/asset-tracker/`, {
      data: {
        id: asset.id
      }
    });
    if (response.status === 204 || response.status === 200) {
      dispatch(fetchIssueTrackerList('', history));
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Asset deleted successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const deleteDataleakTracker = (dataleak, history) => async (
  dispatch
) => {
  try {
    const response = await API.delete(`/orgs/dataleak-tracker/`, {
      data: {
        id: dataleak.id
      }
    });
    if (response.status === 204 || response.status === 200) {
      dispatch(fetchIssueTrackerList('', history));
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Dataleak deleted successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const deleteIssueTrackerComment = (comment, history) => async (
  dispatch
) => {
  try {
    const response = await API.delete(`/orgs/issue-tracker/comment/`, {
      data: {
        comment_id: comment.id
      }
    });
    if (response.status === 204 || response.status === 200) {
      dispatch(fetchIssueTrackerList('', history));
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Comment deleted successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const addIssueTrackerComment = (comment, history, status) => async (
  dispatch,
  getState
) => {
  try {
    const response = await API.post(`/orgs/issue-tracker/comment/`, comment);
    if (response.status === 204 || response.status === 200) {
      await dispatch(fetchIssueTrackerList('', history));

      const rows = getState().issueTable.issueTracker;
      const issueTrackerDetails = getState().issueTable.issueTrackerDetails;
      const issue = rows[status].find((el) => el.id === issueTrackerDetails.id);
      dispatch({
        type: 'UPDATE_ISSUE_TRACKER_REQUEST_SUCCESS',
        data: issue
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const upadteIssueTrackerComment = (comment, history) => async (
  dispatch
) => {
  try {
    const response = await API.put(`/orgs/issue-tracker/comment/`, comment);
    if (response.status === 204 || response.status === 200) {
      dispatch(fetchIssueTrackerList('', history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const getUniqueCondition = (history) => async (dispatch) => {
  try {
    const { data } = await API.get(`/orgs/issues/conditions/`);
    dispatch({
      type: 'FETCH_ISSUE_CONDITIONS',
      data: data.unique_conditions,
      count: data.unique_conditions.length
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchVulnerabilitiesCardData = (
  condition,
  history,
  filterOnClick = false
  // cardValue
) => async (dispatch) => {
  try {
    if (!filterOnClick) {
      dispatch({ type: 'FETCH_VULNERABILITIES_CONDITIONS_DATA_REQUEST' });
    }
    let data;
    if (!filterOnClick) {
      let baseUrl = `/orgs/issue-titles/overview/`;

      const response = await API.get(baseUrl);
      data = response.data;
      await dispatch({
        type: 'FETCH_VULNERABILITIES_CONDITIONS_DATA_SUCCESS',
        vulnerabilitiesCard: data
      });
    }

    const conditionFilter = condition
      ? condition
      : data.unique_conditions[0].id;
    localStorage.setItem('paramQuery', conditionFilter);
    const params = new URLSearchParams(history.location.search);
    const baseURL = '/security-risks/vulnerabilities';
    params.set('condition_id', conditionFilter);
    history.push(`${baseURL}?${params.toString()} `);
    localStorage.setItem(
      'filterParams',
      JSON.stringify([{ name: 'condition_id', value: conditionFilter }])
    );
    await dispatch(fetchIssueConditionInfo(conditionFilter, history));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchIssueConditions = (
  condition,
  history,
  filterOnClick = false,
  cardValue
) => async (dispatch) => {
  try {
    if (!filterOnClick) {
      dispatch({ type: 'FETCH_ISSUE_CONDITIONS_REQUEST' });
    }

    let data = [];
    if (!filterOnClick) {
      let baseUrl = `/orgs/issues/conditions/?is_obsolete=false`;
      if (cardValue) {
        baseUrl = `/orgs/issues/conditions/?${cardValue.name === 'is_new' ? 'is_new=true' : 'is_obsolete=true'
          }`;
      }

      const response = await API.get(baseUrl);
      data = response.data;
      await dispatch({
        type: 'FETCH_ISSUE_CONDITIONS',
        data: data.unique_conditions,
        count: data.unique_conditions.length
      });
    }
    const conditionFilter = condition
      ? condition
      : data.unique_conditions[0].id;
    localStorage.setItem('paramQuery', conditionFilter);
    const params = new URLSearchParams(history.location.search);
    const baseURL = '/security-risks/vulnerabilities';
    params.set('condition_id', conditionFilter);
    history.push(`${baseURL}?${params.toString()} `);
    localStorage.setItem(
      'filterParams',
      JSON.stringify([{ name: 'condition_id', value: conditionFilter }])
    );
    await dispatch(fetchIssueConditionInfo(conditionFilter, history));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchUniqueDataLeaksTypes = (attachedUrl) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_DATA_LEAKS_TYPE_REQUEST' });
    const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
      ? JSON.parse(localStorage.getItem('AssetGroupParams'))
      : [{ name: "Default Group", value: '' }]
    const filterUrl = attachedUrl && attachedUrl !== '?' ? `${attachedUrl}&asset_group=${assetGroupParams[0].value}` : `?asset_group=${assetGroupParams[0].value}`
    const { data } = await API.get(
      `/orgs/dataleaks/overview/${filterUrl}`
    );

    const dataLeaks = map(data.dataleak_types, (asset) => ({
      key: shortid.generate(),
      signature: asset.signature,
      count: asset.type_count
    }));

    dispatch({
      type: 'FETCH_DATA_LEAKS_TYPE_SUCCESS',
      dataLeaks,
      dataLeaksCard: data.overview
    });
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const fetchUniqueSecurityRiskTypes = (
  attachedUrl,
  condition,
  history,
  date
) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_SECURITY_RISK_TYPES_REQUEST' });
    const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
      ? JSON.parse(localStorage.getItem('AssetGroupParams'))
      : [{ name: "asset_group", value: '' }]
    const filterUrl = attachedUrl && attachedUrl !== '?' ? `${attachedUrl}&asset_group=${assetGroupParams[0].value}` : `?asset_group=${assetGroupParams[0].value}`
    const filterDate =
      date && attachedUrl !== ''
        ? `&start_date=${date}`
        : date && attachedUrl === ''
          ? `&start_date=${date}`
          : '';
    const { data } = await API.get(
      `/orgs/issues/overview/${filterUrl}${filterDate}`
    );
    const issueTypes = map(data.title, (asset, i) => ({
      key: shortid.generate(),
      title: asset.title,
      id: asset.condition_id,
      count: asset.type_count,
      severity: asset.severity
    }));

    const issueTypesRaw = map(data.title, (e) => ({
      title: e.title
    }));
    issueTypesRaw.assets = data.assets;

    const severityTypes = map(data.severity, (severity) => ({
      key: shortid.generate(),
      severity: checkSeverity(severity.severity),
      id: severity.severity,
      count: severity.type_count
    }));

    dispatch({
      type: 'FETCH_SECURITY_RISK_TYPES_SUCCESS',
      issueTypes: issueTypes.sort((a, b) => (a.severity > b.severity ? -1 : 1)),
      severityTypes: severityTypes.sort((a, b) => (a.id > b.id ? -1 : 1)),
      issuesCard: data.overview,
      issueTypesRaw
    });
    const checkId = issueTypes.some((item) => item.id === Number(condition));
    const conditionFilter =
      checkId && condition
        ? condition
        : !checkId && issueTypes.length
          ? issueTypes[0].id
          : condition;
    localStorage.setItem('paramQuery', conditionFilter);
    if (conditionFilter && history) {
      const params = new URLSearchParams(history.location.search);
      const baseURL = '/security-risks/vulnerabilities';
      params.set('condition_id', conditionFilter);
      if (date) {
        params.set('start_date', date);
      }
      history.push(`${baseURL}?${params.toString()}`);
      localStorage.setItem(
        'filterParams',
        JSON.stringify([{ name: 'condition_id', value: conditionFilter }])
      );
      await dispatch(
        fetchIssueConditionInfo(
          conditionFilter,
          history,
          attachedUrl.replace('?', '&'),
          date
        )
      );
    } else {
      dispatch({
        type: 'FETCH_ISSUE_CONDITIONS_INFO',
        data: { affected_hosts: [], condition_detail: {} }
      });
    }
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const deleteIssue = (id, comment, history) => async (
  dispatch,
  getState
) => {
  try {
    // dispatch({ type: 'FETCH_ORGS_SECURITY_RISKS_DATA_REQUEST' });
    dispatch({ type: 'SHOW_ASSET_EXPOSURE_CARD_LOADER' });
    const response = await API.put(`/orgs/issues/${id}/blacklist/`, {
      muted_comment: comment
    });
    if (response.status) {
      if (history.location.pathname === '/security-risks/table-layout') {
        const baseUrl = '/security-risks/table-layout';
        await updateUrlAndFetchData(
          baseUrl,
          dispatch,
          fetchSecurityRisksTableData,
          history
        );
      } else {
        const baseUrl = '/security-risks';
        await updateUrlAndFetchData(
          baseUrl,
          dispatch,
          fetchUniqueSecurityRiskTypes,
          history
        );
      }
      dispatch({ type: 'HIDE_ASSET_EXPOSURE_CARD_LOADER' });
      const rows = getState().issueTable.data;
      const new_data_list = rows.filter((e) => e.id !== id);
      dispatch({
        type: 'FETCH_ORGS_SECURITY_RISKS_DATA_SUCCESS',
        issueList: new_data_list,
        nextItems: getState().issueTable.nextItems,
        previousItems: getState().issueTable.previousItems,
        issuesCount: getState().issueTable.issuesCount - 1,
        currentUrl: getState().issueTable.currentUrl
      });
      // await updateUrlAndFetchData(
      //   baseUrl,
      //   dispatch,
      //   fetchUniqueSecurityRiskTypes,
      //   history
      // );
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: `Security risk muted successfully.`,
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
    const baseUrl = '/security-risks';
    await updateUrlAndFetchData(
      baseUrl,
      dispatch,
      fetchSecurityRisksTableData,
      history
    );
  }
};
export const deleteIssueTitle = (id, comment, history) => async (
  dispatch,
  getState
) => {
  try {
    // dispatch({ type: 'FETCH_ORGS_SECURITY_RISKS_DATA_REQUEST' });
    dispatch({ type: 'SHOW_ASSET_EXPOSURE_CARD_LOADER' });
    const response = await API.put(`/orgs/issues/${id}/blacklist/title/`, {
      muted_comment: comment,
      mute: true
    });
    if (response.status) {

      const baseUrl = '/security-risks';
      await updateUrlAndFetchData(
        baseUrl,
        dispatch,
        fetchSecurityRisksTableData,
        history
      );
      await updateUrlAndFetchData(
        baseUrl,
        dispatch,
        fetchUniqueSecurityRiskTypes,
        history
      );
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: `Security risk title muted successfully.`,
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
    const baseUrl = '/security-risks';
    await updateUrlAndFetchData(
      baseUrl,
      dispatch,
      fetchSecurityRisksTableData,
      history
    );
  }
};
export const muteAssetExposure = (id, comment, history) => async (
  dispatch,
  getState
) => {
  try {
    // dispatch({ type: 'SHOW_ASSET_EXPOSURE_CARD_LOADER' });
    const response = await API.put(`/orgs/asset-exposure/${id}/blacklist/`, {
      muted_comment: comment
    });
    if (response.status) {

      if (history.location.pathname === '/security-risks') {
        const baseUrl = '/security-risks';
        await updateUrlAndFetchData(
          baseUrl,
          dispatch,
          fetchSecurityRisksTableData,
          history
        );
      } else {
        const baseUrl = '/attack-surface';
        await updateUrlAndFetchData(
          baseUrl,
          dispatch,
          getAssetExposuresTopCardNumber,
          history
        );
        dispatch({ type: 'HIDE_ASSET_EXPOSURE_CARD_LOADER' });
        dispatch({ type: "FETCH_NEXT_ATTACK_SURFACE", id: id });
        const rows = getState().assetTable.data;
        const new_data_list = rows.filter((e) => e.id !== id);
        dispatch({
          type: 'FETCH_ASSET_SUCCESS',
          id: id,
          assets: new_data_list,
          assetsCount: getState().assetTable.assetsCount - 1,
          nextItems: getState().assetTable.nextItems,
          previousItems: getState().assetTable.previousItems,
          currentUrl: getState().assetTable.currentUrl
        });
      }
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: `Asset muted successfully.`,
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
    const baseUrl = '/attack-surface';
    await updateUrlAndFetchData(
      baseUrl,
      dispatch,
      fetchUniqueAttackSurfaceAssets,
      history
    );
  }
};
export const muteAssetExposureConfirm = (id, history) => async (dispatch) => {
  try {
    const response = await API.post(
      `/orgs/asset-exposure/${id}/confirm-blacklist/`,
      {}
    );
    if (response.status) {
      dispatch({
        type: 'FETCH_ASSET_EXPOSURE_BACKLIST_MSG',
        message: response.data.message
      });
      dispatch(showConfirmMutedModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const muteAssetInventoryConfirm = (id, history) => async (dispatch) => {
  try {
    const response = await API.post(
      `/orgs/asset-inventory/${id}/confirm-blacklist/`,
      {}
    );
    if (response.status) {
      dispatch({
        type: 'FETCH_ASSET_EXPOSURE_BACKLIST_MSG',
        message: response.data.message
      });
      dispatch(showConfirmMutedModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const muteAssetInventory = (id, comment, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_ASSET_REQUEST' });
    const response = await API.put(`/orgs/asset-inventory/${id}/blacklist/`, {
      muted_comment: comment
    });
    if (response.status) {
      const baseUrl = '/attack-surface/asset-inventory';
      await updateUrlAndFetchData(
        baseUrl,
        dispatch,
        fetchAssetInventoryTableData,
        history
      );
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: `Asset muted successfully.`,
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
    const baseUrl = '/attack-surface/asset-inventory';
    await updateUrlAndFetchData(
      baseUrl,
      dispatch,
      fetchAssetInventoryTableData,
      history
    );
  }
};

export const deleteDataleak = (id, comment, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'SHOW_ASSET_EXPOSURE_CARD_LOADER' });
    // dispatch({ type: 'FETCH_ORGS_DATA_LEAK_DATA_REQUEST' });
    const response = await API.put(`/orgs/dataleaks/${id}/blacklist/`, {
      muted_comment: comment
    });
    if (response.status) {
      if (history.location.pathname === '/code-exposures') {
        const baseUrl = '/code-exposures';
        await updateUrlAndFetchData(
          baseUrl,
          dispatch,
          fetchUniqueDataLeaksTypes,
          history
        );
        const rows = getState().issueTable.dataLeaks;
        const new_data_list = rows.filter((e) => e.id !== id);
        dispatch({
          type: 'FETCH_ORGS_DATA_LEAK_DATA_SUCCESS',
          dataLeaks: new_data_list,
          nextItems: getState().issueTable.nextItems,
          previousItems: getState().issueTable.previousItems,
          dataLeaksCount: getState().issueTable.dataLeaksCount - 1,
          currentUrl: getState().issueTable.currentUrl
        });
        dispatch({ type: 'HIDE_ASSET_EXPOSURE_CARD_LOADER' });
      } else {
        const baseUrl = '/code-exposures/table-layout';
        await updateUrlAndFetchData(
          baseUrl,
          dispatch,
          fetchOrgDataleakData,
          history
        );
      }
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: `Dataleak muted successfully.`,
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
    const baseUrl = '/code-exposures';
    await updateUrlAndFetchData(
      baseUrl,
      dispatch,
      fetchOrgDataleakData,
      history
    );
  }
};
export const deleteBulkDataleak = (id, comment, history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'SHOW_ASSET_EXPOSURE_CARD_LOADER' });
    dispatch({ type: 'FETCH_ORGS_DATA_LEAK_DATA_REQUEST' });
    const response = await API.put(`/orgs/dataleaks/muted/`, {
      muted_comment: comment,
      dataleak_ids: id
    });
    if (response.status) {

      const baseUrl = '/code-exposures/table-layout';
      await updateUrlAndFetchData(
        baseUrl,
        dispatch,
        fetchOrgDataleakData,
        history
      );
    }
    dispatch({
      type: 'SUCCESS_MESSAGE_MODAL',
      message: {
        title: 'Success!',
        message: `Selected dataleaks muted successfully.`,
        redirectTo: '',
        buttonTitle: 'Ok'
      }
    });
    dispatch(showSuccessModal());
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const getJiraSyncTracker = (history) => async (dispatch) => {
  try {
    const { data } = await API.get(
      `/orgs/vulnerability-tracker/last-sync-time/`
    );
    dispatch({
      type: 'FETCH_JIRA_SYNC_TRACKER_SUCCESS',
      data: `${moment.utc(data.last_run_at).format('lll')} UTC`
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const createJiraTicket = (orgId, history) => async (dispatch) => {
  try {
    dispatch({ type: 'EXPORT_TO_JIRA_REQUEST' });
    const response = await API.post(`/orgs/${orgId}/jira-tickets/`, {});
    if (response.status === 201 || 200)
      dispatch({ type: 'EXPORT_TO_JIRA_SUCCESS' });
    dispatch({
      type: 'SUCCESS_MESSAGE_MODAL',
      message: {
        title: 'Success!',
        message: response.data.message,
        redirectTo: '',
        buttonTitle: 'Ok'
      }
    });
    dispatch(fetchIssueTrackerList('', history));
    dispatch(showSuccessModal());
  } catch (error) {
    dispatch({ type: 'REQUEST_FAILED' });
    errorHandler(error, dispatch, history);
  }
};

export const fetchRhSecurityRisksTableData = (
  orgId,
  type,
  history,
  search
) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_RH_ORGS_SECURITY_RISKS_TABLE_DATA_REQUEST' });
    const manual = type === 'manual' ? false : true;
    const baseUrl = `/orgs/${orgId}/vulnerability-inventory/?manual_owner=${manual}`;
    const assetSearch = search ? `&asset=${search}` : '';
    const { data } = await APIv2.get(`${baseUrl} ${assetSearch} `);
    dispatch({
      type: 'FETCH_RH_ORGS_SECURITY_RISKS_TABLE_DATA_SUCCESS',
      srList: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      issuesCount: data.count
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextRhSecurityRisksTableData = (history) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: 'FETCH_RH_ORGS_SECURITY_RISKS_TABLE_DATA_REQUEST' });
    const url = getState().issueTable.nextItems;
    const { data } = await APIv2.get(url);
    dispatch({
      type: 'FETCH_RH_ORGS_SECURITY_RISKS_TABLE_DATA_SUCCESS',
      srList: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      issuesCount: data.count
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchRhSecurityRisksDetails = (orgId, title, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_RH_ORGS_SECURITY_RISKS_DETAILS_REQUEST' });
    const { data } = await APIv2.get(
      `/org/${orgId}/vulnerability-inventory-agg/?title=${title}`
    );
    dispatch({
      type: 'FETCH_RH_ORGS_SECURITY_RISKS_DETAILS_SUCCESS',
      srDetails: data.summary,
      srList: [
        ...data.instance.automated.map((i) => ({
          ...i,
          type: 'automated'
        })),
        ...data.instance.manual.map((i) => ({
          ...i,
          type: 'manual'
        })),
        ...data.instance.inactive.map((i) => ({
          ...i,
          type: 'inactive'
        }))
      ],
      srListAutomated: data.instance.automated.map((i) => ({
        ...i,
        type: 'automated'
      })),
      srListManual: data.instance.manual.map((i) => ({
        ...i,
        type: 'manual'
      })),
      srListInactive: data.instance.inactive.map((i) => ({
        ...i,
        type: 'inactive'
      }))
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const filterAICsv = (filterUrl, history) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_ISSUE_TRACKER_REQUEST' });

    const baseURL =
      history.location.pathname === '/security-risks/table-layout'
        ? `/orgs/issues/`
        : `/orgs/dataleaks/`;
    const response = await API.get(
      `${baseURL}${filterUrl ? filterUrl + '&export=true' : '?export=true'} `
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      history.location.pathname === '/security-risks/table-layout'
        ? 'issue_inventory.csv'
        : 'dataleak_inventory.csv'
    );
    document.body.appendChild(link);
    link.click();
    dispatch({ type: 'FETCH_CSV_SUCCESS' });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
