import { FETCH_ASSET_SCAN_DETAILS_SUCCESS } from 'action_types/scan_details';
const initialState = {
  data: [],
  vulnerablePortsCount: null,
  previousItems: null,
  nextItems: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_ASSET_SCAN_DETAILS_SUCCESS:
    case 'FETCH_SCAN_DETAILS_PORTS_SUCCESS':
      return {
        ...state,
        loading: false,
        data: [...action.vulnerablePorts],
        nextItems: action.nextItems,
        previousItems: action.previousItems,
        vulnerablePortsCount: action.vulnerablePortsCount
      };
    case 'RESET_REDUCER_DATA':
      return {
        ...state,
        ...initialState
      };
    case 'FETCH_SCAN_DETAILS_PORTS_REQUEST':
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
