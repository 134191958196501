export const startTour = () => (dispatch) => {
  dispatch({ type: 'START_TOUR' });
};

export const stopTour = () => (dispatch) => {
  dispatch({ type: 'STOP_TOUR' });
};

export const restartTour = () => (dispatch) => {
  dispatch({ type: 'RESTART_TOUR' });
};
