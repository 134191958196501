import React, { Fragment } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SkeletonMaterial from '@material-ui/lab/Skeleton';

export const groupBySkeleton = () => (
  <div style={{ flex: 1, margin: '1rem 2rem' }}>
    <SkeletonMaterial
      height={50}
      animation="wave"
      color="var(--color-light-text)"
    />
    <SkeletonMaterial height={50} animation="wave" />
    <SkeletonMaterial height={50} animation="wave" />
    <SkeletonMaterial height={50} animation="wave" />
    <SkeletonMaterial height={50} animation="wave" />
  </div>
);
export const groupBySkeletonCard = () => (
  <div style={{ flex: 1, margin: '1rem 2rem' }}>
    <SkeletonMaterial
      height={100}
      animation="wave"
      color="var(--color-light-text)"
    />
    <SkeletonMaterial height={100} animation="wave" />
    <SkeletonMaterial height={100} animation="wave" />
    <SkeletonMaterial height={100} animation="wave" />
    <SkeletonMaterial height={100} animation="wave" />
    <SkeletonMaterial height={100} animation="wave" />
    <SkeletonMaterial height={100} animation="wave" />
    <SkeletonMaterial height={100} animation="wave" />
  </div>
);
export const groupBySkeletonScreenShot = () => (
  <div style={{ flex: 1, margin: '1rem 2rem' }}>
    <SkeletonMaterial
      height={50}
      animation="wave"
      color="var(--color-light-text)"
    />
    <SkeletonMaterial height={50} animation="wave" />
    <SkeletonMaterial height={50} animation="wave" />
  </div>
);

export const generateSkeletonTable = (rows, columns, page) => {
  const skeletons = [];

  const renderColumns = () => {
    const columnSkeletons = [];
    for (var column = 1; column <= columns; column++) {
      let style = { padding: '1rem 0.5rem' };
      switch (page) {
        case 'SCAN_PAGE':
          if (column === 1) {
            style = { ...style, width: '100px' };
          } else if (column === 2) {
            style = { ...style, width: '145px' };
          } else if (column === 3) {
            style = { ...style, width: '185px' };
          } else if (column === 4) {
            style = { ...style, width: '205px' };
          } else if (column === 5) {
            style = { ...style, width: '145px' };
          } else if (column === 6) {
            style = { ...style, width: '120px' };
          } else if (column === 7) {
            style = { ...style, width: '140px' };
          }
          break;
        case 'ASSET_SCAN':
          if (column === 2) {
            style = { ...style, width: '300px' };
          }
          break;
        case 'FULL_SCAN_ASSETS':
          if (column === columns) {
            style = { ...style, width: '100px' };
          } else if (column === columns - 1) {
            style = { ...style, width: '100px' };
          }
          break;
        case 'ISSUES':
          if (column === 1) {
            style = { ...style, width: '290px' };
          } else if (column === 2) {
            style = { ...style, width: '190px' };
          }
          break;
        case 'PORTS':
          if (column === 2) {
            style = { ...style, width: '320px' };
          }
          break;
        default:
          style = null;
          break;
      }
      columnSkeletons.push(
        <TableCell component="th" key={column} style={style}>
          <SkeletonTheme
            color="var(--color-light-grey)"
            highlightColor="var(--color-background)">
            <Skeleton color="var(--color-light-text)" />
          </SkeletonTheme>
        </TableCell>
      );
    }
    return columnSkeletons;
  };

  for (var row = 1; row <= rows; row++) {
    skeletons.push(<TableRow key={row}>{renderColumns()}</TableRow>);
  }
  const skeletonLoader = <Fragment>{skeletons}</Fragment>;
  return skeletonLoader;
};
