import { combineReducers } from 'redux';
import assetTableReducer from './asset_table';
import scanTableReducer from './scan_table';
import issueTableReducer from './issue_table';
import rhTablesReducer from './rh_tables';
import vulnerabilityTableReducer from './vulnerability_table';
import scanDetailsReducer from './scan_details';
import authenticationReducer from './auth';
import cardsReducer from './card';
import groupedDetailsReducer from './grouped_details';
import modalsReducer from './modals';
import chartsReducer from './charts';
import organisationOnboardingReducer from './onboarding';
import errorReducer from './error';
import successReducer from './success_message';
import notificationsReducer from './notifications';
import settingsReducer from './settings';
import clientSettingsReducer from './client_settings';
import dashboardReducer from './dashboard';
import tourReducer from './tour';
import errorDataReducer from './error_data';

export default combineReducers({
  assetTable: assetTableReducer,
  scanTable: scanTableReducer,
  issueTable: issueTableReducer,
  vulnerabilityTable: vulnerabilityTableReducer,
  scanDetails: scanDetailsReducer,
  authentication: authenticationReducer,
  cards: cardsReducer,
  groupedDetails: groupedDetailsReducer,
  rhTables: rhTablesReducer,
  modals: modalsReducer,
  charts: chartsReducer,
  organisationOnboarding: organisationOnboardingReducer,
  error: errorReducer,
  successInfo: successReducer,
  notifications: notificationsReducer,
  settings: settingsReducer,
  dashboard: dashboardReducer,
  clientSettings: clientSettingsReducer,
  tour: tourReducer,
  errorData: errorDataReducer
});
